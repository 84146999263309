import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';

import { Observable, of, from, } from 'rxjs';
import { switchMap, tap, catchError } from 'rxjs/operators';
import { CacheService } from "ionic-cache";

@Injectable()
export class CacheInterceptor implements HttpInterceptor {

	/** 
		rCache: default | fresh
			default: if cache get form cache
			fresh: get newest and cache
			clear: get and clear cache
	**/
	constructor(public cache: CacheService) { }
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let cache: any = req.params.get('rCache');
		if (!cache) {
			return next.handle(req);
		}
		let cacheKey = req.params.get('rCacheKey');
		let group = req.params.get('rCacheGroupKey') || null;
		const prefixKey = req.params.get('rPrefixCacheKey');

		let params = req.params.delete('rCache');
		params = params.delete('rCacheKey');
		params = params.delete('rCacheGroupKey');
		params = params.delete('rPrefixCacheKey');

		
		req = req.clone({ params: params });
		let key = cacheKey || req.urlWithParams || req.url;
		if (prefixKey) {
			key = prefixKey + key;
		}
		// let cacheDelayType = 'all'
		// return this.cacheService.loadFromDelayedObservable(key, next.handle(req), null, null, cacheDelayType);
		// return next.handle(req);
		// return this.cacheService.loadFromObservable(key, next.handle(req));
		// console.log(req);
		let o: Observable<HttpEvent<any>>;

		if (cache == 'fresh' || cache == 'clear') {
			o = next.handle(req);
		}
		else {
			o = from(this.cache.getItem(key)).pipe(
				catchError((error) => {
					return of(null);
				}),
				switchMap((d: any) => {
					if (d && d.body && d.ok) {
						return of(new HttpResponse<any>(d))
					}
					cache = 'fresh';
					return next.handle(req);
				})
			);
		}
		o = o.pipe(
			tap(event => {
				if (!cache || !event['ok'] || !event['body'] || event['body'].Status == "Failed") {
					return;
				}
				if (event instanceof HttpResponse && (cache == 'fresh'|| cache == 'clear' )) {
					//Avoid case get from cache then save it again
					this.cache.saveItem(key, event, group);
				}
			})
		);

		return o;
	}



}
