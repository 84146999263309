// import { TabsPage } from '../tabs/tabs';
import { Component, ViewChild, ElementRef } from '@angular/core';
import { Location} from '@angular/common'
import { ModalController } from '@ionic/angular';
import { Router, ActivatedRoute, } from '@angular/router';
import { CacheService } from "ionic-cache";
import { ENV } from '@app/env';
import { User } from '@rsApp/modules/account/provider/user.service';
import { Store } from '../../providers/store.service';
import { CurrentStore } from '../../providers/current-store.service';
import { Utils, cacheErrorHandle } from '@rsApp/modules/utils/providers/utils';
import { AuthService } from '@rsApp/modules/auth/providers/auth.service';
import { Geolocation } from '@ionic-native/geolocation/ngx';

import {HttpClient, HttpRequest, HttpHeaders, HttpParams, HttpHandler } from '@angular/common/http';
import { GoogleMaps, GoogleMap, GoogleMapsEvent, GoogleMapOptions, Marker, HtmlInfoWindow, MarkerIcon } from '@ionic-native/google-maps';
import {Observable, Observer} from 'rxjs';

/**
 * Generated class for the HomePage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

declare var google;

@Component({
  selector: 'page-directions-map',
  templateUrl: 'direction.html',
  styleUrls: ['./direction.scss']
})
export class DirectionsMapPage {
  @ViewChild('map',{static: false}) mapElement: ElementRef;
  currentStore: any;
  currentUser: any = {};

  // directionsService = new google.maps.DirectionsService;
  // directionsDisplay = new google.maps.DirectionsRenderer;
  map: any;

  storeInfo: any;
  icons: any;

  start: any;
  end: any;

  constructor( public router: Router, public route: ActivatedRoute,public user: User, public store: Store,
    public utils: Utils,
    public modalCtrl: ModalController, public geolocation: Geolocation, public location: Location, public cache: CacheService,
    public http: HttpClient) {
  }


  async ionViewDidEnter() {
    this.storeInfo = await this.cache.getItem('store_storeInfo').catch(cacheErrorHandle);
    if(this.storeInfo){
      // this.cache.removeItem('store_storeInfo');
    }
    // this.initIcon();

    // this.utils.showLoading();
    let here = await this.utils.getCurrentPosition();

    if(!here.isLocationOn){
      // 30.223536,-95.5854667,17
      this.start = { lat: 30.223536, lng: -95.5854667 };
    }
    else{
      this.start = { lat: here.lat, lng: here.lng};
    }
    this.end = { lat: this.storeInfo.Latitude, lng: this.storeInfo.Longitude };
    this.directions();
  }

  goBackPage() {
    this.location.back();
  }

  

  directions() {
    let mapOptions: GoogleMapOptions = {
      camera: {
        target: {
          lat: this.start.lat,
          lng: this.start.lng
        },
        zoom: ENV.MapZoom,
        tilt: 30
      }
    };
    this.map = GoogleMaps.create('map_canvas', mapOptions);
    this.getRoute(this.start, this.end).subscribe((res:any)=>{
      let polyLine = res.routes[0].overview_polyline;
      let decodedPoints = GoogleMaps.getPlugin().geometry.encoding.decodePath(polyLine);
      this.map.addPolyline({
        points : decodedPoints,
        color : '#AA00FF',
        width: 2,
        geodesic : false
      });
      let marker: Marker = this.map.addMarkerSync({
        // icon: store.StoreIconURL,
        // infoWindowAnchor: [13, 0],
        title: 'You are here',
        position: {
          lat: this.start.lat,
          lng: this.start.lng
        }
      });
      let store = this.storeInfo;
      let storeMarker: Marker = this.map.addMarkerSync({
        icon: store.StoreIconURL || '',
        position: {
          lat: store.Latitude,
          lng: store.Longitude
        }
      }); 
    });
  }

  public getRoute(from, to):Observable<any>{
    //let url: string = https://maps.googleapis.com/maps/api/directions/json?key=YOUR_API_KEY&origin=New York, NY&destination=Chicago, IL

    // url could be for lat, lng
    // https://maps.googleapis.com/maps/api/directions/json?key=YOUR_API_KEY&origin=LAT,LNG&destination=LAT,LNG
    const ob = Observable.create((observer:Observer<any>)=>{
      // let key = 'AIzaSyDmyvHYwnBfmYljxy23h1h9daGLuczmEhY';
          // from = '30.2351796,-97.7835469,15.54',
          // to =  this.end.lat+','+this.end.lng;
        // from ='Toronto',
        // to = 'Montreal';
      let directionsService = new google.maps.DirectionsService;
      directionsService.route({
        origin: from,
        destination: to,
        travelMode: google.maps.TravelMode['DRIVING']
      }, (res)=>{
        observer.next(res);
        observer.complete();
      })
    })
    return ob;
    
  }
  public getPageName() {
		return "DirectionsMapPage";
	}
}
