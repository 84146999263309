import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { NavController, ModalController, Platform, AlertController } from '@ionic/angular';
import _ from 'lodash';
import { from, combineLatest } from 'rxjs'
import { tap, map } from 'rxjs/operators'
import { CacheService } from "ionic-cache";

import { Router, ActivatedRoute } from '@angular/router';
import { Utils, noop, cacheErrorHandle } from '@rsApp/modules/utils/providers/utils';
import { Store } from '../../providers/store.service';
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';
import { ENV } from '@app/env';

@Component({
  selector: 'page-find-store',
  templateUrl: 'find-store.html',
  providers: [OpenNativeSettings],
  styleUrls: ['./find-store.scss']
})
export class FindStorePage {
  cri: any = {
    keyword: '',
    lat: null,
    lng: null,
    isNearBy: false,
    isPickup: false,
    isDelivery: false,
    states: {},
    cities: {},
    banners: {},
    featureLists: {},
    systemList: {},
    areas: {},
  };
  states: any[];
  banners: any[];
  featureLists: any[];
  allFeatureLists: any[];
  customServices: any[];
  systemServices: any[];
  rawCities: any[];
  cities: any[];
  areas: any[];
  rawAreas: any[];
  services: any[];

  _router: string;
  defaultHref: string;

  isMapOn: boolean;
  isShowFullFeature = false;
  isFullCity = false;
  isBlockLoading = false;

  constructor(
    public navCtrl: NavController,
    // public navParams: NavParams, 
    public utils: Utils,
    public modalCtrl: ModalController,
    public router: Router,
    public route: ActivatedRoute,
    public store: Store,
    public location: Location,
    public openNativeSettings: OpenNativeSettings,
    public alertCtrl: AlertController,
    public platform: Platform,
    public cache: CacheService) {
    const params = this.route.snapshot.paramMap;
    this._router = params.get('router');
    this.defaultHref = params.get('defaultHref');

  }

  async ionViewDidEnter() {
    const params = await this.cache.getItem('store-search-params').catch(cacheErrorHandle);
    if (params) {
      Object.assign(this.cri, params);
    }
    this.cri.isNearBy = false;
    const cityS = this.utils.getCities().pipe(
      tap((data: any) => {
        this.rawCities = data;
        this.cities = this.isFullCity ? this.rawCities: data.slice(0, 6);
      })
    );
    const stateS = this.store.getStoreStates().pipe(
      tap((data: any) => {
        this.states = data.filter(obj => obj.StateId === 'CT' || obj.StateId === 'MA');
      })
    );

    const bannerS = this.store.getBanner().pipe(
      tap((data: any) => {
        this.banners = data.filter(b => b.Banner.toLowerCase().indexOf('bigy') > -1);
      })
    )

    const getStoreSystemServices = this.store.getStoreSystemServices().pipe(
      tap((data: any) => {
        data = _.orderBy(data, ['Priority'], ['asc']);
        this.systemServices = data.filter(service => service.IsActive && service.CS_ServicesID !== ENV.OnlineOrderSvcId && (service.ServiceGroup == 'SystemService' || service.ServiceGroup == 'CustomService'));
      })
    );

    const getStoreInStoreervices = this.store.getStoreInStoreervices().pipe(
      tap((data: any) => {
        // const customService = data.filter(service => service.ServiceGroup == 'CustomService');
        // this.customServices = _.orderBy(customService, ['Priority'], ['asc']);

        // console.log('customServices', this.customServices);
        data = _.map(data, function (d) {
          d.Priority = d.Priority != null ? d.Priority : 0;
          return d;
        });
        data = _.orderBy(data, ['Priority'], ['asc']);
        const servicesStore = data
        this.allFeatureLists = servicesStore;
        console.log(" this.allFeatureLists", this.allFeatureLists);
        this.featureLists = this.isShowFullFeature ? this.allFeatureLists : servicesStore.slice(0, 6);
      })
    );

    const areas = await this.utils.getCityArea().toPromise();
    this.rawAreas = _.sortBy(areas, ['Priority', 'CityAreaName']);
    this.areas = this.rawAreas.slice(0, 6);
    console.log(this.rawAreas);

    const s = combineLatest([cityS, stateS, getStoreSystemServices, getStoreInStoreervices, bannerS]).subscribe(() => {
      // hide loading;
    });
  }

  async doFindStore(event = null) {
    if (event != null && event.keyCode !== 13) return;
    if (this.cri.cities) {
      _.forIn(this.cri.cities, (val, key) => {
        if (!val) {
          delete this.cri.cities[key];
        }
      });
    }
    if (this.cri.states) {
      _.forIn(this.cri.states, (val, key) => {
        if (!val) {
          delete this.cri.states[key];
        }
      });
    }
    if (this.cri.areas) {
      _.forIn(this.cri.areas, (val, key) => {
        if (!val) {
          delete this.cri.areas[key];
        }
      });
    }
    if (this.cri.banners) {
      _.forIn(this.cri.banners, (val, key) => {
        if (!val) {
          delete this.cri.banners[key];
        }
      });
    }
    if (this.cri.featureLists) {
      _.forIn(this.cri.featureLists, (val, key) => {
        if (!val) {
          delete this.cri.featureLists[key];
        }
      });
    }

    if (this.cri.systemList) {
      _.forIn(this.cri.systemList, (val, key) => {
        if (!val) {
          delete this.cri.systemList[key];
        }
      });
    }


    await this.cache.saveItem('store-search-params', this.cri, 'store');
    this.router.navigate(['../result-find',
      {
        view: this.isMapOn ? 'map' : 'list'
      }
    ], {
      state: {
        searchParams: this.cri
      },
      relativeTo: this.route
    });
    this.isMapOn = false;
  }

  doReset() {
    this.isBlockLoading = false;
    this.cri = {
      keyword: '',
      lat: null,
      lng: null,
      isPickup: false,
      isDelivery: false,
      isNearBy: false,
      states: {},
      cities: {},
      banners: {},
      featureLists: {},
      systemList: {},
      areas: {},
    };
  }

  async goNearby() {
    if (this.isBlockLoading) {
      return;
    }
    this.isBlockLoading = true;
    this.utils.getCurrentPosition(5000, true).then((res: any) => {
      if (!res.isLocationOn) {
        this.utils.alertError('Please enable location services to see stores near you.', this.goAppSettings.bind(this));
        this.isBlockLoading = false;
        return;
      }
      this.doReset();
      this.cri.isNearBy = true;
      // this.isMapOn = true;
      this.doFindStore();
    });
  }
  onStateChange() {
    this.cities = _.filter(this.rawCities, (city) => {
      const valid = !!this.cri.states[city.State];
      // remove selected cities in uncheck states
      if (!valid && this.cri.cities[city.City]) {
        delete this.cri.cities[city.City];
      }
      return valid;
    })
  }

  seeMoreFeature() {
    this.featureLists = this.allFeatureLists;
    this.isShowFullFeature = true;
  }

  seeLessFeature() {
    this.featureLists = this.allFeatureLists.slice(0, 6);
    this.isShowFullFeature = false;
  }

  seeMoreCity() {
    this.areas = this.rawAreas;
    this.isFullCity = true;
  }

  seeLessCity() {
    this.areas = this.rawAreas.slice(0, 6);
    this.isFullCity = false;
  }

  goAppSettings() {
    if (!this.platform.is('cordova')) {
      return;
    }
    this.openNativeSettings.open('application_details').then(rs => {
      console.log('rs openNativeSettings', rs);
    }).catch(err => {
      console.log('err openNativeSettings', err);
    });
  }
  public getPageName() {
		return "FindStorePage";
	}
}
