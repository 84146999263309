import { Injectable } from '@angular/core';
import _ from 'lodash';
import { enumTrackAction, enumTrackingProduct, enumTrackingEventChange, enumTrackingShoppingList, TRACKING_ACTION_NAME } from '../enum/enum';
import { Subscription } from 'rxjs';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';

@Injectable({
	providedIn: 'root'
})

export class DXPTracker {
	currentUrl: string = '';
	subscriptions: Subscription[] = [];

	constructor(	
		public events: NgxPubSubService
	) {
		if (!(<any>window).dxp_at) {
			(<any>window).dxp_at = [];
		}
	}

	init() {
		this.registerEvent();
	}

	trackPage(pageName: any, url?: any) {
		(<any>window).dxp_at.push(['trackPageView', '', { 'PageUrl': url, 'PageTitle': pageName }]);
	}

	trackEventActionECommerce(actionType, value, metadata?: any) {
		var data = {
			actionType : actionType, 
			actionName : TRACKING_ACTION_NAME[actionType], 
			actionValue : value
		};

		(<any>window).dxp_at.push(['trackEventActionECommerce', data, metadata]);
	}

	registerEvent() {
		//Ecommerce Events
		const obsSearchItem = this.events.subscribe(enumTrackAction.SearchItem, ({keyword}) => {
			if (!keyword) {
				return;
			}
	
			this.trackEventActionECommerce(enumTrackingProduct.SearchItem, keyword); 
		});

		const obsCategoryView = this.events.subscribe(enumTrackAction.CategoryView, ({ category, pageTitle, externalId}) => {
			this.trackViewProductList(externalId);
		});

		const obsProductView = this.events.subscribe(enumTrackAction.ProductView, ({ product }) => {
			this.trackProductView(product);
		});

		const obsViewShoppingList = this.events.subscribe(enumTrackAction.ViewShoppingList, ({ ListID }) => {
			if (!ListID) {
				return;
			}
			
			this.trackEventActionECommerce(enumTrackingShoppingList.ViewList, ListID); 
		});

		const obsShopListGrandTotal = this.events.subscribe(enumTrackAction.ShopListGrandTotal, ({ items }) => {
			if (!items) {
				return;
			}

			var totalPrice = _.sumBy(items, function(i) { return i.ItemPrice ? parseFloat(i.ItemPrice) : 0; });
			var metadata = {
				TotalItem: items.length,
				TotalQuantity: _.sumBy(items, function(i) { return i.Quantity; }),
				StoreCredit: totalPrice,
				ShoppingCartItems: items.map(x => {
					return {
						Id: x.ProductUPC,
						Quantity: x.Quantity,
						ProductVariantName: x.ProductName,
						Price: x.ItemPrice,
						AllowSubstitutions: x.AllowSubstitutions,
					};
				}),
			};
			
			this.trackEventActionECommerce(enumTrackingShoppingList.ShopList, totalPrice, metadata); 
		});

		const obsAddItemShoppingList = this.events.subscribe(enumTrackAction.AddItemShoppingList, ({ item }) => {
			if (!item) {
				return;
			}
			this.trackEventActionECommerce(enumTrackingShoppingList.AddItem, item.Note); 
		});

		const obsCheckOffItems = this.events.subscribe(enumTrackAction.CheckOffItems, ({ ItemIDs }) => {
			if (!ItemIDs) {
				return;
			}
			this.trackEventActionECommerce(enumTrackingShoppingList.CheckOffItems, ItemIDs.join(',')); 
		});

		const obsEmptyList = this.events.subscribe(enumTrackAction.EmptyList, ({ ItemIDs }) => {
			if (!ItemIDs) {
				return;
			}
			this.trackEventActionECommerce(enumTrackingShoppingList.EmptyList, ItemIDs.join(',')); 
		});

		const obsShareList = this.events.subscribe(enumTrackAction.ShareList, ({ ListID }) => {
			if (!ListID) {
				return;
			}
			this.trackEventActionECommerce(enumTrackingShoppingList.ShareList, ListID); 
		});

		const obsPrintList = this.events.subscribe(enumTrackAction.PrintList, ({ ListID }) => {
			if (!ListID) {
				return;
			}
			this.trackEventActionECommerce(enumTrackingShoppingList.PrintList, ListID); 
		});

		const obsRemoveList = this.events.subscribe(enumTrackAction.RemoveList, ({ ListID }) => {
			if (!ListID) {
				return;
			}
			this.trackEventActionECommerce(enumTrackingShoppingList.RemoveList, ListID); 
		});

		const obsListUpdate = this.events.subscribe(enumTrackAction.ListUpdate, ({ ItemIDs }) => {
			if (!ItemIDs) {
				return;
			}
			this.trackEventActionECommerce(enumTrackingShoppingList.ListUpdate, ItemIDs.join(',')); 
		});

		const obsUrlUpdate = this.events.subscribe('emmit-change-url', ({ url }) => {
			this.currentUrl = url;
		});

		[
			obsSearchItem,
			obsCategoryView,
			obsProductView,
			obsViewShoppingList,
			obsShopListGrandTotal,
			obsAddItemShoppingList,
			obsCheckOffItems,
			obsEmptyList,
			obsShareList,
			obsPrintList,
			obsRemoveList,
			obsListUpdate,
			obsUrlUpdate
		].forEach(x => this.subscriptions.push(x));

	}

	trackViewProductList(externalId){
		if (!externalId) {
			return;
		}

		this.trackEventActionECommerce(enumTrackingProduct.ViewProductList, externalId); 
	}

	trackProductView(product) {
		if (!product) {
			return;
		}

		const { UPC, Name, Category, SalePrice, Price } = product;
		const price = SalePrice || Price || 0;
		const productCategory = Category || product.CategoryName || (product.ProductCategories && product.ProductCategories[0] && product.ProductCategories[0].CategoryName || "");
		var metadata = {
			ProductName: Name,
			UPC: UPC,
			ItemSize: (product && product.ItemSize) || '',
			ProductCategory: productCategory,
			ProductBrand: (product && product.ProductBrand) || '',
		};

		this.trackEventActionECommerce(enumTrackingProduct.ViewProduct, price, metadata); 
	}

	trackChooseStore(selectedStoreId , preStoreId) {
		if(!selectedStoreId || !preStoreId){
			return;
		}

		this.trackEventActionECommerce(enumTrackingEventChange.ChooseStore, selectedStoreId, {
			PreviousStoreId: preStoreId
		}); 
	}

	trackChooseContext(selectedshopPath, preshopPath) {
		if(!selectedshopPath || !preshopPath){
			return;
		}

		this.trackEventActionECommerce(enumTrackingEventChange.ChooseContext, selectedshopPath, {
			PreviousContext: preshopPath
		}); 
	}

	trackAddItemToList(product) {
		if (!product) {
			return;
		}

		const { UPC, Name,  Quantity } = product;
		const quantity = Quantity || 1;
		const productCategory = product.CategoryName || (product.ProductCategories && product.ProductCategories[0] && product.ProductCategories[0].CategoryName || "");
		var metadata = {
			ProductName: Name,
			UPC: UPC,
			ItemSize: (product && product.ItemSize) || '',
			ProductCategory: productCategory,
			ProductBrand: (product && product.ProductBrand) || '',
			ProductQuantity: quantity,
			IsRecommended: product.IsSaleItem || false
		};

		this.trackEventActionECommerce(enumTrackingShoppingList.AddToList, UPC, metadata); 
	}

	trackRemoveItemToList(product) {
		if (!product) {
			return;
		}
		
		const { ProductUPC, ProductName,  Quantity } = product;
		const quantity = Quantity || 1;
		const productCategory = product.CategoryName || (product.ProductCategories && product.ProductCategories[0] && product.ProductCategories[0].CategoryName || "");
		var metadata = {
			ProductName: ProductName,
			UPC: ProductUPC,
			ItemSize: (product && product.ItemSize) || '',
			ProductCategory: productCategory,
			ProductBrand: (product && product.ProductBrand) || '',
			ProductQuantity: quantity
		};


		this.trackEventActionECommerce(enumTrackingShoppingList.RemoveFromList, ProductUPC, metadata); 
	}

	ngOnDestroy() {
		this.subscriptions.forEach((s) => {
			s.unsubscribe();
		});
	}
}