import { Inject, Injectable, Injector } from '@angular/core';
import _ from 'lodash';

import { CMS_HTTP_CLIENT } from '@rsApp/modules/more/more.config';
import { RelationshopEComCoreHttpClient } from '@rsApp/modules/gateway/rs-ecom-core-api.service';
import { IPageMetadata, IResponeWidgetLayout, IWidgetLayout } from '@rsApp/modules/interfaces/widget-layout.interface';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { refreshParam } from './utils';
import { ENV } from '@app/env';
@Injectable({
	providedIn: 'root'
})
export class WidgetLayoutService {
	groupCacheKey = 'CMS-Widget-Layout';
	constructor(
		@Inject(CMS_HTTP_CLIENT) private apiCore: RelationshopEComCoreHttpClient
	) {
	}

	public getLayout(
		slug: string,
		objectId: string,
		type: string,
		refresher?
	): Observable<IWidgetLayout> {
		let params = {
			slug: slug,
			objectId: objectId,
			type: type && type.toLowerCase()||'',
			tenant: "bigymobile",
		};
		Object.assign(params, refreshParam(refresher, this.groupCacheKey));
		let o = this.apiCore.get<IResponeWidgetLayout>(`/layout/GetlayoutBySlugOrObjectId`, { params: params })
			.pipe(
				map(response => {
					if (response && response.Data && response.Data.Id) {
						response.Data.ZoneInfoValue = response.Data.ZoneInfo ? JSON.parse(response.Data.ZoneInfo) : [];
						return response.Data;
					}
					return null;
				}),
				catchError(error => {
					return of(null);
				}))
		return o;
	}
	public getSEO(slug: string,
		objectId: string,
		type: string = 'page',
		refresher?
	): Observable<IPageMetadata> {
		// const tenant = ENV.Mode.toLowerCase() + "mobile";
		// let params = {
		// 	slug: slug,
		// 	objectId: objectId,
		// 	type: type && type.toLowerCase() || '',
		// 	tenant: tenant,
		// };
		// Object.assign(params, refreshParam(refresher, this.groupCacheKey));
		let o = this.getSEOGetByUrlClient(slug, type, refresher)
			.pipe(
				switchMap(response => {
					if (response) {
						return of(response);
					}
					return this.getSEOGetByObjectIdClient(objectId, type, refresher);

				}),
				catchError(error => {
					return of(null);
				}))
		return o;

	}
	public getSEOGetByUrlClient(slug: string,
		type: string,
		refresher?
	): Observable<IPageMetadata> {
		const tenant = ENV.Mode.toLowerCase() + "mobile";
		let params = {
			url: slug,
			type: type && type.toLowerCase() || '',
			tenant: tenant,
		};
		Object.assign(params, refreshParam(refresher, this.groupCacheKey));
		let o = this.apiCore.get<IResponeWidgetLayout>(`/PageMetadata/GetByUrlClient`, { params: params })
			.pipe(
				map(response => {
                console.log("🚀 ~ file: widget-layout.service.ts ~ line 93 ~ WidgetLayoutService ~ response", response)
					if (response && response.Data && response.Data.Id) {
						return response.Data;
					}
					return null;
				}),
				catchError(error => {
					return of(null);
				}))
		return o;
	}
	public getSEOGetByObjectIdClient(objectId: string,
		type: string,
		refresher?
	): Observable<IPageMetadata> {
		const tenant = ENV.Mode.toLowerCase() + "mobile";
		let params = {
			objectId: objectId,
			type: type && type.toLowerCase() || '',
			tenant: tenant,
		};
		Object.assign(params, refreshParam(refresher, this.groupCacheKey));
		let o = this.apiCore.get<IResponeWidgetLayout>(`/PageMetadata/GetByObjectIdClient`, { params: params })
			.pipe(
				map(response => {
                console.log("🚀 ~ file: widget-layout.service.ts ~ line 117 ~ WidgetLayoutService ~ response", response)
					if (response && response.Data && response.Data.Id) {
						return response.Data;
					}
					return null;
				}),
				catchError(error => {
					return of(null);
				}))
		return o;
	}
}