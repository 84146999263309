import { Component, OnDestroy, Input, HostListener, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CurrentStore } from '@rsApp/modules/store/providers/current-store.service';
import { ActionType } from '@rsApp/modules/utils/enum/enum';

@Component({
	selector: 'cart-list-icon',
	templateUrl: 'cart-list-icon.html',
	styleUrls: ['./cart-list-icon.scss']
})
export class CartListIcon implements OnInit, OnDestroy {
	@Input() watchFromDXPComponent: string;
	context: string = "list";
	isEcom: boolean;
	s: Subscription;
	public isDisplay: boolean = true;
	private isWatchFromDXPComp: boolean;

	constructor( public cStore: CurrentStore ) {
		this.s = cStore.store$.asObservable().pipe(
			tap(() => {
				this.isEcom = cStore.isEcom();
			})
		).subscribe();
	}

	ngOnInit() {
		this.isWatchFromDXPComp = this.watchFromDXPComponent === "true";
		if (this.isWatchFromDXPComp) {
			this.isDisplay = false;
		}
	}

	ngOnDestroy() {
		if (!this.s) {
			return;
		}
		this.s.unsubscribe();
	}

	@HostListener('window:message', ['$event'])
	async listenDxpComponent(event) {
		if (!this.isWatchFromDXPComp) {
			return;
		}

		if (event.data.action == ActionType.DisplayCart) {
			this.isDisplay = event.data.data.showCart;
		}
	}
}