import { Component, OnInit, OnDestroy } from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import { Router } from '@angular/router';
import {CurrentShoppingList} from '../../providers/current-shopping-list.service';
import { AuthService } from '@rsApp/modules/auth/providers/auth.service';
import { ModalController } from '@ionic/angular';
import { GuestMsg } from '@rsApp/modules/utils/components/guest-msg/guest-msg';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';
import { ActionType } from '@rsApp/modules/utils/enum/enum';

@Component({
	selector: 'shopping-list-icon',
	templateUrl: 'shopping-list-icon.html',
	styleUrls: ['./shopping-list-icon.scss']
})
export class ShoppingListIcon implements OnInit, OnDestroy {
	// count:Observable<any>;
	// list: Observable<any>;
	list:any;

	subscriptions:Subscription[] = [];
	constructor(
		private shoppingList: CurrentShoppingList, 
		private router: Router, 
		private auth: AuthService, 
		public modalCtrl: ModalController,
		private events: NgxPubSubService
		) {

	}
	public ngOnInit() {
		// this.list = this.shoppingList.getActiveList();
		let countS = this.shoppingList.getActiveList().subscribe((list)=>{
			// console.log('change List', list.CS_UserShoppingListId);
			this.list = list;
		});	
		this.subscriptions.push(countS);
	}
	public ngOnDestroy(){
		this.subscriptions.forEach((s)=>{
			s.unsubscribe();
		});
	}
	public go(){
		if(!this.auth.cre.currentUser){
			this.showGuestMsg();
	    	return;
	    }
		let list = this.list;

		this.events.publishEvent('close-modal');
		window.postMessage({ action: ActionType.DoCloseAllModal }, "*")

		// console.log('go', list.CS_UserShoppingListId);
		this.router.navigate(['/tabs/shopping-lists/detail', {id: list.CS_UserShoppingListId, name: list.ShoppingListName, isActiveList: true}]);
	}
	async showGuestMsg(){
	    const m = await this.modalCtrl.create({
	      component: GuestMsg
	    });
	    await m.present();
	  }
}