import {Injectable} from '@angular/core';
import {HttpClient, HttpRequest, HttpHeaders, HttpParams, HttpHandler } from '@angular/common/http';

import {Observable, of } from 'rxjs';

import { ENV } from '@app/env';
import { AuthService } from '@rsApp/modules/auth/providers/auth.service';
import { Credential } from '@rsApp/modules/auth/providers/credential.service';

export type HttpObserve = 'body' | 'events' | 'response';


/** reference 
	https://medium.com/@admin_87321/extending-angular-httpclient-episode-2-144b58494db4
	https://github.com/bbogdanov/ExtendAngularHttpClient/blob/master/extendHttpClient/src/app/http-client.ts 
	https://github.com/angular/angular/issues/20203
**/

@Injectable({
  providedIn: 'root'
})
export class RelationshopHttpClient extends HttpClient{
	protected api = ENV.APIUrl;
    constructor(handler: HttpHandler, private cre: Credential) {
       super(handler);
    }
	request(first: string|HttpRequest<any>, url?: string, options: {
    body?: any,
    headers?: HttpHeaders|{[header: string]: string | string[]},
    observe?: HttpObserve,
    params?: HttpParams|{[param: string]: string | string[]},
    reportProgress?: boolean,
    responseType?: 'arraybuffer'|'blob'|'json'|'text',
    withCredentials?: boolean,
  } = {}): Observable<any> {

	let override = {};
	if(!options){
		options = {};
	}
    // end point url
    // url =  ENV.APIUrl+ url;
    url = this.api+url;

    // token
	let headers: HttpHeaders|undefined = undefined;
    if (options.headers instanceof HttpHeaders) {
        headers = options.headers;
    } else {
        headers = new HttpHeaders(options.headers);
    }
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('X-MCMAccessToken', this.cre.rsApiToken || '');
    headers = headers.set('TrackingCode', ENV.TrackingCode || '');
    options.headers = headers;

    // cache params
    // let params: HttpParams|undefined = undefined;
    // if (!!options.params) {
    //     if (options.params instanceof HttpParams) {
    //       params = options.params;
    //     } else {
    //       params = new HttpParams({ fromObject: options.params } as HttpParamsOptions);
    //     }
    // }

    let req = super.request(first as string, url, options as any);
    return req;
  }  
}