import { Component } from '@angular/core';

@Component({
    selector: 'skeleton-component',
    templateUrl: 'skeleton-component.html',
    styleUrls: ['./skeleton-component.scss']
})
export class SkeletonComponent{
    constructor(
    ) {
    }
}