import { Injectable } from '@angular/core';
import { CacheService } from 'ionic-cache';
import { cacheErrorHandle } from './utils';
@Injectable({
	providedIn: 'root'
})
export class PermanentSettings {

	public static cacheTime = 60 * 60 * 24 * 300;  // 300 days
	public static SHOWED_SELECT_STORE = 'SHOWED_SELECT_STORE';
	public static SHOWED_NEWS_55 = 'ion_did_tutorial9'; 
	public bkItems = [];
	constructor(public cache: CacheService){
		// this.cache.sa
	}
	saveItem(key, val){
		return this.cache.saveItem(key, val, null, PermanentSettings.cacheTime);
	}
	getItem(key){
		return this.cache.getItem(key)
		.then((val)=>{
			// refresh extend time;
			this.saveItem(key, val);
			return val;
		})
		.catch(()=>{return null});
	}
	async backup(){
		const showedStoreVal = await this.cache.getItem(PermanentSettings.SHOWED_SELECT_STORE).catch(cacheErrorHandle);;
		if(showedStoreVal !== null){
			this.bkItems.push({key:PermanentSettings.SHOWED_SELECT_STORE, val: showedStoreVal})
		}
	}
	async restore(){
		await Promise.all(
			this.bkItems.map((item)=>{
				return this.saveItem(item.key, item.val);
			})
		)
	}
}