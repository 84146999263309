import { Injectable, NgModule } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub'
import { RelationshopHttpClient } from '@rsApp/modules/gateway/rs-api.service';
import { Credential } from '@rsApp/modules/auth/providers/credential.service';
import { CurrentStore } from '@rsApp/modules/store/providers/current-store.service';
import { tap, map, switchMap, take, debounceTime } from 'rxjs/operators';
import { Observable, combineLatest, of, merge } from 'rxjs';
import _ from 'lodash';
import { AuthService } from '@rsApp/modules/auth/providers/auth.service';
import { RSTracker } from '@rsApp/modules/utils/providers/rs-tracker.service';
import { ShoppingCartGatewayService } from './shopping-cart-gateway.service';
import { DxpComponentService } from '@rsApp/modules/utils/providers/dxp.component.service';
import { enumTrackAction } from '@rsApp/modules/utils/enum/enum';

@Injectable({
	providedIn: 'root'
})
export class CurrentShoppingCart {
	public count$: ReplaySubject<any>;
	public CACHE_TIME: number = 10 * 60 * 1000; // 10 mins
	public lastCahe: number = 0;
	public isFirstLanchApp = true;
	constructor(private cre: Credential, private events: NgxPubSubService, private cartProvider: ShoppingCartGatewayService, private cStore: CurrentStore, private auth: AuthService, public tracker: RSTracker,
		public dxpComponentService: DxpComponentService
	) {
		this.count$ = new ReplaySubject(1);

		merge(this.cStore.store$.asObservable(), auth.authState()).pipe(
			debounceTime(2000)
		).subscribe((store) => {
			if (!cStore.isEcom()) {
				this.count$.next(0);
				return;
			}
			this.events.publishEvent('emit-cart');
		});

		this.events.subscribe('emit-cart', (cartSummary?) => {
			console.log('cartSummary', cartSummary);
			if (cartSummary && cartSummary.TotalQuantity) {
				this.count$.next(cartSummary.TotalQuantity);
			} else {
				this.refresh();
			}
			const dataSource = cartSummary;
			if (!dataSource || dataSource.source !== "dxp-component") {
				this.dxpComponentService.onLoadShoppingCart();
			}
			this.trackEcommerceCartUpdate(cartSummary);
		})
		this.refresh();
	}

	getCount() {
		let now = +new Date(),
			offset = now - this.lastCahe;
		if (offset > this.CACHE_TIME) {
			this.refresh();
		}
		return this.count$.asObservable();
	}

	public async refresh() {
		this.cartProvider.clearCache();
		let currentUser = await this.cre.getCurrentUser();
		let currentStore = await this.cStore.getStore();
		this.lastCahe = +new Date();
		if (currentUser && currentUser.ExternalCustomerCardID && currentStore && currentStore.ExternalID) {
			this.cartProvider.getCartReview(currentUser, currentStore, true)
				.pipe(take(1))
				.subscribe((res: any) => {
					console.log('FINAL CART REVIEW', res);
					if (!res || !res.Summary) {
						return;
					}
					res.Summary.TotalAmount = Number(res && res.Summary && res.Summary.TotalPrice && res.Summary.TotalPrice.replace(/[^\d.]/g, '') || 0)
					// this.tracker.trackEcommerceCartUpdate(res.Summary.TotalAmount);

					this.count$.next((res && res.Summary) ? res.Summary.TotalQuantity : 0);
				});
		}
		else {
			this.count$.next(0);
		}
	}
	public async trackEcommerceCartUpdate(cartSummary) {
		console.log('trackEcommerceCartUpdate');
		let currentUser = await this.cre.getCurrentUser();
		let currentStore = await this.cStore.getStore();
		if (currentUser && currentUser.ExternalCustomerCardID && currentStore && currentStore.ExternalID) {
			const shoppingCart = this.cartProvider.getShoppingCartByUser(currentUser.ExternalCustomerCardID, currentStore, true)
				.pipe(take(1))
				.subscribe((resShoppingCart: any) => {
					this.events.publishEvent(enumTrackAction.TrackEcommerceShoppingCart, { shoppingCart: { ...resShoppingCart } })
				});

		}
	}
}


