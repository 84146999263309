import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root'
})
export class GuestGuardService implements CanActivate{
	constructor(public auth: AuthService, public router: Router){

	}
	canActivate(): Observable<boolean>{
		return this.auth.getCurrentUser().pipe(
			take(1),
			map(user=>{
				if(user){
					this.router.navigate(['/tabs/home']);
				}
				return !user;
			})
		)
	}
}