import { Component, Input, OnChanges, NgZone, OnInit, Output, EventEmitter, ViewEncapsulation } from "@angular/core";
import { IWidgetLayout, IWidgetZoneInfo, IZoneInfo } from "@rsApp/modules/interfaces/widget-layout.interface";
import { DxpComponentService } from "../../providers/dxp.component.service";
import { WidgetLayoutService } from "../../providers/widget-layout.service";

@Component({
  selector: "delete-account",
  templateUrl: "delete-account.component.html",
  styleUrls: ['delete-account.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DeleteAccountComponent implements OnInit, OnChanges {
  @Input() public step: number;
  @Input() public widgetId?: string;
  @Input() public widgetCode?: string;
  @Input() public type?: string;
  @Input() public objectId?: string;
  @Input() public slug?: string;
  @Input() public zoneName?: string;

  @Output() onVerifyPasswordSucceed = new EventEmitter();
  @Output() onLogout = new EventEmitter();
  widgetHTML: string = '';
  constructor(private zone: NgZone,
    public widgetLayoutService: WidgetLayoutService,
    public dxpComponentService: DxpComponentService
  ) {
  }
  async ngOnInit() {
    this.widgetHTML = `<dxp-delete-account step=${this.step} ></dxp-delete-account>`;

    window.addEventListener("onVerifyPassword", event => {
      console.log('line 29 - handle on verify password', event);
      this.onVerifyPasswordSucceed.emit();
    });

    window.addEventListener("onLogout", event => {
      console.log('line 29 - handle on logout', event);
      this.onLogout.emit();
    });

    // console.log("🚀 ~ file: delete-account.component.ts ~ line 25 ~ WidgetLayoutComponent ~ ngOnInit ~ ngOnInit")
    // if (this.widgetId) {
    //   //set
    // } else if (this.widgetCode) {

    // } else if (this.type) {
    //   const slug = this.slug && this.slug[0] == '/' ? this.slug.substring(1) : this.slug;
    //   this.widgetLayoutService.getLayout(slug, this.objectId, this.type).subscribe(async (zoneName: IWidgetLayout) => {
    //     console.log(`type: ${this.type},
    //     slug: ${slug},
    //     objectId: ${this.objectId},
    //     `);
    //     console.log("🚀 ~ file: delete-account.component.ts ~ line 31 ~ WidgetLayoutComponent ~ this.widgetLayoutService.getLayout ~ zoneName", zoneName)
    //     await this.dxpComponentService.onConnected();
    //     const zone: IZoneInfo = zoneName && zoneName.ZoneInfoValue && zoneName.ZoneInfoValue.find(s => s.Name == this.zoneName);
    //     console.log("🚀 ~ file: widget-layout.component.ts ~ line 38 ~ WidgetLayoutComponent ~ this.widgetLayoutService.getLayout ~ zone", zone)
    //     if (!zone) {
    //       return;
    //     }
    //     this.widgetHTML = zone && zone.Widgets && zone.Widgets.map(widget => {

    //       return widget && widget.Id && `
    //       <dxp-delete-account step=${this.step}></dxp-delete-account>
    //     ` || '';
    //     }).join('');
    //     console.log("🚀 ~ file: widget-layout.component.ts ~ line 39 ~ WidgetLayoutComponent ~ this.widgetLayoutService.getLayout ~  this.widgetHTML", this.widgetHTML)
    //   });
    // }
  }

  public ngOnChanges() {

  }

  private handleOnVerifyAccount() {
    console.log('line 63 - verify account')
  }


}
