import { Component, Input, NgZone, OnInit } from "@angular/core";
import { IWidgetLayout, IZoneInfo } from "@rsApp/modules/interfaces/widget-layout.interface";
import { DxpComponentService } from "../../providers/dxp.component.service";
import { WidgetLayoutService } from "../../providers/widget-layout.service";
import { ZoneLayoutType } from "@rsApp/core/enum";
import * as  CookieStorage from "../../providers/cookies";

@Component({
  selector: "widget-layout",
  templateUrl: "widget-layout.component.html",
  styleUrls: ['widget-layout.component.scss']
})
export class WidgetLayoutComponent implements OnInit {
  @Input() public widgetId?: string;
  @Input() public widgetCode?: string;
  @Input() public type?: string;
  @Input() public objectId?: string;
  @Input() public slug?: string;
  @Input() public zoneName?: string;

  widgetHTML: string = '';
  public ZoneLayoutType = ZoneLayoutType;

  constructor(private zone: NgZone,
    public widgetLayoutService: WidgetLayoutService,
    public dxpComponentService: DxpComponentService
  ) {
  }
  async ngOnInit() {
    if (this.widgetId) {
      //set
    } else if (this.widgetCode) {

    } else if (this.type) {
      const slug = this.slug && this.slug[0] == '/' ? this.slug.substring(1) : this.slug;
      this.widgetLayoutService.getLayout(slug, this.objectId, this.type).subscribe(async (zoneName: IWidgetLayout) => {
        await this.dxpComponentService.onConnected();
        const zone: IZoneInfo = zoneName && zoneName.ZoneInfoValue && zoneName.ZoneInfoValue.find(s => s.Name == this.zoneName);
  
        if (!zone || ( this.zoneName == ZoneLayoutType.ZONE_FIXED_TOP && CookieStorage.getCookie('HIDDEN_WIDGETS_FIXED_TOP') ) || ( this.zoneName == ZoneLayoutType.ZONE_FIXED_CENTER && CookieStorage.getCookie('HIDDEN_WIDGETS_FIXED_CENTER') ) ) {
          return;
        }

        if ( zone.Widgets.length > 0 && this.zoneName == ZoneLayoutType.ZONE_FIXED_CENTER ) {
          window.postMessage({ action: 'OPEN_FIXED_CENTER_WIDGETS',  data: zone.Widgets }, '*');
          return;
        }

        this.widgetHTML = zone && zone.Widgets && zone.Widgets.map(widget => {
          return widget && widget.Id && `
          <dxp-widget widgetid=${widget.Id} viewmode="Mobile"></dxp-widget>
        ` || '';
        }).join('');
      });
    }
  }

  public hiddenFixedTopWidgets() {
    document.getElementsByClassName("widget-fixed-top")[0].remove();
    CookieStorage.setCookie('HIDDEN_WIDGETS_FIXED_TOP', 'true', 30, null );
  }
}
