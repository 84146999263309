import { NgxPubSubService } from '@pscoped/ngx-pub-sub'
import { Injectable } from '@angular/core';

import { CacheService } from 'ionic-cache';
import { Observable, Observer, of } from 'rxjs';
import { tap, catchError, switchMap, map } from 'rxjs/operators';

import _ from 'lodash';

import { ENV } from '@app/env';
import { enumProductType } from '@rsApp/modules/utils/enum/enum.ts';
import { RelationshopHttpClient } from '@rsApp/modules/gateway/rs-api.service';
// import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { cacheErrorHandle } from '@rsApp/modules/utils/providers/utils';
@Injectable()
export class ShoppingList {
    _rewards: any;
    public shoppingListPath = 'shoppinglists/v5/all';
    constructor(
        private api: RelationshopHttpClient,
        private cache: CacheService,
        // public http: Http,
        public events: NgxPubSubService) {
    }

    getCouponInShoppingList(user, couponId) {
        return this.api.get(`/ShoppingLists/get-coupon?username=${user.Email}&couponId=${couponId}`);
    }
    /** will nore to current-shopping-list */
    getCountItemsInShoppingList(user, userShoppingListId = 0) {
        return this.api.get(`/ShoppingLists/user-items/v5/count?username=${user.Email}&userShoppingListId=${userShoppingListId}`, { params: { refresher: 'true' } })
    }

    removeShoppingListItemsMulti(user, items, shoppingListId) {

        const itemRemoveArray = [];
        _.forEach(items, function (item) {

            if (item.ShoppingListItemType == 'Notes') {
                item.ShoppingListId = item.CS_ShoppingListNoteID;
            }
            const itemRemove = {
                'ShoppingListId': item.ShoppingListId,
                'ShoppingListItemType': item.ItemType
            };
            itemRemoveArray.push(itemRemove);
        });

        // console.log('RemoveShoppingListItemsMulti', itemRemoveArray);
        const url = '/ShoppingLists/remove-items/multi';
        return this.api.request('DELETE', url, { body: itemRemoveArray }).pipe(
            tap(() => {
                this.events.publishEvent('emmit-list', { id: shoppingListId });
                this.cache.removeItem('shoppinglists-get-note');
                this.cache.removeItem('/shoppinglists/Items/V5' + shoppingListId);
            })
        )
    }

    removeCouponInShoppingList(user, cpn) {
        // console.log('removeCouponInShoppingList', user, cpn);
        const params = {
            CS_ShoppingListCouponID: cpn.ShoppingListItemId + '',
            CS_UserShoppingListId: cpn.ShoppingListId,
            CouponCode: cpn.UCPCode,
        };
        const url = '/ShoppingLists/remove-coupon' + "?username=" + user.Email;
        return this.api.request('DELETE', url, { body: params }).pipe(
            tap(() => {
                this.cache.removeItem('shoppinglists/user-items' + cpn.CS_UserShoppingListId);
            })
        );
    }

    removeOfferInShoppingList(user, offer) {
        // console.log('removeCouponInShoppingList', user, cpn);
        const params = {
            CS_ShoppingListCouponID: offer.ShoppingListItemId + '',
            CS_UserShoppingListId: offer.ShoppingListId,
            CouponCode: offer.CpnCode,
        };
        const url = '/ShoppingLists/remove-coupon' + "?username=" + user.Email;
        return this.api.request('DELETE', url, { body: params }).pipe(
            tap(() => {
                this.cache.removeItem('shoppinglists/user-items' + offer.CS_UserShoppingListId);
            })
        );
    }

    private handleError(error: any): Promise<any> {
        // console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }

    addNoteItemToShoppingList(itemNote, userName, shoppingListId) {
        this.cache.removeItem('/shoppinglists/Items/V5' + shoppingListId);
        itemNote.CS_UserShoppingListId = shoppingListId;
        const req = this.api.post('/ShoppingLists/item/note?username=' + userName, itemNote).pipe(
            tap(() => { this.events.publishEvent('emmit-list', { id: shoppingListId }); })
        );
        return req;
    }

    removeNoteItemFromShoppingList(user, item, shoppingListId) {
        console.log('removeNoteInShoppingList', user, item);
        const url = '/ShoppingLists/Note/' + item.ShoppingListId
        return this.api.delete(url).pipe(
            tap(() => {
                this.events.publishEvent('emmit-list', { id: shoppingListId });
                this.cache.removeItem('/shoppinglists/Items/V5' + shoppingListId);
            })
        )
    }

    getItemInShoppingList(user, shoppingListId, shoppingListType) {
        const url = '/shoppinglists/item?shoppinglistID=' + shoppingListId + '&shoppinglistType=' + shoppingListType;
        return this.api.get(url);
        // tslint:disable-next-line:max-line-length
        // return this.restangular.all('/shoppinglists/item?shoppinglistID=' + shoppingListId + '&shoppinglistType=' + shoppingListType).customGET("", {});
    }

    addEcomProducToShoppingList(product, shoppingListId, user) {
        // console.log('addEcomProductoShoppingListV5', product);
        const fullProductName = `${product.FriendlyName || product.Name} ${product.ShortDescription ? `, ${product.ShortDescription}`: ''}`;
        const params = {
            UserName: user.Email,
            Quantity: product.Quantity || 1,
            // tslint:disable-next-line:max-line-length
            CategoryName: product.ProductCategories && product.ProductCategories[0] && product.ProductCategories[0].CategoryName || product.RSCategoryName || product.CategoryName || '',
            // tslint:disable-next-line:max-line-length
            CategoryID: product.ProductCategories && product.ProductCategories[0] && product.ProductCategories[0].CategoryId || product.RSCategory || product.Category || product.CategoryID || '0',
            ReferenceID: product.ReferenceID,
            ProductName: fullProductName,
            ItemType: enumProductType.EcomProduct,
            UserShoppingListId: shoppingListId,
            ExternalID: product.ExternalID || product.UPC || product.Sku,
            Description: product.FullDescription || '',
            UnitPrice: product.SalePrice || 0,
            Price: product.Price || 0,
            Note: null,
            ImageUrl: product.DefaultImage,
            ProductUPC: product.UPC || product.Sku,
        };
        // console.log("addEcomProductoShoppingListV5 params", params);
        return this.api.post('/ShoppingLists/add-item/v5?listId=' + shoppingListId, params)
            .pipe(
                tap((rs) => {
                    this.cache.removeItem('/shoppinglists/Items/V5' + shoppingListId);
                    // this.getCountItemsInShoppingList(user).subscribe(res => {
                    //     this.events.publishEvent('emmit-list', res);
                    // });
                    this.events.publishEvent('emmit-list');
                })
            );
        // .toPromise().then(rs => {
        //     this.cache.removeItem('/shoppinglists/Items/V5' + shoppingListId);
        //     this.getCountItemsInShoppingList(user).subscribe(res => {
        //         this.events.publishEvent('emmit-list', res);
        //     });
        //     this.events.publishEvent('emmit-list');
        // });

    }

    updateProductToShoppingList(item, listId, user) {
        const params = {
            Quantity: item.Quantity,
            ShoppingListId: item.ShoppingListId,
            Note: item.Note,
            UserName: user.Email
        };
        return this.api.post('/ShoppingLists/update-item/v5', params).pipe(
            tap(() => {
                this.cache.removeItem('/shoppinglists/Items/V5' + listId);
                this.events.publishEvent('emmit-list');
            })
        );
    }

    addCouponToShoppingList(user, coupon) {
        const params = {
            CategoryID: coupon.Category && coupon.Category[0].Name,
            CouponID: coupon.ID,
            CouponType: "MfrDigitalCoupon",
            Description: coupon.Description,
            Quantity: 1,
            ReferenceID: null,
            UserName: user.Email + ''
        };
        this.clearCache(user);
        const req = this.api.post('/ShoppingLists/add-coupon/v4?username=' + user.Email, params);
        return req;
    }

    addOfferToShoppingList(user, offer) {
        const params = {
            CategoryID: offer.Cats && offer.Cats[0].CategoryName,
            CouponID: offer.ID,
            CouponType: "MfrDigitalCoupon",
            Description: offer.Description,
            Quantity: 1,
            ReferenceID: null,
            UserName: user.Email + ''
        };
        this.clearCache(user);
        const req = this.api.post('/ShoppingLists/add-coupon/v4?username=' + user.Email, params);
        return req;
    }

    addIngredients(ings, user) {
        if(!ings || ings.length == 0 ){
            return of();
        }
        const firstIng = ings.length > 0 && ings[0] || null;
        const data = {
            IngredientsList: _.map(ings,'IngredientName').join('<ING>'),
            RecipeId: ings[0].RecipeID,
            CS_UserShoppingListId: ings[0].UserShoppingListID
        }
        return this.api.post(`/ShoppingLists/add-ingredients?username=${user.Email}`, data).pipe(
            tap(() => {
                this.cache.removeItem('/shoppinglists/Items/V5' + ings[0].UserShoppingListID);
                this.events.publishEvent('emmit-list', { id: ings[0].UserShoppingListID });
            })
        );
        /* EF API */
        /*
        return this.api.post`/ShoppingLists/add-ingredients/v5?username=${user.Email}`, ings).pipe(
            tap(() => {
                this.cache.removeItem('/shoppinglists/Items/V5' + ings[0].UserShoppingListID);
                this.events.publishEvent('emmit-list', { id: ings[0].UserShoppingListID });
            })
        );
        */
    }

    clearCache(user, shoppingListId?) {
        this.cache.removeItem('cards/' + user.SRCardID + '/rewards');
        this.cache.removeItem('srcoupons-DigitalCoupon');
        this.cache.removeItem('srcoupons-AvailableOffer');
        if(shoppingListId){
            this.cache.removeItem('/shoppinglists/Items/V5' + shoppingListId);
        }
    }

    getTotalItemsShoppingList(user, userShoppingListId) {
        let req = this.api.get(`/ShoppingLists/user-items/v5/count?username=${user.Email}&userShoppingListId=${userShoppingListId}`);
        return req;
    }

    getAllShoppingList(user, refresher?): Observable<any> {
        let params = { username: user.Email };
        return this.api.get('/shoppinglists/v5/all', { params: params })

    }
    /* move to favorite-list */
    /*
    getUserFavorite(user, refresher?) {
        let url = '/favoritelists/all';
        let params = {
            username: user.Email,
            type: '1'
        };
        return this.api.get(url, {params:params});
    }

    getItemsInFavoriteList(user, userShoppingListId, refresher?) {
        let url = '/favoritelists/item';
        let params = {
            username: user.Email,
            userShoppingListId: userShoppingListId
        };
        return this.api.get(url,{params: params});
    }

    addItemToFavoriteList(product, listId, userName) {
        console.log("addItemToFavoriteList", product);
        let params = {
            ReferenceID: product.ReferenceID,
            ProductUPC: product.UPC,
            ProductName: product.Name,
            UserName: userName,
            ItemType: enumProductType.EcomProduct,
            UserFavotireListId: listId,
            CategoryID: product.ProductCategories ? product.ProductCategories[0].CategoryId : 0,
            CategoryName: product.ProductCategories ? product.ProductCategories[0].CategoryName : "",
            ExternalID: product.ExternalID ? product.ExternalID : product.Id,
            Description: JSON.stringify(product.FullDescription),
            ImageUrl: product.DefaultImage,
        };
        console.log("addItemToFavoriteList params", params);
        this.cache.removeItem('/favoritelists/item');
        let req = this.api.post('/favoritelists/add-item?listId=' + listId, params);
        
        return req;
    }

    removeItemFromFavoriteList(product, listId, user) {
        let params = {
            
        };
        let url ='/favoritelists/' + user.Email + "/Item?itemIdToRemove="+product.ItemIdInFavorite;
        return this.api.request('DELETE', url, {body: params}).pipe(
            tap(() => {
                this.cache.removeItem('favoritelists/item');
            })
        );
    }
    */

    updateUserShoppingList(user, shoppinglist) {
        //clearCache: ['/shoppinglists/all?username=' + userid]
        let url = `/ShoppingLists/update`;
        return this.api.post(url, shoppinglist).toPromise();
    }
    getShoppingListById(user, shoppingListId, refresher?): Observable<any> {
        // let self = this;
        console.log('getShoppingListById');
        let url = 'shoppinglists/published-items';
        let cacheKey = 'shoppinglists-' + shoppingListId;
        let params = { userid: user.UserID, userShoppingListId: shoppingListId },
            cacheParams = { rCache: refresher ? 'fresh' : 'default', rCacheKey: cacheKey }
        return this.api.get(url, { params: Object.assign(params, cacheParams) });
    }
    removeItemFromShoppingList(user, item) {
        console.log('remove ---');
        let params = {
            ItemID: item.ShoppingListId + '',
            CS_UserShoppingListId: item.CS_UserShoppingListId,
        };
        let url = '/ShoppingLists/remove-item' + "?username=" + user.Email;
        return this.api.request('DELETE', url, { body: params }).pipe(
            tap(() => {
                this.cache.removeItem('/shoppinglists/Items/V5' + item.CS_UserShoppingListId);
                // this.getCountItemsInShoppingList(user).subscribe(res => {
                //     this.events.publishEvent('emmit-list', res);
                // });
                this.events.publishEvent('emmit-list');
            })
        );
    }

    activeShoppingList(shoppingListId): Observable<any> {
        // this.restangular.all('/shoppinglists/' + shoppingListId + '/Active').customPOST("");
        return this.api.post('/shoppinglists/' + shoppingListId + '/Active', {}).pipe(
            switchMap((result) => {
                return this.cache.getItem(this.shoppingListPath).catch(cacheErrorHandle)
            }),
            tap((cacheShoppingList: any) => {
                if (!cacheShoppingList) {
                    this.events.publishEvent('emmit-list', { reload: true });
                    return;
                }
                cacheShoppingList.forEach(list => {
                    if (list.CS_UserShoppingListId == shoppingListId) {
                        console.log("changeShoppingList list", list);
                        list.IsActive = true;
                    } else {
                        list.IsActive = false;
                    }
                });
                this.cache.saveItem(this.shoppingListPath, cacheShoppingList);
                this.events.publishEvent('emmit-list', { reload: true });
            }),
            catchError((error) => {
                console.log("activeShoppingList error", error);
                return of(null);
            })
        );
    }

    getProductsInShoppingList(user, shoppingListId?, refresher?): Observable<any> {
        // let self = this;

        let url = '/shoppinglists/Items/V5',
            params = {
                username: user.Email,
                userShoppingListId: shoppingListId || ''
            },
            cacheParams = { rCache: refresher ? 'fresh' : 'default', rCacheKey: url + shoppingListId };
        return this.api.get(url, { params: Object.assign(params, cacheParams) });
    }
    getNotes(user, refresher?): Observable<any> {
        // let self = this;
        let url = 'shoppinglists/user-notes?username=' + user.Email;
        let cacheKey = 'shoppinglists-get-note',
            cacheParams = { rCache: refresher ? 'fresh' : 'default', rCacheKey: cacheKey };
        return this.api.get(url, { params: cacheParams });
    }
    getItemCats(refresher?): Observable<any> {
        // let self = this;
        let url = '/categories/RolloverData',
            cacheParams = { rCache: refresher ? 'fresh' : 'default' };
        return this.api.get(url, { params: cacheParams }).pipe(
            map((rs: any) => {
                // console.log(rs);
                return rs;
            })
        );
    }
    deleteShoppingList(user, listId) {
        // console.log('removeCouponInShoppingList', user);
        let url = `/ShoppingLists/${listId}?username=${user.Email}`;
        return this.api.delete(url).pipe(
            tap(() => {
                this.cache.removeItem('account-shoppinglists');
            })
        );
    }

    addItemsFavoriteToShoppingList(user, shoppingListId, favoriteListsId, listItem) {
        let ids = _.map(listItem, 'ShoppingListId');
        let url = `/favoritelists/add-to/shoppinglist?username=${user.Email}&activeShoppingListId=${shoppingListId}&userShoppingListId=${favoriteListsId}`;
        return this.api.post(url, ids).toPromise().then(() => {
            this.cache.removeItem('/shoppinglists/Items/V5' + shoppingListId);
            this.events.publishEvent('emmit-list');
        });
    }
    edit(shoppingList: any) {
        let data = {
            CS_UserShoppingListId: shoppingList.CS_UserShoppingListId,
            ShoppingListName: shoppingList.ShoppingListName
        }
        return this.api.post('/shoppinglists/update', data);
    }
    createShoppingList(shoppinglist) {
        // let data = {
        //     Shopping
        // }
        return this.api.post('/shoppinglists', shoppinglist);
    }
    shareShoppingList(userName, email, listId) {
        let req = this.api.post(`/ShoppingLists/send-email?username=${userName}&userShoppingListId=${listId}`, "'" + email + "'");
        return req
    }
}
@Injectable()
export class CheckedShoppingItem {
    _rewards: any;

    constructor(private cache: CacheService) {
    }
    updateCheckedItems(checkedItems, userid) {
        console.log('updateCheckedItems');
        var key = '/shoppinglist/' + userid + '/checked';
        this.cache.saveItem(key, checkedItems);
    }
    async getCheckedItems(userid) {
        var key = '/shoppinglist/' + userid + '/checked';
        let item = await this.cache.getItem(key).catch(cacheErrorHandle);
        return item || [];
    }
    check(item, userid) {
        console.log('check', item, userid);
        return this.getCheckedItems(userid).then(checkeditems => {
            var checkedItem = _.find(checkeditems, function (shoppinglistId) {
                return shoppinglistId == item.ShoppingListId || shoppinglistId == 'n_' + item.CS_ShoppingListNoteID || shoppinglistId == 'c_' + item.ProductId;
            });
            item.checked = true;
            if (checkedItem) {
                return true;
            }
            checkeditems.push(item.ShoppingListId || (item.CS_ShoppingListNoteID ? 'n_' + item.CS_ShoppingListNoteID : 'c_' + item.ProductId));
            var limitLength = 1000;
            if (checkeditems.length > limitLength) {
                checkeditems = checkeditems.slice(checkeditems.length - limitLength, checkeditems.length);
            }
            this.updateCheckedItems(checkeditems, userid);
            return true;
        });
    }
    uncheck(item, userid) {
        return this.getCheckedItems(userid).then(checkeditems => {
            _.remove(checkeditems, function (shoppinglistId) {
                return shoppinglistId == item.ShoppingListId || shoppinglistId == 'n_' + item.CS_ShoppingListNoteID || shoppinglistId == 'c_' + item.ProductId;
            });
            item.checked = false;
            this.updateCheckedItems(checkeditems, userid);
            return true;
        });
    }
    async loadCheckedProperty(shoppinglist, userid) {
        let checkedIds = await this.getCheckedItems(userid);
        let newchekedIds = _.clone(checkedIds);
        // load and clean
        _.forEach(checkedIds, function (checkedId) {
            var shoppingItem = _.find(shoppinglist, function (sItem) {
                return sItem.ShoppingListId == checkedId || checkedId == 'n_' + sItem.CS_ShoppingListNoteID || checkedId == 'c_' + sItem.ProductId;
            });
            if (shoppingItem) {
                shoppingItem.checked = true;
            }
            // clean checked item
            else {
                _.remove(newchekedIds, function (c) {
                    return c == checkedId;
                })
            }
        });
        this.updateCheckedItems(newchekedIds, userid);
    }

}