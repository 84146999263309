import { Injectable } from '@angular/core';

import { CacheService } from 'ionic-cache';
import { Observable, Observer, of } from 'rxjs';
import { tap, catchError, switchMap } from 'rxjs/operators';

import _ from 'lodash';
import {cacheErrorHandle} from '@rsApp/modules/utils/providers/utils';

@Injectable()
export class CheckedShoppingItemService {
    _rewards: any;

    constructor(private cache: CacheService) {
    }
    updateCheckedItems(checkedItems, userid) {
        // console.log('updateCheckedItems');
        var key = '/shoppinglist/' + userid + '/checked';
        return this.cache.saveItem(key, checkedItems);
    }
    getCheckedItems(userid) {
        var key = '/shoppinglist/' + userid + '/checked';
        return this.cache.getItem(key).then(res => {
            // console.log('getCheckedItems getItem', res);

            return res || []
        }
            , error => {
                console.log('getCheckedItems');
                return []
            });
    }
    check(item, userid) {
        return this.getCheckedItems(userid).then(checkeditems => {
            var checkedItem = _.find(checkeditems, function (shoppinglistId) {
                return shoppinglistId == item.ShoppingListId || shoppinglistId == 'n_' + item.CS_ShoppingListNoteID || shoppinglistId == 'c_' + item.ProductId;
            });
            item.checked = true;
            if (checkedItem) {
                return true;
            }
            checkeditems.push(item.ShoppingListId || (item.CS_ShoppingListNoteID ? 'n_' + item.CS_ShoppingListNoteID : 'c_' + item.ProductId));
            var limitLength = 1000;
            if (checkeditems.length > limitLength) {
                checkeditems = checkeditems.slice(checkeditems.length - limitLength, checkeditems.length);
            }
            this.updateCheckedItems(checkeditems, userid);
            return true;
        });
    }
    async  uncheck(item, userid) {
        // console.log('uncheck')
        let checkeditems = await this.getCheckedItems(userid);
        _.remove(checkeditems, function (shoppinglistId) {
            return shoppinglistId == item.ShoppingListId || shoppinglistId == 'n_' + item.CS_ShoppingListNoteID || shoppinglistId == 'c_' + item.ProductId;
        });
        item.checked = false;
        return this.updateCheckedItems(checkeditems, userid);
    }
    async multiUnCheck(items, userid){
        let checkeditems = await this.getCheckedItems(userid);
        _.each(items, (item)=>{
            _.remove(checkeditems, function(shoppinglistId){
                return shoppinglistId == item.ShoppingListId || shoppinglistId == 'n_' + item.CS_ShoppingListNoteID || shoppinglistId == 'c_' + item.ProductId;
            });
            item.checked = false;
        });
        return this.updateCheckedItems(checkeditems, userid);
    }
    async loadCheckedProperty(shoppinglist, userid) {
        let checkedIds = await this.getCheckedItems(userid);
        let newchekedIds = _.clone(checkedIds);
        // load and clean
        _.forEach(checkedIds, function (checkedId) {
            var shoppingItem = _.find(shoppinglist, function (sItem) {
                return sItem.ShoppingListId == checkedId || checkedId == 'n_' + sItem.CS_ShoppingListNoteID || checkedId == 'c_' + sItem.ProductId;
            });
            if (shoppingItem) {
                shoppingItem.checked = true;
            }
            // clean checked item
            else {
                _.remove(newchekedIds, function (c) {
                    return c == checkedId;
                })
            }
        });
        this.updateCheckedItems(newchekedIds, userid);
        return shoppinglist;
    }

}