import { NgxPubSubService } from '@pscoped/ngx-pub-sub'
import { Injectable } from '@angular/core';
import { CacheService } from 'ionic-cache';
import { tap, switchMap, map } from 'rxjs/operators';
import _ from 'lodash';
import { refreshParam } from '@rsApp/modules/utils/providers/utils';
import { RelationshopOnlineEComHttpClient } from '../../gateway/rs-online-ecom-api.service';
import { RSTracker } from '@rsApp/modules/utils/providers/rs-tracker.service';
import { of } from 'rxjs';
import { enumTrackAction } from '@rsApp/modules/utils/enum/enum';

@Injectable()
export class ShoppingCartService {
    public shoppingCartPath = '/shoppingcart';
    public groupCacheKey = 'cart';
    constructor(
        public api: RelationshopOnlineEComHttpClient,
        private cache: CacheService,
        public tracker: RSTracker,
        public events: NgxPubSubService) {
    }

    clearCache() {
        console.log('clearCache');
        this.cache.clearGroup(this.groupCacheKey);
    }

    getShoppingCartByUser(un, store, refresher = false) {
        const url = `${this.shoppingCartPath}/user`;
        const params = {
            un,
            storeId: store.ExternalID
        }
        Object.assign(params, refreshParam(refresher, this.groupCacheKey, null, null));
        return this.api.get(url, { params });
    }
    getCartReview(user, store, refresher?) {
        const params = {
            un: user.ExternalCustomerCardID,
            storeId: store.ExternalID
        };
        Object.assign(params, refreshParam(refresher, this.groupCacheKey, null, null));
        return this.api.get(`/shoppingcart/review`, { params });
    }

    addItem(cartItem: {
        UserId: string,
        UserName: string,
        ProductVariantId: string,
        StoreId: any,
        Quantity: number,
        Message?: string,
        AllowSubstitutions?: boolean
    }, notUpdateAllowSub?: boolean) {
        this.clearCache();
        if (notUpdateAllowSub !== true) {
            cartItem.AllowSubstitutions = true;
        }
        return this.api.post(`/shoppingcart/add-item`, cartItem).pipe(
            tap((rs) => {
                this.events.publishEvent('emit-cart', rs);
            })
        );
    }

    addItems(cartItems: any) {
        this.clearCache();
        cartItems.forEach(item => {
            item.StoreId = item.StoreId.ExternalID;
        });

        return this.api.post(`/shoppingcart/add-items`, cartItems).pipe(
            tap((rs) => {
                // _.forEach(cartItems, product => {
                //     this.tracker.addEcommerceItem(product);
                // });
                this.events.publishEvent('emit-cart', rs);
            })
        );
        // const requestArray = [];
        // cartItems.forEach(item => {
        //     requestArray.push(this.addItem(item));
        // });
        // return combineLatest(requestArray).pipe(
        //     map((rs: any) => {
        //         return true;
        //     })
        // );
    }

    updateSize(cartItem: any) {
        return this.deleteItem(cartItem).pipe(
            switchMap((rs) => {
                // this.tracker.addEcommerceItem(cartItems);
                return this.addItem(cartItem, true);
            })
        );
    }

    updateItem(cartItem: any, returnFull = false) {
        this.clearCache();
        if (cartItem.Quantity <= 0) {
            return this.deleteItem(cartItem);
        }
        // code update
        return this.api.post(`/shoppingcart/update-item`, cartItem).pipe(
            map((rs: any) => {
                this.events.publishEvent('emit-cart', rs);
                // this.tracker.addEcommerceItem(cartItem);
                // return rs.ShoppingCartItems && rs.ShoppingCartItems[0];
                if (returnFull) {
                    return rs;
                }
                console.log('return item');
                return rs.ShoppingCartItems && rs.ShoppingCartItems[0];
            })
        );
    }

    updateAllowsSubstitutions(user, store, isUpdate) {
        this.clearCache();
        // tslint:disable-next-line:max-line-length
        return this.api.post(`/shoppingcart/itemSubstitution/update-all?userId=${user.ExternalCustomerCardID}&storeId=${store.ExternalID}&IsUpdate=${isUpdate}`, null);
    }


    deleteItem(cartItem, productV?) {
        this.clearCache();
        const params = { userId: cartItem.UserId, storeId: cartItem.StoreId, id: cartItem.Id };
        return this.api.delete(`/shoppingcart/delete-item`, { params }).pipe(
            map((rs) => {
                this.events.publishEvent(enumTrackAction.RemoveEcommerceItem, { product: { ...(productV || cartItem) } });
                this.events.publishEvent('emit-cart', rs);
                return null;
            })
        );
    }

    deleteAllItem(user, store) {
        this.clearCache();
        const params = { userId: user.ExternalCustomerCardID, storeId: store.ExternalID };
        return this.api.delete(`/shoppingcart/delete-all`, { params }).pipe(
            map((rs) => {
                this.events.publishEvent(enumTrackAction.ClearEcommerceCart, { isTrackEvent: false });
                this.events.publishEvent('emit-cart', rs);
                return null;
            })
        );
    }

    addProduct(product: any, user: any, store: any, qty?: number) {
        const cartItem = {
            UserName: user.ExternalCustomerCardID,
            UserId: user.ExternalCustomerCardID,
            ProductVariantId: product.ItemKey,
            StoreId: store.ExternalID,
            Quantity: qty || 1
        };
        // this.tracker.addEcommerceItem(product);
        return this.addItem(cartItem);

    }

    getRuleByStore(store, ruleName){
        return of(false);
    }
}
