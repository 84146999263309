import { Injectable, Component } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, take, catchError, timeout } from 'rxjs/operators';
import { AppSettings } from '@rsApp/modules/utils/providers/app-setting';
import { Platform, AlertController } from '@ionic/angular';
import { Utils } from '@rsApp/modules/utils/providers/utils';
import { ENV } from '@app/env';
import { ModalController } from '@ionic/angular';

@Injectable({
	providedIn: 'root'
})
export class IsOutDateGuardService implements CanActivate{
	constructor(
		public appSetting: AppSettings, 
		public router: Router, 
		public platform: Platform, 
		public alertCtrl: AlertController,
		public utils: Utils,
		public modalCtrl: ModalController){

	}
	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		
		const s = this.appSetting.getLastVersion(ENV.DefaultBanerId).pipe(
			timeout(3000),
			map((rs:any)=>{
				let isOutDate = false;
				// if(!rs || !rs.Device){
				// 	return isOutDate;
				// }
				let buidNumber = parseInt(ENV.BuildNumber) || 600000;
				// get globalbuild number
				let device = null;
				if(this.platform.is('ios')){
					device = 'IOS'
				}
				else if(this.platform.is('android')){
					device = 'Android'
				}
				if(!device){
					return isOutDate;
				}
				let platformSetting= rs.Device.find(el=> el.DeviceName == device);
				if(!platformSetting){
					return isOutDate;
				}

				let globalBuildNumber = parseInt(platformSetting.Version) || 0;
				// globalBuildNumber = 550042;
				if(buidNumber < globalBuildNumber){
					isOutDate = true;
					this.showOutDate(platformSetting);
				}
				return isOutDate;
			}),
			
			map((isOutDate)=>{
				return true;
			}),
			catchError((err)=>{
				return of(true);
			}),
		)
		return s;
	}
	async showOutDate(platformSetting) {
		let m = await this.modalCtrl.create({
			component:OutDateModal, 
			componentProps:{platformSetting}
		});

		m.onDidDismiss().then(() => {
			let buidNumber = parseInt(ENV.BuildNumber) || 600000;
			let globalBuildNumber = parseInt(platformSetting.Version) || 0;

			if (buidNumber < globalBuildNumber) {
				this.showOutDate(platformSetting);
			}
		});

		m.present();
	}
}

@Component({
	selector: 'page-outdate',
	template: `
		<ion-content class="ion-padding-horizontal outdate-content">
			<h1>We've made some<br>improvements in our App</h1>	
			<p>Use the link below to get the latest update.</p>
			<ion-button class="update-now-btn" color="primary" (click)="clickUpdate()">UPDATE NOW</ion-button>
		</ion-content>
	`,
	styles: [
	`.outdate-content {
		text-align: center;
		--background: white url(./assets/img/app-update.png)  no-repeat center/cover;
	}
	.outdate-content ion-button {
			position: absolute;
		    left: 50%;
		    margin-left: -60px;
		    width: 120px;
		    bottom: 100px;
		}
	`
	]
})
export class OutDateModal{
	platformSetting:any;
	constructor(
		public utils: Utils
	){}
	clickUpdate(){
		this.utils.openInSystem(this.platformSetting.AppLink);
	}

} 
