import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ShoppingCartIcon} from './component/shopping-cart-icon/shopping-cart-icon';
import { ShoppingCartServicesModule } from './shopping-cart-services.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    IonicModule,
    ShoppingCartServicesModule
  ],
  entryComponents: [ShoppingCartIcon],
  declarations: [ShoppingCartIcon],
  exports: [ShoppingCartIcon]
})
export class ShoppingCartIconModule {}
