import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of, from } from 'rxjs';
import { map, take, switchMap} from 'rxjs/operators';
import { AuthService } from './auth.service';
import { CacheService } from "ionic-cache";
/**
	Show login for first use app
**/
@Injectable({
	providedIn: 'root'
})
export class ShowLoginGuardService implements CanActivate{
	constructor(public auth: AuthService, public router: Router, public cache: CacheService){

	}
	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree{
		const s = this.auth.getCurrentUser().pipe(
			take(1),
			switchMap(user=>{
				if(user){
					return of(true);
				}
				const o = this.auth.isUnderGuestFlow().pipe(
					map((allowGuest:any)=>{
						console.log('Enable guest flow', allowGuest);
						if(allowGuest){
							return true;
						}
						return this.router.parseUrl('/login');
					})
				);
				
				return o;		
			})
		)
		return s;
	}
}