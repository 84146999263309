import { Injectable, NgModule } from '@angular/core';
import { CacheService } from 'ionic-cache';


import { ENV } from '@app/env';
import { RelationshopHttpClient } from '@rsApp/modules/gateway/rs-api.service';
// import { Utils } from '../utils/utils';
import _ from 'lodash';
import { tap, switchMap } from 'rxjs/operators';
@NgModule({})
export class UserServiceModule {

}


/**
 * Most apps have the concept of a User. This is a simple provider
 * with stubs for login/signup/etc.
 *
 * This User provider makes calls to our API at the `login` and `signup` endpoints.
 *
 * By default, it expects `login` and `signup` to return a JSON object of the shape:
 *
 * ```json
 * {
 *   status: 'success',
 *   user: {u
 *     // User fields your app needs, like "id", "name", "email", etc.
 *   }
 * }Ø
 * ```
 *
 * If the `status` field is not `success`, then an error is detected and returned.
 */

@Injectable({
  providedIn: UserServiceModule
})
export class User {
  _user: any;


  constructor(
    private api: RelationshopHttpClient,
    private cache: CacheService/*, public utils: Utils*/) {
  }


  /**
   * Send a POST request to our signup endpoint with the data
   * the user entered on the form.
   */
  signup(accountInfo: any) {
    console.log(accountInfo);
    // let birthDay: string = accountInfo.BirthDay;
    // if (birthDay) {
    //   const parts = birthDay.split('/'); //['MM', 'DD', 'YYYY']
    //   birthDay = [parts[2], parts[0], parts[1]].join('/');
    // }
    let birthDay: string= '';
    if(accountInfo.bDay && accountInfo.bMonth && accountInfo.bYear){
      birthDay = [accountInfo.bYear, accountInfo.bMonth, accountInfo.bDay].join('/');
    }
    const newAccount = {
      isReceiveCard: true,
      Email: accountInfo.Email,
      UserEmail: accountInfo.UserEmail,
      Password: accountInfo.Password,
      FirstName: accountInfo.FirstName,
      LastName: accountInfo.LastName,
      MiddleName: accountInfo.MiddleName,
      Address: accountInfo.Address,
      Address2: accountInfo.Address2,
      City: accountInfo.City,
      State: accountInfo.State,
      ZipCode: accountInfo.ZipCode,
      Phone: accountInfo.Phone && accountInfo.Phone.replace(/\D/g, ""),
      CellPhone: accountInfo.CellPhone && accountInfo.CellPhone.replace(/\D/g, ""),
      StoreID: accountInfo.StoreID,
      LoyaltyAutoEnroll: accountInfo.LoyaltyAutoEnroll,
      ReceiveEmail: accountInfo.ReceiveEmail,
      ReceiveTextMessage: accountInfo.ReceiveTextMessage,
      SRCardID: accountInfo.SRCardID,
      ExternalCustomerCardID: accountInfo.ExternalCustomerCardID,
      AccountType: accountInfo.AccountType,
      BirthDay: birthDay,
      BannerID: ENV.DefaultBanerId,
      DriverLicenseNumber: accountInfo.DriverLicenseNumber,
      ShopPath: "InStore"
    };


    const seq = this.api.post('/users', newAccount);
    return seq;
  }

  updateUserProfile(username, profile, value) {
    let body = '"' + value + '"';
    return this.api.put(`/profile/${profile}?`, body, { params: { username: username } });
  }

  validateUser(email, phone, firstName, lastName, username) {
    let user = {
      Email: email, //user Email of userRO
      Phone: phone,
      FirstName: firstName,
      LastName: lastName,
      UserName: username //email of userRO
    }
    let seq = this.api.post('/users/validate', user);

    return seq;
  }

  getUserById(userId) {
    return this.api.get('/users/' + userId);
  }

  forgotUserName(email: string) {
    return this.api.post('/ForgotUserName', {}, { params: { email: email, bannerid: ENV.DefaultBanerId + '' } });
  }

  forgotPassWord(email: string) {
    return this.api.post('/ForgotPassword/email', {}, { params: { email: email, bannerid: ENV.DefaultBanerId + '' } });
  }

  updateUserById(user) {
    let birthDay: string = user.BirthDay;
    if (birthDay && birthDay.indexOf('T') < 0 ) {
      let parts = birthDay.split('/'); //['MM', 'DD', 'YYYY']
      birthDay = [parts[2], parts[0], parts[1]].join('/');// YYY/MM/DD
    }
    var userConvert = {
      Address: user.Address,
      Address2: user.Address2,
      ApplicationKey: user.ApplicationKey,
      BannerHost: user.BannerHost,
      BannerID: user.BannerID,
      BannerName: user.BannerName,
      BirthDay: birthDay,
      CellPhone: user.CellPhone && user.CellPhone.replace(/\D/g, ""),
      ChangedUserName: user.ChangedUserName,
      City: user.City,
      CreatedDate: user.CreatedDate,
      Email: user.Email,
      ExternalCustomerCardID: user.ExternalCustomerCardID,
      ExternalCustomerID: user.ExternalCustomerID,
      FirstName: user.FirstName,
      IsAuthorized: user.IsAuthorized,
      IsLockedOut: user.IsLockedOut,
      LastLoginDate: user.LastLoginDate,
      LastName: user.LastName,
      LastPasswordChangeDate: user.LastPasswordChangeDate,
      LastUpdatedByUser: user.LastUpdatedByUser,
      LastUpdatedDate: user.LastUpdatedDate,
      ListRoles: user.ListRoles,
      LoyaltyAutoEnroll: user.LoyaltyAutoEnroll,
      MiddleName: user.MiddleName,
      Password: user.Password,
      Phone: user.Phone && user.Phone.replace(/\D/g, ""),
      ReceiveEmail: user.ReceiveEmail,
      ReceivePostalMail: user.ReceivePostalMail,
      ReceiveTextMessage: user.ReceiveTextMessage,
      SRCardID: user.SRCardID,
      SecondaryCellPhone: user.SecondaryCellPhone && user.SecondaryCellPhone.replace(/\D/g, ""),
      State: user.State,
      StoreID: user.StoreID,
      StoreManagerID: user.StoreManagerID,
      StoreName: user.StoreName,
      StoreZipCode: user.StoreZipCode,
      Token: user.Token,
      UserEmail: user.UserEmail,
      UserID: user.UserID,
      ZipCode: user.ZipCode,
      ShopPath: user.ShopPath
    }
    return this.api.put(`/users/${userConvert.UserID}`, userConvert)
      .pipe(tap(() => {
        this.setUserCache(user.UserID).subscribe();
      }));
  }
  updateUserFields(profile:any){
    return this.getUserById(profile.UserID).pipe(
      switchMap((user:any)=>{
        Object.assign(user, profile);
        return this.updateUserById(user);
      })
    );
  }
  setUserCache(userId) {
    const url = `/cache/SetUserCache?key=cs.user:QRY:session_current_user:${userId}`
    return this.api.post(url, null);
  }
  updatePassword(email, oldPassword, newPassword) {
    let passwordBody = {
      Email: email,
      OldPassword: oldPassword,
      NewPassword: newPassword
    };
    return this.api.put('/users/password', passwordBody);
  }

  searchUsersByField(field, val) {
    let params = {
      field: field,
      value: val
    };
    return this.api.get('/users', { params: { field: params.field, value: params.value } });
  }

  checkUserExists(email, currentUsername?) {
    return this.api.get(`/users/email/${email}/v5`, { params: { un: currentUsername || '' } });
  }
  checkExistUsername(username) {
    return this.api.get(`/users/${username}/exist`, null);
  }

  checkExistEmail(email) {
    return this.api.get(`/users/email/${email}`, null);
  }

  updateUserName(username, newUsername, pwd) {
    let body = '"' + pwd + '"';
    return this.api.post('/users/change-username/v5', body, { params: { username: username, newUsername: newUsername } });
  }

  getAddresses(userId) {
    return this.api.get('/users/contacts/user', { params: { userId: userId } });
  }

  getAddressesById(contactId) {
    return this.api.get('/users/contact', { params: { contactId: contactId } });
  }

  removeAddressById(contactId) {
    return this.api.get('/users/contact/delete', { params: { contactId: contactId } });
  }

  updateAddress(address) {
    let body = {
      CS_ContactID: address.CS_ContactID,
      Address: address.Address,
      City: address.City,
      Country: null,
      Email: null,
      ExternalId: null,
      Fax: null,
      FirstName: address.FirstName,
      LastName: address.LastName,
      MobilePhone: address.MobilePhone && address.MobilePhone.replace(/\D/g, ""),
      Note: null,
      OrderNumber: null,
      Phone: null,
      SecondaryPhone: (address.SecondaryPhone && address.SecondaryPhone.replace(/\D/g, "")) || null,
      State: address.State,
      Title: null,
      UserID: address.UserID,
      ZipCode: address.ZipCode,
      IsBilling: address.IsBilling,
      IsDelivery: address.IsDelivery,
      ExternalId2: address.ExternalId2
    }
    return this.api.post('/users/contact/update', body);
  }

  addAddress(address) {
    let body = {
      CS_ContactID: null,
      Address: address.Address,
      City: address.City,
      Country: null,
      Email: null,
      ExternalId: null,
      Fax: null,
      FirstName: address.FirstName,
      LastName: address.LastName,
      MobilePhone: address.MobilePhone && address.MobilePhone.replace(/\D/g, ""),
      Note: null,
      OrderNumber: null,
      Phone: null,
      SecondaryPhone: null,
      State: address.State,
      Title: null,
      UserID: address.userId,
      ZipCode: address.ZipCode,
      IsBilling: address.IsBilling,
      IsDelivery: address.IsDelivery
    }
    return this.api.post('/users/contact/add', body);
  }
  public setStore(user, store, shopPath?) {
    let data: any = {
      UserEmail: user.UserEmail,
      Email: user.Email,
      UserID: user.UserID,
      StoreID: store.CS_StoreID,
      StoreZipCode: store.Zipcode,
      StoreName: store.StoreName
    };
    if (shopPath) {
      data.ShopPath = shopPath;
    }
    // console.log(store);
    return this.api.put(`/users/${data.UserID}/UpdateShoppingMode`, data);
  }
  // ToDo: should move to shoppinglist provider
  // getActiveShoppingList(user, store, refresher?) {
  //   let url = ENV.EComHost + '/shopping-list/active';
  //   // return this.apiWrapper.getCache(url, { userId: user.ExternalCustomerCardID, storeId: store.ExternalID }, { refresher: refresher || false });
  // }

  // region Earth Fare functions
  public sendOtpEmail(email: string) {
    return this.api.get(`/user/sendotpemail?email=${email}&bannerId=${ENV.DefaultBanerId}`);
  }
  public validateOtpEmail(email, code) {
    return this.api.get(`/user/validateotp?email=${email}&otp=${code}`);
  }
  public getLoyaltyMembers(email) {
    return this.api.get(`/user/GetLoyaltyRegMember?email=${email}`)
  }
  public getCardInfo(card) {
    return this.api.get(`/cards/${card}/demographic`);
  }
  /*
  // check card in loyaly
  public checkCard(card: any) {
    // return this.api.get(`/user/VerifyLoyaltyRegMember?cardId=${card.cardId}&lastName=trang`)
    let url = `/user/VerifyLoyaltyRegMember?cardId=${card.cardId}`;
    if (card.lastName) {
      url += `&lastName=${card.lastName}`
    }
    return this.api.get(url);
  }*/
  // check card is linked account
  public isUsedCard(card: string) {
    return this.api.get(`/users/card/${card}?un=`)
  }
  /*
  getUserByCard(card: string) {
    return this.api.get(`/user-card/${card}`);
  }
  */
  checkDemographicInfo(cardId, lastName){
    return this.api.get(`/cards/checkcardinfo?cardId=${cardId}&lastName=${lastName}`);
  }

  checkDemographicInfoByPhone(phoneNumber, lastName){
    return this.api.get(`/cards/checkcardinfo/byphone?phoneNumber=${phoneNumber}&lastName=${lastName}`);
  }

  checkCardIsUsed(customerID){
    return this.api.get(`/users?field=loyaltycardid&value=${customerID}`);
  }
  getListSegment(loyaltyCardId){
    return this.api.get(`/segment/get?loyaltyCardId=${loyaltyCardId}`);
  }

  updateSegment(username, segment, isHave = true){
    return this.api.post(`/segment/update?username=${username}&segment=${segment}&isHave=${isHave}`, null);
  }
  getContactByDriverLicense(text){
    return this.api.post(`/contact/find-by-driver-license`, `"${text}"`);
  }
  updateCommunication(username, general: any){
    return this.api.put(`/users/communication/${username}`, general);
  };
  sendSupportEmail(obj: any) {
    Object.assign(obj, { BannerID: ENV.DefaultBanerId });
    return this.api.post('/feedbacks', obj)
  }
  public linkCard(user, card) {
    return this.api.post(`/users/${user.Email}/relink`, card);
  }

  getBirthTreat(userId) {
    return this.api.get(`/loyalty/club/${userId}?type=BirthTreat`);
  }

  updateBirthTreat(username, children) {
    const params = {
      username,
      Options: {
        IsReceivedLetter: false,
        FamilyMembers: JSON.stringify(children)
      }
    };
    return this.api.post(`/loyalty/enrollclub?type=BirthTreat`, params);
  }

  getInterestClub(username) {
    return this.api.get(`/profile/InterestClub?username=${username}`);
  }

  updateInterestClub(username, pets) {
    let params = JSON.stringify(pets);
    params = params.replace(/\"/g, '\\"');
    console.log('updateInterestClub params', params);
    return this.api.put(`/profile/InterestClub?username=${username}`, '"' + params + '"');
  }

  changePassword(username, newPassword) {
    return this.api.post(`/users/set-password?username=${username}`, '"' + newPassword + '"');
  }
  // end region Earth Fare function
}
