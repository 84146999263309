import { Component } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { Router} from '@angular/router';
import { LearnMore } from '../learn-more/learn-more';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';

@Component({
	selector: 'guest-msg',
	templateUrl: 'guest-msg.html',
	styleUrls: ['./guest-msg.scss']
})
export class GuestMsg {
	backUrl:string
	constructor(
		public router: Router,
		// public state: ActivatedRouteSnapshot,
		public modalCtrl: ModalController,
		public navCtrl: NavController,
		public events: NgxPubSubService

	) { 
		this.backUrl = this.router.url;
	}
	
	public goLogin() {
		this.modalCtrl.dismiss(true);
		this.events.publishEvent('close-modal');
		this.navCtrl.navigateRoot(['/login', { returnRoute: this.backUrl || ''}]);
	}
	public goSignup() {
		this.modalCtrl.dismiss(true);
		this.events.publishEvent('close-modal');
		this.navCtrl.navigateRoot(['/signup', { returnRoute: this.backUrl || ''}]);

	}
	public async showLearn(){
		const m = await this.modalCtrl.create({
			component: LearnMore
		});
		m.present();

	}
}
