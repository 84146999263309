import { PurchasesService } from './purchase.service';

import { Injectable, NgModule } from '@angular/core';
import { CurrentStore } from '@rsApp/modules/store/providers/current-store.service';


import { ENV } from '@app/env';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import _ from 'lodash';
import { IPurchaseService } from './ipurchase.service';

@Injectable()
export class PurchaseGatewayService implements IPurchaseService {
    public api: IPurchaseService;
    constructor(
        public purchasetApi: PurchasesService,
        public cStore: CurrentStore
    ) {
        // will listening event change store, change Path for switch api
        this.cStore.store$.asObservable().subscribe((store) => {
            /* if (store && store.Provider && store.Provider.Code === 'MI9V8') {
                 this.api = this.mi8v9PurchasetApi;
             } else {
                 this.api = this.onlinePurchasetApi;
             }
             */
            this.api = this.purchasetApi;
        });
    }

    getPurchase(cardId, type, startDate, endDate): Observable<any> {
        return this.api.getPurchase(cardId, type, startDate, endDate);
    }

    getPurchaseDetail(cardId, transId, referenceId): Observable<any> {
        return this.api.getPurchaseDetail(cardId, transId, referenceId);
    }
}