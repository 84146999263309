import { NgModule, Injectable } from '@angular/core';
import { RelationshopHttpClient } from '@rsApp/modules/gateway/rs-api.service';
import { Observable } from 'rxjs';

@NgModule({})
@Injectable()
export class PurchasesService {

	constructor(
		public api: RelationshopHttpClient,
	) {
	}

	getPurchase(cardId, type, startDate, endDate): Observable<any> {
		const url = `/cards/${cardId}/transactions?start=${startDate}&end=${endDate}&type=${type}`;
		return this.api.get(url);
	}

	getPurchaseDetail(cardId, transId, referenceId): Observable<any> {
		const url = `/cards/${cardId}/transaction/${transId}`;
		return this.api.post(url, '"' + referenceId + '"');
	}
}
