import { Injectable, NgModule } from '@angular/core';
import { CurrentStore } from '@rsApp/modules/store/providers/current-store.service';
import { Observable } from 'rxjs';
import _ from 'lodash';
import { IShoppingCartService } from './ishopping-cart.service';
import { ShoppingCartService } from './shopping-cart.service';

@Injectable()
export class ShoppingCartGatewayService implements IShoppingCartService {
    public service: IShoppingCartService;
    constructor(
        private shoppingCartService: ShoppingCartService,
        private cStore: CurrentStore
    ) {
        // We listening event change store, change Path for switch api service.
        this.cStore.store$.asObservable().subscribe((store) => {
            // store.provider = "mi9v8";
            // if (store && store.Provider && store.Provider.Code === 'MI9V8') {
            //     this.service = this.mi9v8ShoppingCartService;
            // } else {
                this.service = this.shoppingCartService;
            // }
        });
    }
    clearCache(): void {
        return this.service.clearCache();
    }
    getShoppingCartByUser(un: string, store: any, refresher?: boolean): Observable<any> {
        return this.service.getShoppingCartByUser(un, store, refresher);
    }
    getCartReview(user: any, store: any, refresher?: any): Observable<any> {
        return this.service.getCartReview(user, store, refresher);
    }
    addProduct(product: any, user: any, store: any, qty?: number): Observable<any> {
        return this.service.addProduct(product, user, store, qty);
    }
    addItems(cartItems: any): Observable<any> {
        return this.service.addItems(cartItems);
    }
    updateSize(cartItem: any): Observable<any> {
        return this.service.updateSize(cartItem);
    }
    updateItem(cartItem: any, returnFull?: boolean): Observable<any> {
        return this.service.updateItem(cartItem, returnFull);
    }
    updateAllowsSubstitutions(user: any, store: any, isAllows: boolean, listItemId?: any): Observable<any> {
        return this.service.updateAllowsSubstitutions(user, store, isAllows, listItemId);
    }
    deleteItem(cartItem: any, productV?: any): Observable<any> {
        return this.service.deleteItem(cartItem, productV);
    }
    deleteAllItem(user: any, store: any): Observable<any> {
        return this.service.deleteAllItem(user, store);
    }
    getRuleByStore(store: any, ruleName: string) {
        return this.service.getRuleByStore(store, ruleName);
    }
}