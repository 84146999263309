import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of, from } from 'rxjs';
import { map, take, switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { CurrentStore } from '@rsApp/modules/store/providers/current-store.service';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Utils } from '@rsApp/modules/utils/providers/utils';
import { CacheService } from 'ionic-cache';
@Injectable({
	providedIn: 'root'
})
export class StoreGuardService implements CanActivate{
		constructor(public auth: AuthService, public router: Router, public cStore: CurrentStore, private utils: Utils, public cache: CacheService){

	}
	canActivate(
		nex: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const s = this.cStore.store$.asObservable().pipe(
			take(1),
			switchMap(store=>{
				if(store && store.ShopPath){
					return of(true);
				}
				this.cache.saveItem('store-back', { route: [state.url], key: 'selected-store' }, 'store');
				if(store){
					return of(this.router.parseUrl('store/near'));
				}
				// dont have store , redirec to selector
				if(this.auth.underGuestFlow){
					return of(this.router.parseUrl('store/find;defaultHref=login'));
				}
				else{
					return of(this.router.parseUrl('store/find'));
				}		
			})
		)
		return s;
	}
}