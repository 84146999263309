import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuardService implements CanActivate{
	constructor(public auth: AuthService, public router: Router){

	}
	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree{
		const s = this.auth.getCurrentUser().pipe(
			take(1),
			map(user=>{
				if(user){
					return true;
				}
				// not yet login, redirect to login page
				const backUrl = (<any>next)._routerState.url;
				const loginUrlTree = this.router.createUrlTree(['/login', {returnRoute: backUrl}]);
				// return this.router.parseUrl('/login');
				return loginUrlTree;
			})
		)
		return s;
	}
}