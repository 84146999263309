import { Injectable } from '@angular/core';
import { CacheService } from 'ionic-cache';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';

import { of, ReplaySubject } from 'rxjs';

import { RelationshopHttpClient } from '@rsApp/modules/gateway/rs-api.service';
import { Credential } from '@rsApp/modules/auth/providers/credential.service';
import { RelationshopEComHttpClient } from '../../gateway/rs-ecom-api.service';
import { Platform } from '@ionic/angular';
import { RelationshopEComCoreHttpClient } from '@rsApp/modules/gateway/rs-ecom-core-api.service';
import { map, tap, catchError } from 'rxjs/operators';
import _ from 'lodash';
import { Store } from './store.service';
import { Utils } from '@rsApp/modules/utils/providers/utils';
import { ENV } from '@app/env';

@Injectable({
	providedIn: 'root'
})
export class CurrentStore {
	// public store$: ReplaySubject<any>;
	public cacheKey = 'cache-currentStore';
	public store: any;
	public store$: ReplaySubject<any>;
	storeDxpApiUrl: any;
	public service: any;

	constructor(
		private cache: CacheService,
		private api: RelationshopHttpClient,
		private cre: Credential,
		private ecapi: RelationshopEComHttpClient,
		private platform: Platform,
		private events: NgxPubSubService,
		private ecomCoreApi: RelationshopEComCoreHttpClient,
		private storeService: Store,
	) {
		this.storeDxpApiUrl = `/store${ENV.VersionStoreAPI}/api`;
		this.store$ = <ReplaySubject<any>>new ReplaySubject(1);
		this.events.subscribe('change-store', async (data) => {
			if (data) {
				this.setStore(data);
			}
			else {
				this.setStore(await this.loadStore());
			}
		});
	}
	async init() {
		await this.setStore(await this.loadStore());
	}

	public async getStore() {
		// return this.store$.asObservable();
		if (this.store === undefined) {
			this.store = await this.cache.getItem(this.cacheKey).catch(() => {
				return null;
			});
			/* move to load store
			if (this.store) {
				const service = _.find(this.store.SystemService, s => s.DisplayName === this.store.ShopPath);
				const storeEcomProvider = await this.storeService.getStoreProviver(this.store, service.CS_ServicesID).toPromise();
				this.store.Provider = await this.storeService.getProviver(storeEcomProvider).toPromise(); 
			}
			*/
		}
		// dont have cache store, get store from user;
		if (this.store === undefined) {
			/*
			const user = await this.cre.getCurrentUser();
			if (user && user.StoreID) {
				const store = await this.getStoreById(user.StoreID).toPromise();
				await this.setStore(store);
			}
			*/
			await this.setStore(await this.loadStore());
		}
		if (this.store) {
			this.store.SystemService = this.store.SystemService ? _.orderBy(this.store.SystemService, ['Priority']) : null;
		}
		return this.store;
	}
	public async setStore(store) {
		if (store) {
			store.IsEcomm = false;
			if (store.Pickup == true || store.Delivery == true) {
				store.IsEcomm = true;
			}
			// store.ShopPath = 'Online';
			// store.ShopPath = 'Instore';
		}
		// console.log(store);

		this.store = store;
		await this.cache.saveItem(this.cacheKey, store, undefined, 60 * 60 * 12 * 15);
		this.store$.next(store);
	}

	async loadStoreEcom(user, store) {
		store.StoreType = this.displayStoreType(store);
		store.ShopPath = user.ShopPath;
		const service = _.find(store.SystemService, s => ((s.ServiceSettings && s.ServiceSettings.ShopPath === user.ShopPath)) || (s.CS_ServicesID == user.ShopPath));
		if (service) {
			try {
				store.Service = service;
				const storeEcomProvider = await this.storeService.getStoreProviver(store, service.CS_ServicesID).toPromise();
				store.Provider = await this.storeService.getProviver(storeEcomProvider).toPromise();
				if (store && store.Provider && store.Provider.Code === 'MI9V8') {
					store.ShowDeptFilter = true;
					const shoppingModeConfig: any = await this.getDetailConfigByCurrentStore(store, 'ShoppingMode').toPromise();
					if (shoppingModeConfig) {
						store.ShoppingModeId = shoppingModeConfig.Value;
					}

				} else {
					store.ShowDeptFilter = false;
				}
				
			} catch (error) {

			}
			if (user) {
				await this.storeService.setStore(store, user, service).toPromise().catch(error => {
				});
			}
		}

		return store
	}

	getStoreById(id) {
		const seq = this.ecomCoreApi.get(`${this.storeDxpApiUrl}/Store/by-id/${id}`).pipe(
			map((dxpStore) => {
				return this.storeService.convertDXPStoreToStore(dxpStore);
			})
		);
		return seq;
	}
	async loadStore() {

		const user = await this.cre.getCurrentUser();
		let store: any = null;
		if (!user) {
			// load form cache
			store = await this.cache.getItem(this.cacheKey).catch(() => {
				return null;
			});

		}
		else if (user.StoreID) {
			store = await this.getStoreById(user.StoreID).toPromise();
			if (!store) {
				return null;
			}
			store = await this.loadStoreEcom(user, store);
		}

		return store;
	}
	// async loadEcomProvider(store) {
	// 	const service = _.find(store.SystemService, s => ((s.ServiceSettings && s.ServiceSettings.ShopPath === store.ShopPath)) || (s.CS_ServicesID == store.ShopPath));
	// 	if (service) {
	// 		const storeEcomProvider = await this.storeService.getStoreProviver(store, service.CS_ServicesID).toPromise();
	// 		let p = await this.storeService.getProviver(storeEcomProvider).toPromise();
	// 		return p;
	// 	}
	// 	return null;
	// }

	isEcom() {
		return this.store && (this.store.ShopPath !== 'InStore' && this.store.ShopPath !== 'MakeAList');
	}

	isShowDeptFilter() {
		// return this.store && (this.store.ShowDeptFilter);
		return true;
	}

	getListConfigByCurrentStore(store) {
		const seq = this.ecomCoreApi.get(`${this.storeDxpApiUrl}/Config/${store.CS_StoreID}/${store.Provider.Id}/${store.Service.CS_ServicesID}`).pipe(
			catchError(() => {
				return of(null);
			})
		);
		return seq;
	}

	getDetailConfigByCurrentStore(store, key) {
		const seq = this.ecomCoreApi.get(
			`${this.storeDxpApiUrl}/Config/${store.CS_StoreID}/${store.Provider.Id}/${store.Service.CS_ServicesID}/${key}`).pipe(
				catchError(() => {
					return of(null);
				})
			);
		return seq;
	}
	/*
	setCurrentStore(data, currentUser?) {
		this.cache.saveItem('cache-currentStore', data, undefined, 60 * 60 * 12 * 15);
		if (currentUser) {
			this.setStoretoShoppingV3(currentUser, data);
		}
	}

	setStoretoShoppingV3(user, store) {
		return this.ecapi.put(`/user/store/v3?`, null, { params: { userId: user.ExternalCustomerCardID, storeId: store.ExternalID } });
	}
	*/
	displayStoreType(store) {
		if (!store || (!store.Pickup && !store.Delivery)) return "";
		const pickupText = store.Pickup ? "Pickup " : "";
		const deliveryText = store.Delivery ? "Delivery " : "";
		const andText = store.Pickup && store.Delivery ? "& " : "";
		const type = `${deliveryText}${andText}${pickupText}Available`;
		return type;
	}
	getDefaultOnlineService() {
		let s = this.store,
			systemServices = s.SystemService,
			provider = s.Provider,
			service = null;
		if (provider.Code === 'MI9V8') {
			service = _.find(systemServices, { ServiceName: "Pickup" });
		}
		else {
			service = _.find(systemServices, { ServiceName: "eCom" })
		}
		return service;
	}
}