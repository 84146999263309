import { Component, ViewChild, OnInit, OnDestroy} from '@angular/core';
import {IonRouterOutlet, IonTabs, IonTabBar} from '@ionic/angular'
import {RouterDirection} from '@ionic/core';
import { Network } from '@ionic-native/network/ngx';
import {AuthService} from '@rsApp/modules/auth/providers/auth.service';
import { RouteView } from '@ionic/angular/directives/navigation/stack-utils';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, tap, map, delay, debounceTime} from 'rxjs/operators';
import { ModalController } from '@ionic/angular';
import { GuestMsg } from '@rsApp/modules/utils/components/guest-msg/guest-msg';

@Component({
  selector: 'app-tabs',
  templateUrl: 'tabs.page.html',
  styleUrls: ['tabs.page.scss']
})
export class TabsPage  implements OnInit, OnDestroy{
	@ViewChild('tabs',{static: true}) tabs: IonTabs;
  public subsriptions = [];
  public isOffline = false;
  selectedTab ='';
  constructor(
    private outlet: IonRouterOutlet, 
    private network: Network, 
    public auth: AuthService, 
    private router: Router, 
    private route: ActivatedRoute,
    public modalCtrl: ModalController
    ) {
  	// console.log(outlet);
     let navigationO = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map((event:NavigationEnd)=>{
        const lRoute =getLeaftActiveRoute(this.router.routerState.snapshot.root);
        return lRoute.routeConfig;
      }),
      // delay for init tab;
      // delay(200),
      tap((routeConfig:any)=>{
        if(routeConfig.data && routeConfig.data.hideTab){
          this.hideTabs();
        }
        else{
          this.showTabs();
        }
      })
    );
    navigationO.subscribe();
  }
  ngOnInit(){
  	// cheat
    setTimeout(()=>{
      let outlet:any = this.tabs.outlet,
      stackCtrl = outlet.stackCtrl;
      overrideStackCtrl.bind(stackCtrl)();
    }, 1000);
    // end cheat
    this.listenNetwork();
  }
  ngOnDestroy(){
    for(let i = 0; i< this.subsriptions.length;i++){
      this.subsriptions[i].unsubscribe();
    }
  }
  
  listenNetwork(){
    let disconnectSubscription = this.network.onDisconnect().subscribe(() => {
      // console.log('network was disconnected :-(');
      this.isOffline = true;
    });
    let connectSubscription = this.network.onConnect().pipe(debounceTime(1000)).subscribe(() => {
      console.log('network connected!');
      // We just got a connection but we need to wait briefly
       // before we determine the connection type. Might need to wait.
      // prior to doing any api requests as well.
      setTimeout(() => {
        if (this.network.type === 'wifi') {
          // console.log('we got a wifi connection, woohoo!');
        }
        // this.auth.init();
        setTimeout(()=>{
          this.isOffline = false;
        },1000);
      }, 3000);
    });
    this.subsriptions.push(disconnectSubscription);
    this.subsriptions.push(connectSubscription);
  }

  public hideTabs(){
    const tabBar = document.getElementById('dxptabbar');
    if(tabBar && tabBar.style){
      tabBar.style.display = 'none';
    }
  }
  public showTabs(){
    // console.log('show Tab');
    const tabBar = document.getElementById('dxptabbar');
    if(tabBar && tabBar.style){
      tabBar.style.display = 'flex';
    }
  }
  async showGuestMsg(bUrl){
    const lRoute = getLeaftActiveRoute(this.router.routerState.snapshot.root);
    console.log(lRoute);
    const m = await this.modalCtrl.create({
      component: GuestMsg,
      componentProps:{
        backUrl: bUrl
      }
    });
    await m.present();
  }
  loadSelectedTab(){
    this.selectedTab = this.tabs.getSelected();
  }

}
function getLeaftActiveRoute(route){
  if(route.firstChild){
    return getLeaftActiveRoute(route.firstChild);
  }
  return route;
}

function overrideStackCtrl(){

	// cheat for each tab go to shopping list and back to tab;
	this.origin_getStack = this.getStack;
	this.getStack = function(stackId: string|undefined, f = false){
		if(!f && (stackId == 'shopping-lists' || stackId=="products" || stackId=="rewards" || stackId=="purchase" || stackId=="recipe" || stackId =="shopping-cart")){
			return this.views;
		}
		return this.origin_getStack(stackId);
	}
  // end cheat
  
  this.origin_getLastUrl = this.getLastUrl;
  this.getLastUrl = function(stackId?: string){
    const views = this.getStack(stackId,true);
    return views.length > 0 ? views[views.length - 1] : undefined;
  }

  // cheat for every click tab button, will return first page of Tab
  this.original_insertView = this.insertView;  
  this.insertView = function(enteringView: RouteView, direction: RouterDirection){
    let views = this.original_insertView(enteringView, direction);
    if(views.indexOf(enteringView) == (views.length -1)){
      return views;
    }
    this.views = views.filter(v =>  v.id <= enteringView.id);
    return this.views.slice();
    
  }
  
  // end cheat
}
