import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Router} from '@angular/router';

@Component({
	selector: 'learn-more',
	templateUrl: 'learn-more.html',
	styleUrls: ['./learn-more.scss']
})
export class LearnMore {
	backUrl:string;
	constructor(
		public router: Router,
		// public state: ActivatedRouteSnapshot,
		public modalCtrl: ModalController,
	) { 
		this.backUrl = this.router.url;
	}
}
