import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { ENV } from './environments/environment';

import "hammerjs"; // HAMMER TIME

if (ENV.production) {
  // Remove console log, only PROD - start
  if (window.console) {
    window.console.log = () => { }
  }
  // Remove console log, only PROD - end
  enableProdMode();
}


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err))

