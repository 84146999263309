import { Component, Input, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute, } from '@angular/router';
import { CacheService } from 'ionic-cache';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';

import { Utils } from '@rsApp/modules/utils/providers/utils';
import { AuthService } from '@rsApp/modules/auth/providers/auth.service';
import { CurrentStore } from '@rsApp/modules/store/providers/current-store.service';
import { Store } from '@rsApp/modules/store/providers/store.service';
import { Credential } from '@rsApp/modules/auth/providers/credential.service';
import { PermanentSettings } from '@rsApp/modules/utils/providers/permanent-settings';
import _ from 'lodash';
import { take } from 'rxjs/operators';
import { ShoppingCartGatewayService } from '../shopping-cart/providers/shopping-cart-gateway.service';

export class BaseStore {
  public currentStore: any;
  public currentUser: any;

  constructor(
    public utils: Utils,
    public cStore: CurrentStore,
    public router: Router,
    public cache: CacheService,
    public store: Store,
    public cri: Credential,
    public events: NgxPubSubService,
    public pSettings: PermanentSettings,
    public shoppingCartService?: ShoppingCartGatewayService,
  ) {
    this.getCurrent();
  }

  async getCurrent() {
    this.currentStore = await this.cStore.getStore();
    // console.log('this.currentStore', this.currentStore);
    this.currentUser = await this.cri.getCurrentUser();
  }

  viewInfo(storeId) {
    this.router.navigate(['/store/info', { storeId }]);
  }

  async selectStore(store, service?) {
    try {
      // if (!this.currentUser || !store || !shopPath) return null;
      if (!store) { return null; }

      await this.utils.showLoading();
      if(!service){
        service = _.find(store.SystemService, s => s.ServiceSettings.ShopPath === "InStore");
      }

      const isShopPath = service && service.ServiceSettings.IsShopPath;
      if (isShopPath) {
        store.Service = service;
        store.ShopPath = service.ServiceSettings.ShopPath;
        await this.setStore(store, store.Service)
 

        const back = await this.cache.getItem('store-back').catch(() => { return null });
        const backRoute = back && back.route && back.route.length > 0 ? back.route : ['/tabs/home'];
        setTimeout(() => {
          //Wait 'change-store' save store info
          if (backRoute && backRoute[0].search("/purchase/order-sumary") > -1) {
            if (this.currentStore.Provider.Code != store.Provider.Code) {
              this.utils.navigateRoot(["/tabs/purchase"], true);
              return;
            }
          }
          this.utils.navigateRoot(backRoute, true);
        }, 500);

      } else {
        await this.utils.showLoading();
        if ((this.currentStore && this.currentStore.CS_StoreID != store.CS_StoreID) || (!this.currentStore)) {
          store.Service = _.find(store.SystemService, s => s.ServiceSettings.ShopPath === "InStore");
          store.ShopPath = store.Service.ServiceSettings.ShopPath;
          await this.setStore(store, store.Service)
        }
        const pageName = _.replace(service.ServiceSettings.MobileNavUrl, 'app://', '');
        this.router.navigateByUrl(pageName);
      }
    }
    catch (error) {
      console.log(error);
      this.utils.hideLoading();
    }
  }

  async setStore(store, service) {
    if (service) {
      try {
        const shoppingModeConfig: any = await this.cStore.getDetailConfigByCurrentStore(store, 'ShoppingMode').toPromise();
        store.ShoppingModeId = shoppingModeConfig.Value;
      } catch (error) {

      }
    }
 
    if (this.currentUser) {
      await this.store.setStore(store, this.currentUser, service).toPromise().catch(error => {
        const msg = error.error && error.error.Message || error.statusText;
        if (msg) {
          this.utils.alertError(msg);
        }
      });

    }
    //  refresh user
    // this.auth.refreshUser();

    // }
    // set current Store
    await this.pSettings.saveItem(PermanentSettings.SHOWED_SELECT_STORE, true);
    await this.events.publishEvent('change-store', Object.assign({}, store));
    this.events.publishEvent('emmit-change-order');
  }

}
