import { Component, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Router } from "@angular/router";
import { IOrderedResponse } from "@rsApp/modules/interfaces/order.interface";
import { ENV } from '@app/env';

@Component({
  selector: "iframe-modal",
  templateUrl: "./iframe-modal.html",
  styleUrls: ["./iframe-modal.scss"],
})
export class IframeModal {
  @Input() url: string;
  @Input() title: string;
  public deviceMode = '';
  constructor(public modalCtrl: ModalController, public router: Router) {
    this.deviceMode = ENV.DeviceMode;
    if (this.deviceMode == 'Web') {
      window.parent.postMessage('scroll-top', '*');
    }
  }
}
