import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ShoppingListIcon} from './component/shopping-list-icon/shopping-list-icon';
import { ShoppingListServicesModule } from './shopping-list-services.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    IonicModule,
    ShoppingListServicesModule
  ],
  entryComponents: [ShoppingListIcon],
  declarations: [ShoppingListIcon],
  exports: [ShoppingListIcon]
})
export class ShoppingListIconModule {}
