import { NgModule } from '@angular/core';
import { ShoppingCartGatewayService } from './providers/shopping-cart-gateway.service';
import { ShoppingCartService } from './providers/shopping-cart.service';
@NgModule({
  providers: [
    ShoppingCartGatewayService,
    ShoppingCartService
  ]
})
export class ShoppingCartServicesModule {
}