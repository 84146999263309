import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription} from 'rxjs';
import { Router } from '@angular/router';
import { CurrentShoppingCart} from '../../providers/current-shopping-cart.service';
import { AuthService } from '@rsApp/modules/auth/providers/auth.service';
import { ModalController } from '@ionic/angular';
import { GuestMsg } from '@rsApp/modules/utils/components/guest-msg/guest-msg';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';

@Component({
	selector: 'shopping-cart-icon',
	templateUrl: 'shopping-cart-icon.html',
	styleUrls: ['./shopping-cart-icon.scss']
})
export class ShoppingCartIcon implements OnInit, OnDestroy {
	countS:Observable<any>;
	// list: Observable<any>;

	subscriptions:Subscription[] = [];
	constructor(
		private shoppingCart: CurrentShoppingCart, 
		private router: Router, 
		private auth: AuthService, 
		public modalCtrl: ModalController,	
		private events: NgxPubSubService) {
	}
	public ngOnInit() {
		// this.list = this.shoppingCart.getActiveList();
		this.countS = this.shoppingCart.getCount();
		// this.subscriptions.push(countS);
	}
	public ngOnDestroy(){
		
	}
	public go(){
		if(!this.auth.cre.currentUser){
			this.showGuestMsg();
	    	return;
		}
		
		this.events.publishEvent('close-modal');
		this.router.navigate(['/tabs/shopping-cart']);
	}
	async showGuestMsg(){
	    const m = await this.modalCtrl.create({
		  component: GuestMsg,
	    });
	    await m.present();
	  }
}