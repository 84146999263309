import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, } from '@angular/router';
import { NavController, ModalController, Platform } from '@ionic/angular';
import { CacheService } from "ionic-cache";
import { of, Observable, from } from 'rxjs';
import { switchMap, tap, map, catchError, filter } from 'rxjs/operators';
import _ from 'lodash';

import { ENV } from '@app/env';
import { User } from '@rsApp/modules/account/provider/user.service';
import { Store } from '../../providers/store.service';
import { CurrentStore } from '../../providers/current-store.service';
import { Utils, cacheErrorHandle } from '@rsApp/modules/utils/providers/utils';
import { AuthService } from '@rsApp/modules/auth/providers/auth.service';
import { Credential } from '@rsApp/modules/auth/providers/credential.service';
import { BaseStore } from './../../base-store';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';
import { PermanentSettings } from '@rsApp/modules/utils/providers/permanent-settings';
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';
import { ShoppingCartGatewayService } from '@rsApp/modules/shopping-cart/providers/shopping-cart-gateway.service';

@Component({
  selector: 'page-result-find-store',
  templateUrl: 'result-find-store.html',
  styleUrls: ['./result-find-store.scss', '../../styles/store-list.scss']
})
export class ResultFindStorePage extends BaseStore {
  public pageTitle = 'Select a Store';
  public view = 'list'; // map|list
  public currentUser: any = null;
  public currentStore: any = null;
  public resultCount = 0;
  public filter = {
    keyword: '',
    lat: null,
    lng: null,
    isNearBy: false,
    isPickup: false,
    isDelivery: false,
    states: {},
    cities: {},
    banners: {},
    featureLists: {},
    systemList: {},
    areas: {}
  };
  type = 'findStore'; // findStore|chooseStore
  stores: Array<any>;
  allStore: Array<any>;

  map: any; // google map object
  public backInfo: any; // {route: ['/singup/account'], key: 'selected-store'};
  public stopEvent = false;

  isAll: boolean = true;
  isPickup: boolean;
  isDelivery: boolean;
  isPharmacy: boolean;
  isStarbucks: boolean;
  currentCatalog: string = 'all';

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public navCtrl: NavController,
    public user: User,
    public store: Store,
    public cStore: CurrentStore,
    public utils: Utils,
    public modalCtrl: ModalController,
    public auth: AuthService,
    public cache: CacheService,
    public location: Location,
    public cri: Credential,
    public events: NgxPubSubService,
    public pSettings: PermanentSettings,
    public openNativeSettings: OpenNativeSettings,
    public platform: Platform,
    public shoppingCartService: ShoppingCartGatewayService,
  ) {
    super(utils, cStore, router, cache, store, cri, events, pSettings, shoppingCartService);
    const params = this.route.snapshot.paramMap;
    Object.assign(this.filter, {
      keyword: params.get('keyword') || '',
      lat: params.get('lat') || null,
      lng: params.get('lng') || null,
      isNearBy: !!params.get('isNearBy') || false,
    });
    this.view = params.get('view') || 'list';
  }

  async ionViewWillEnter() {
    super.getCurrent();
    const params = await this.cache.getItem('store-search-params').catch(cacheErrorHandle);
    if (params) {
      Object.assign(this.filter, params);
    }
    this.pageTitle = this.genTitle();

    const back = await this.cache.getItem('store-back').catch(cacheErrorHandle);
    if (back) {
      this.backInfo = back;
    }
    this.search();
  }

  async search(event = null) {
    if (event != null && event.keyCode !== 13) { return; }
    let s: Observable<any> = of(null);
    if (this.filter.lat || this.filter.lng || this.filter.isNearBy) {
      if (this.filter.isNearBy) {
        s = from(this.utils.getCurrentPosition(5000, true)).pipe(
          tap((res) => {
            if (!res.isLocationOn) {
              throw new Error('Please enable location services to see stores near you.');
            }
            Object.assign(this.filter, {
              lat: res.lat,
              lng: res.lng
            });
          })
        );
      }
      s = s.pipe(
        switchMap((reps) => {
          const latlng = this.filter.lat + ',' + this.filter.lng;
          // const latlng = '42.44800681363518,-73.26032280921936';
          return this.store.getStoreNearBy(latlng);
        }),
        switchMap((rs: any) => {
          if ((!rs || rs.length === 0) && this.filter.keyword) {
            return this.store.searchStores(this.filter.keyword, 'l');
          }
          return of(rs);
        })
      );
    } else {
      s = this.store.searchStores(this.filter.keyword, 'l');
    }
    s = s.pipe(
      // filter store by states
      map((res) => {
        let stores = res;

        if (_.isEmpty(this.filter.states) && _.isEmpty(this.filter.cities) && _.isEmpty(this.filter.areas) && !this.currentStore
          && !this.filter.isNearBy && !this.filter.isDelivery && _.isEmpty(this.filter.banners)
          && _.isEmpty(this.filter.systemList) && _.isEmpty(this.filter.featureLists)) {
          return stores;
        }
        stores = _.filter(stores, (store) => {

          // cheat handle data
          // this.storeInfo.PhoneNumber
          store.SystemService = _.orderBy(store.SystemService, ['Priority']);
          // end cheat handle data
          let valid = true;
          const filterByCity = !_.isEmpty(this.filter.cities);
          if (filterByCity) {
            valid = valid && this.filter.cities[store.City]
          }
          const filterByState = !_.isEmpty(this.filter.states);
          if (filterByState) {
            valid = valid && this.filter.states[store.State]
          }
          if (!_.isEmpty(this.filter.areas)) {
            valid = valid && this.filter.areas[store.CS_CityAreaID];
          }
          const filterByBanner = !_.isEmpty(this.filter.banners);
          if (filterByBanner) {
            valid = valid && this.filter.banners[store.StoreName.replaceAll(' ', '')];
          }
          if (this.filter.isDelivery) {
            valid = valid && store.Delivery && store.ShopOnline;
          }
          if (this.filter.isPickup) {
            valid = valid && store.Pickup && store.ShopOnline;
          }
          const filterByFeatureLists = !_.isEmpty(this.filter.featureLists);
          if (filterByFeatureLists) {
            let isMatch = false;
            if (!!store.ServiceStores && store.ServiceStores.length > 0) {
              store.ServiceStores.forEach(service => {
                if (this.filter.featureLists[service.CS_ServicesID]) {
                  isMatch = true;
                  return false;
                }
              });
            }
            valid = valid && isMatch;
          }

          const filterBySystemList = !_.isEmpty(this.filter.systemList);
          // console.log('filterBySystemList', filterBySystemList);

          if (filterBySystemList) {
            let isMatch = false;
            if (!!store.SystemService && store.SystemService.length > 0) {
              store.SystemService.forEach(service => {
                if (this.filter.systemList[service.CS_ServicesID]) {
                  isMatch = true;
                  return false;
                }
              });
            }
            if (!!store.CustomServices && store.CustomServices.length > 0) {
              store.CustomServices.forEach(service => {
                if (this.filter.systemList[service.CS_ServicesID]) {
                  isMatch = true;
                  return false;
                }
              });
            }
            valid = valid && isMatch;
          }
          return valid;
        });

        return stores;
      }),
      // assgin to model
      tap((res) => {
        const stores = res;
        this.allStore = res;
        // search store with catalog
        this.stores = stores;
        // console.log('this.store 1111', this.stores);
        this.filterStore(this.currentCatalog);
        this.resultCount = this.stores.length;
        // console.log(this.resultCount);
        // remove selected Store from list
        if (this.currentStore) {
          this.stores = this.stores.filter(s => s.CS_StoreID !== this.currentStore.CS_StoreID);
        }

      }),
      catchError((error) => {
        var callback: Function;
        if (error && error.message == "Please enable location services to see stores near you.") {
          callback = this.goAppSettings;
        }
        this.utils.alertError(error.message, callback.bind(this));
        return of(null);
      })
    );

    // show Loading
    await this.utils.showLoading();
    s.subscribe(async (res) => {
      // hideLoading
      await this.utils.hideLoading();
      this.stopEvent = false;
    });
  }

  toggleMap() {
    this.view = this.view == 'map' ? 'list' : 'map';
  }

  reset() {
    this.filter = {
      keyword: this.filter.keyword,
      lat: null,
      lng: null,
      isNearBy: false,
      isPickup: false,
      isDelivery: false,
      states: {},
      cities: {},
      banners: {},
      featureLists: {},
      systemList: {},
      areas: {},
    };
  }
  nearBy() {
    const nearyby = !this.filter.isNearBy;
    this.stopEvent = true;
    this.reset();
    this.filter.isNearBy = nearyby;
    this.search();
  }

  genTitle() {
    let title = 'Select a Store';
    if (_.isEmpty(this.filter.states) && _.isEmpty(this.filter.cities) && _.isEmpty(this.filter.banners)) {
      return title;
    }
    title = '';
    if (!_.isEmpty(this.filter.banners)) {
      title += ':' + Object.keys(this.filter.banners).join(', ');
    }
    if (!_.isEmpty(this.filter.states)) {
      title += ':' + Object.keys(this.filter.states).join(', ');
    }
    if (!_.isEmpty(this.filter.cities)) {
      title += ':' + Object.keys(this.filter.cities).join(', ');
    }
    return title.substring(1);

  }

  async viewMore() {
    this.reset();
    await this.cache.saveItem('store-search-params', this.filter, 'store');
    this.router.navigate(['../find'], { relativeTo: this.route });
  }

  chooseCatalog(catalog) {
    this.currentCatalog = catalog;
    this.filterStore(catalog);
    switch (catalog) {
      case 'pickup':
        this.isAll = false;
        this.isPickup = true;
        this.isDelivery = false;
        this.isPharmacy = false;
        this.isStarbucks = false;
        break;
      case 'delivery':
        this.isAll = false;
        this.isPickup = false;
        this.isDelivery = true;
        this.isPharmacy = false;
        this.isStarbucks = false;
        break;
      case 'pharmacy':
        this.isAll = false;
        this.isPickup = false;
        this.isDelivery = false;
        this.isPharmacy = true;
        this.isStarbucks = false;
        break;
      case 'starbucks':
        this.isAll = false;
        this.isPickup = false;
        this.isDelivery = false;
        this.isPharmacy = false;
        this.isStarbucks = true;
        break;
      default:
        this.isAll = true;
        this.isPickup = false;
        this.isDelivery = false;
        this.isPharmacy = false;
        this.isStarbucks = false;
    }
  }

  filterStore(catalog) {
    switch (catalog) {
      case 'pickup':
        this.stores = this.allStore.filter(store => store.Pickup === true && store.ShopOnline);
        break;
      case 'delivery':
        this.stores = this.allStore.filter(store => store.Delivery === true && store.ShopOnline);
        break;
      case 'pharmacy':
        this.stores = this.allStore.filter(store => store.Pharmacy === true);
        break;
      case 'starbucks':
        this.stores = this.allStore.filter(store => {
          let isMatch = false;
          if (!!store.ServiceStores && store.ServiceStores.length > 0) {
            store.ServiceStores.forEach(service => {
              if ('Starbucks'.indexOf(service.ServiceName.toString()) > -1) {
                isMatch = true;
                return false;
              }
            });
          }
          return isMatch;
        });
        break;
      default:
        this.stores = this.allStore;
    }
  }

  goAppSettings() {
    if (!this.platform.is('cordova')) {
      return;
    }
    this.openNativeSettings.open('application_details').then(rs => {
    }).catch(err => {
      console.log('err openNativeSettings', err);
    });
  }
  public getPageName() {
		return "ResultFindStorePage";
	}
}
