import { Injectable } from "@angular/core";
import { map, catchError } from 'rxjs/operators';

import _ from 'lodash';

import { RelationshopHttpClient } from '@rsApp/modules/gateway/rs-api.service';
import { RelationshopOnlineEComHttpClient } from '@rsApp/modules/gateway/rs-online-ecom-api.service';
// import { refreshParam } from './utils';
import { of } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AppSettings {
  groupCacheKey = 'setting';
   saleTagImage = '';
  constructor(
    public api: RelationshopHttpClient,
    public ecomApi: RelationshopOnlineEComHttpClient,
  ) {
    //Preload setting to improve performance
    const getSaleTagImage = localStorage.getItem('DEFAULT_IMAGE_SALE_TAG_URL');
    if (getSaleTagImage) {
      localStorage.removeItem('DEFAULT_IMAGE_SALE_TAG_URL');
    }

    this.getSaleTagImage();
  }

  public getSettings() {
    return this.api.get('/settings', {params:{rCache: 'd'}});
  }

  public getSettingValue(settingName) {
    let settings = this.getSettings().pipe(
      map((data) => {
        var setting = _.find(data, (o: any) => (o.SettingName == settingName))
        if (setting) {
          return setting.SettingValue;
        }
        return '';
      })
    )
    return settings;
  }
  public getEcomSettingValue(settingName, storeID) {
    return this.ecomApi.get('/checkout/messageresources', { params: { keyword: settingName, storeId: storeID } });
  }
  public getLastVersion(bannerId) {
    return this.api.get(`/app/version?banner=${bannerId}`);
  }
  public getProgramsSetting(refresher?) {
    let params = {};
    const url = `/setting/HOMEPAGE_PROGRAMS`;
    Object.assign(params, refreshParam(refresher, this.groupCacheKey));
    let o = this.api.get(url, { params: params }).pipe(
      catchError((e) => {
        console.log('getSettingPrograms error');
        return [];
      }),
      map(settingResult => {
        return JSON.parse(settingResult);
      }));
    return o;
  }
  public getProgramsSettingByProgramCode(programCode, refresher?) {
    let params = {};
    const url = `/setting/HOMEPAGE_PROGRAMS`;
    Object.assign(params, refreshParam(refresher, this.groupCacheKey));
    let o = this.api.get(url, { params: params }).pipe(
      catchError((e) => {
        console.log('getSettingPrograms error');
        return [];
      }),
      map(settingResult => {
        const settings = JSON.parse(settingResult);
        return _.find(settings, setting => setting.ProgramCode == programCode);
      }));
    return o;
  }
  public getSaleTagImage(refresher?) {
    let params = {};
    const saleTagImg = this.saleTagImage || localStorage.getItem('DEFAULT_IMAGE_SALE_TAG_URL');
    if (saleTagImg) {
      return of(saleTagImg);
    }
    const url = `/setting/DEFAULT_IMAGE_SALE_TAG_URL`;
    Object.assign(params, refreshParam(refresher, this.groupCacheKey));
    let o = this.api.get<string>(url, { params: params }).pipe(
      catchError((e) => {
        console.log('getTakeOutServiceSetting error');
        return of('');
      }),
      map(settingResult => {
        this.saleTagImage = settingResult;
        return settingResult;
      }));
    return o;
  }

  public getTakeOutServiceSetting(refresher?) {
    let params = {};
    const url = `/setting/TAKEOUT_SERVICE_ENABLE_APP`;
    Object.assign(params, refreshParam(refresher, this.groupCacheKey));
    let o = this.api.get(url, { params: params }).pipe(
      catchError((e) => {
        console.log('getTakeOutServiceSetting error');
        return of(false);
      }),
      map(settingResult => {
        return settingResult == "true";
      }));
    return o;
  }
  public getDynamicNavigationServiceSetting(refresher?) {
    let params = {};
    const url = `/setting/SERVICE_DYNAMIC_MENU_APP`;
    Object.assign(params, refreshParam(refresher, this.groupCacheKey));
    let o = this.api.get(url, { params: params }).pipe(
      catchError((e) => {
        return of("[]");
      }),
      map((settingResult: any) => {
        return JSON.parse(settingResult || "[]");
      }));
    return o;
  }
}
// clone from utils
function refreshParam(refresher, groupkey?, key?, prefixKey?) {
  return { rCache: refresher ? 'fresh' : 'default', rCacheKey: key || null, rCacheGroupKey: groupkey || null, rPrefixCacheKey: prefixKey || null };
}
