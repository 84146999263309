import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { PhoneFormatPipe } from './pipes/phone-format/phone-format';
import { SafeHtmlPipe } from './pipes/safe-html/safe-html';
import { GuestMsg } from './components/guest-msg/guest-msg';
import { LearnMore } from './components/learn-more/learn-more';
import { SkeletonComponent } from './components/skeletion-component/skeleton-component';
// import { CheckOrderModal } from '../dashboard/component/check-order-modal/checkout-status-bar';
import { SafeUrlPipe } from './pipes/safe-html/safe-url';
import { IframeModal } from './components/iframe-modal/iframe-modal';
import { SafeResourceUrlPipe } from './pipes/safe-html/safe-resource-url';
import { WidgetLayoutComponent } from './components/widget-layout/widget-layout.component';
import { CMS_API_URL, CMS_HTTP_CLIENT } from '../more/more.config';
import { ENV } from '@app/env';
import { RelationshopEComCoreHttpClientFactory } from '../gateway/rs-ecom-core-api.service';
import { HttpHandler } from '@angular/common/http';
import { WidgetLayoutService } from './providers/widget-layout.service';
import { Credential } from '@rsApp/modules/auth/providers/credential.service';
import { DeleteAccountComponent } from './components/delete-account/delete-account.component';
export { GuestMsg, LearnMore };

@NgModule({
  declarations: [
    PhoneFormatPipe, SafeHtmlPipe, SafeUrlPipe, SafeResourceUrlPipe, GuestMsg, SkeletonComponent/*, CheckOrderModal*/, LearnMore, IframeModal, WidgetLayoutComponent, DeleteAccountComponent
  ],
  imports: [
    CommonModule,
    IonicModule
  ],
  entryComponents: [GuestMsg/*, CheckOrderModal*/,LearnMore, IframeModal, WidgetLayoutComponent, DeleteAccountComponent],
  exports: [PhoneFormatPipe, SafeHtmlPipe, SafeUrlPipe, SafeResourceUrlPipe, GuestMsg, SkeletonComponent/*, CheckOrderModal*/, LearnMore, IframeModal, WidgetLayoutComponent, DeleteAccountComponent],
  providers: [
    WidgetLayoutService,
    { provide: CMS_API_URL, useValue: ENV.CMSUrl },
    { provide: CMS_HTTP_CLIENT, useFactory: RelationshopEComCoreHttpClientFactory, deps: [HttpHandler, Credential, CMS_API_URL] },
  ]
})
export class UtilsModule { }
