import { NgModule } from '@angular/core';
import { ShoppingList} from './providers/shopping-list.service';
import { CurrentShoppingList} from './providers/current-shopping-list.service';
import { CheckedShoppingItemService} from './providers/checked-shopping-list.service';
import { FavoriteList} from './providers/favorite-list.service';
export { ShoppingList, CurrentShoppingList, CheckedShoppingItemService, FavoriteList };
@NgModule({
  providers: [
    ShoppingList,
    CheckedShoppingItemService,
    FavoriteList
    // CurrentShoppingList,
  ]
})
export class ShoppingListServicesModule{
}