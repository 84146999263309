import { Injectable, NgModule } from '@angular/core';
import { ReplaySubject, Subscription} from 'rxjs';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub'
import { RelationshopHttpClient} from '@rsApp/modules/gateway/rs-api.service';
import { Credential } from '@rsApp/modules/auth/providers/credential.service';
import { ShoppingList} from './shopping-list.service';
import { tap, map, switchMap, take } from 'rxjs/operators';
import { Observable, combineLatest, of } from 'rxjs';
import _ from 'lodash';
import {AuthService} from '@rsApp/modules/auth/providers/auth.service';
import { DxpComponentService } from '@rsApp/modules/utils/providers/dxp.component.service';

@Injectable({
	providedIn: 'root'
})
export class CurrentShoppingList{
	public sList$: ReplaySubject<any>;
	public sListItems$: ReplaySubject<any>;
	public cList:any;
	public CACHE_TIME:number = 10*60*1000; // 10 mins
	public lastCahe:number = 0;
	refreshSub: Observable<any> = null;
	constructor(private api: RelationshopHttpClient, private cre: Credential, private events: NgxPubSubService, private listService:ShoppingList,private auth: AuthService,
		public dxpComponentService: DxpComponentService
		 ){
		this.sList$ = new ReplaySubject(1);
		this.sListItems$ = new ReplaySubject(null);
		// this.sList$.next(0);
		this.events.subscribe('emmit-list', (data) => {
			if(data &&  !data.reload && this.cList && data.id !== this.cList.CS_UserShoppingListId){
				return;
			}
			if(data && data.totalItems){
				this.cList.TotalItems = data.totalItems;
				this.sList$.next(this.cList);
				return;
			}
			this.refresh(data && data.reload);
			if(!data || data.source !== "dxp-component"){
				this.dxpComponentService.onLoadShoppingList();
			}
			if(data && data.source == 'dxp-component'){
				this.listService.clearCache(this.cre.currentUser,this.cList.CS_UserShoppingListId);
			}
		});
		this.refresh(true);
		// this._loadActiveShoppingList().subscribe();
		this.sList$.asObservable().subscribe((list)=>{
			
			this.cList = list;
		});
	} 

	getActiveList(){
		let now = +new Date(),
			offset = now - this.lastCahe;
		if(offset > this.CACHE_TIME){
			this.refresh(true);
		}
		return this.sList$.asObservable();
	}
	update(list){
		this.sList$.next(list);
	}
	refresh(reloadAll = false){
		if(!this.cre.currentUser){
			this.sList$.next(null);
			return;
		}
		this.lastCahe = +new Date();
		
		if (this.refreshSub) {
			this.refreshSub.subscribe();
		}

		this.refreshSub = reloadAll ? this._loadActiveShoppingList() : this._loadCount();
		this.refreshSub.subscribe(() => {
			//Load Shopping List State
			this.getItems().subscribe();
		});
	}
	_loadActiveShoppingList(){
		let user = this.cre.currentUser;
		let params = { username: user.Email };
        return this.api.get('/shoppinglists/v5/all', {params: params}).pipe(
        	map((rs)=>{
        		let list = _.find(rs, {IsActive: true});
        		if(!list){
        			return;
        		}
        		this.sList$.next(list);
        	})
        );
	}
	_loadCount(){
		console.log('load count --------');
		let s = this.getActiveList().pipe(take(1));
		let count = this.api.get(`/ShoppingLists/user-items/v5/count?username=${this.cre.currentUser.Email}&userShoppingListId=0`);

		return combineLatest([s, count]).pipe(
			tap((rs:Array<any>)=>{
				let list = rs[0],
					count = rs[1];
				list.TotalItems = count;
				this.sList$.next(list);
			})
		);
	}
	addProduct(product){
		if(product.ShpLstItem && product.ShpLstItem.ShoppingListId){
			return this.updateItem(product);
		}
		return this.listService.addEcomProducToShoppingList(product, this.cList.CS_UserShoppingListId, this.cre.currentUser).pipe(
			tap((item:any)=>{
				if(product.ShpLstItem){
					delete item.Quantity;
					Object.assign(product.ShpLstItem, item);
				}
				else{
					product.ShpLstItem = item;
				}
			})
		)
	}
	updateItem(product, newQuantity = 1){
		let item = product.ShpLstItem;
		if(!item || !item.ShoppingListId){
			return this.addProduct(product);
		}
		if(newQuantity < 1){
			return this.removeProduct(product);
		}
		return this.listService.updateProductToShoppingList(Object.assign({},item, {Quantity: newQuantity}), this.cList.CS_UserShoppingListId, this.cre.currentUser).pipe(
			tap((rs)=>{
				// Object.assign(product.ShpLstItem, {Quantity:newQuantity});
			})
		);
	}
	removeProduct(product){
		return this.listService.removeItemFromShoppingList(this.cre.currentUser, product.ShpLstItem).pipe(
			tap(()=>{
				// delete product.ShpLstItem;
				Object.assign(product.ShpLstItem, {ShoppingListId: null});
			})
		);
	}
	getItems(){
		let o = this.cList  && of(this.cList) || this.getActiveList();
		o = o.pipe(
			take(1),
			switchMap(()=>{
				return this.listService.getProductsInShoppingList(this.cre.currentUser, this.cList.CS_UserShoppingListId)
					.pipe(tap((items) => {
						const sListItems = _.groupBy(items, 'ProductUPC') || {};
						this.sListItems$.next(sListItems);
					}));
			})
		);
		return o;
	}

}


