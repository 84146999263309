import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ShoppingListIconModule } from '../shopping-list/shopping-list-icon.module';
import { CartListIconModule } from '../cart-list-icon/cart-list-icon.module';
import { TabsPageRoutingModule } from './tabs.router.module';

import { TabsPage } from './tabs.page';
import { CountDownChangeOrder } from './component/count-down-change-order/count-down-change-order';
import { PurchaseServiceModule } from '../purchase/purchase-services.module';
import { ChangeOrderModalComponent } from '../purchase/components/change-order-modal/change-order-modal.component';
export { TabsPage };

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    TabsPageRoutingModule,
    ShoppingListIconModule,
    CartListIconModule,
    PurchaseServiceModule
  ],
  exports: [ChangeOrderModalComponent, CountDownChangeOrder],
  entryComponents: [ChangeOrderModalComponent, CountDownChangeOrder],
  declarations: [TabsPage, ChangeOrderModalComponent, CountDownChangeOrder]
})
export class TabsPageModule { }
