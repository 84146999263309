import { PageBase } from '@rsApp/core/page-base';
import _ from 'lodash';
import { CacheService } from 'ionic-cache';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, ViewEncapsulation } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { Utils } from '@rsApp/modules/utils/providers/utils';
import { AuthService } from '@rsApp/modules/auth/providers/auth.service';
import { Store } from '../../providers/store.service';
import { CurrentStore } from '../../providers/current-store.service';
import { Credential } from '@rsApp/modules/auth/providers/credential.service';
import { enumStoreId } from '@rsApp/core/enum';
import { BaseStore } from '../../base-store';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';
import { PermanentSettings } from '@rsApp/modules/utils/providers/permanent-settings';
import { ShoppingCartGatewayService } from '@rsApp/modules/shopping-cart/providers/shopping-cart-gateway.service';

@Component({
  selector: 'page-store-context',
  templateUrl: 'store-context.html',
  styleUrls: ['./store-context.scss', '../../styles/store-list.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StoreContextPage extends BaseStore {
  searchbar: string;
  limitedQuantity: any;
  isPickupDelivery = false;
  storeListLength: any;

  returnRoute: string;
  defaultHref: string;
  btnFillInstore: string = "outline";
  btnFillOnline: string = "outline";
  listStore: any[] = [];
  keyword;

  serviceList: any;

  constructor(
    public utils: Utils,
    public auth: AuthService,
    public cStore: CurrentStore,
    public modalCtrl: ModalController,
    public router: Router,
    public store: Store,
    public route: ActivatedRoute,
    public cache: CacheService,
    public cri: Credential,
    public events: NgxPubSubService,
    public pSettings: PermanentSettings,
    public shoppingCartService: ShoppingCartGatewayService,
  ) {
    super(utils, cStore, router, cache, store, cri, events, pSettings, shoppingCartService);
    const params = this.route.snapshot.paramMap;
    this.returnRoute = params.get('returnRoute');
    this.defaultHref = params.get('defaultHref');
  }

  async ionViewWillEnter() {
    await super.getCurrent();
    this.initSelectedStore();
    return true;
  }
  closer() {
    this.router.navigate([this.returnRoute]);
  }

  async initSelectedStore() {
    let storePath = null;
    if (this.cStore.store) {
      storePath = this.cStore.store.ShopPath && this.cStore.store.ShopPath.toLowerCase();
      this.serviceList = _.filter(this.cStore.store.SystemService, s => s.ServiceSettings != null && s.IsActive);
      if (!this.cStore.store.ShopPath) {
        this.cStore.store.Service = _.find(this.cStore.store.SystemService, s => s.ServiceSettings.ShopPath === "InStore");
        this.cStore.store.ShopPath = this.cStore.store.Service.ServiceSettings.ShopPath;
        try {
          await this.setStore(this.cStore.store, this.cStore.store.Service)
        }
        catch (error) {
          this.utils.hideLoading();
        }
      }
    }
    // if (this.currentUser && this.currentUser.ShopPath) {
    //   storePath = this.currentUser.ShopPath.toLowerCase();
    // } else if (this.cStore.store) {

    //   storePath = this.cStore.store.ShopPath && this.cStore.store.ShopPath.toLowerCase();
    //   this.serviceList = this.cStore.store.SystemService;
    // }
    // this.selectStorePath(storePath);
  }

  viewMoreStore() {
    if (!!this.returnRoute) {
      this.router.navigate(['/store/find', { router: this.returnRoute }]);
    } else {
      this.router.navigate(['/store/find']);
    }
  }
  public getPageName() {
		return "StoreContextPage";
	}
}
