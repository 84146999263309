import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes, CanActivate } from '@angular/router';
import { TabsPage, TabsPageModule } from './modules/tabs/tabs.module';
import { GuestGuardService as GuestGuard } from './modules/auth/providers/guest-guard.service';
import { AuthGuardService as AuthGuard } from './modules/auth/providers/auth-guard.service';
import { StoreGuardService as StoreGuard } from './modules/auth/providers/store-guard.service';
import { ForceChangePasswordGuardService as ForceChangePasswordGuard } from './modules/auth/providers/force-change-password-guard.service';
import { IsOutDateGuardService as IsOutDateGuard } from './modules/auth/providers/isOutDate-guard.service';

const routes: Routes = [
  {
    path: '', redirectTo: 'home', pathMatch: 'full',
    canActivate: [AuthGuard, StoreGuard],
    data: { requiredLogin: true }
  },
  {
    path: 'home', redirectTo: '/tabs/home', pathMatch: 'full',
    canActivate: [AuthGuard, StoreGuard],
    data: { requiredLogin: true }
  },
  {
    path: 'signup', loadChildren: './modules/bigy-signup/bigy-signup.routing.module#BigySignupRoutingModule',
    canActivate: [GuestGuard]
  },
  {
    path: 'login', loadChildren: './modules/sign-in/sign-in.module#SignInModule',
    canActivate: [GuestGuard]
  },
  {
    path: 'store', loadChildren: './modules/store/store.module#StoreModule',
    canActivate: [ForceChangePasswordGuard]

  },
  { path: 'tutorial', loadChildren: './modules/tutorial/tutorial.module#TutorialModule' },
  {
    path: 'checkout', loadChildren: './modules/checkout/checkout.module#CheckoutModule',
    canActivate: [AuthGuard, ForceChangePasswordGuard],
    data: { requiredLogin: true }
  },
  {
    path: 'account', loadChildren: './modules/account/account.module#AccountModule',
    canActivate: [AuthGuard],
    data: { requiredLogin: true }
  },
  {
    path: 'myGas', loadChildren: './modules/account/account.module#AccountModule',
    canActivate: [AuthGuard],
    data: { requiredLogin: true }
  },
  { path: 'offer', loadChildren: './modules/deals/deals.router.module#DealsPageRoutingModule' },
  {
    path: 'online-order',
    loadChildren: './modules/cus-online-order/cus-online-order-routing.module#CusOnlineOrderRoutingModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'cake-catering/:systemServiceId',
    loadChildren: './modules/cus-online-order/cus-online-order-routing.module#CusOnlineOrderRoutingModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'commerce/:urlSlugSystemService',
    loadChildren: './modules/cus-online-order/commerce-routing.module#CommerceRoutingModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'notification',
    loadChildren: './modules/notification/notification.module#NotificationModule',
    canActivate: [AuthGuard],
    data: { requiredLogin: true }
  },
  // {
  //   path: 'my-bigy', loadChildren: './modules/myBigy/myBigy.module#MyBigyModule',
  //   canActivate: [AuthGuard],
  //   data: { requiredLogin: true }
  // },
  // { path: 'pickup', loadChildren: './modules/pickup/pickup.module#PickupModule'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    TabsPageModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
