/*
	https://stackoverflow.com/questions/45202208/angular-4-interceptor-retry-requests-after-token-refresh
*/
import { Injectable, } from '@angular/core';
import { Router } from '@angular/router';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';

import { Observable, Subject, of, from, empty, throwError } from 'rxjs';
import { switchMap, tap, catchError } from 'rxjs/operators';
import { CacheService } from "ionic-cache";
import { AuthService } from '../../modules/auth/providers/auth.service';
import { Network } from '@ionic-native/network/ngx';
import { ENV } from '@app/env';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	private refreshSubject: Subject<any> = new Subject<any>();

	constructor(private auth: AuthService, public router: Router, public network: Network) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let url = req.url;
		if (url.includes('tokens') || url.indexOf(ENV.EComHost) < 0) {
			return next.handle(req);
		}

		let o = next.handle(req);
		o =  o.pipe(
			catchError((error, caught) => {
				if (error instanceof HttpErrorResponse){
					if(this.isTokenExpiryErr(error)) {
						if(this.network.type == this.network.Connection.NONE){
							return throwError(error);
						}
						// refresh Token
						return this.refreshToken(this.auth.cre.rsApiToken).pipe(
							switchMap((res)=>{
								if(!res){
									this.auth.logout();
									return throwError(error);
								}
								return next.handle(this.updateHeader(req));
							})
						);

						// this.auth.logout();
						// return throwError(error);
					}
					else{
						return throwError(error);
					}
				}
				return caught
			})
		)
		return o;
	}

	private isTokenExpiryErr(error: HttpErrorResponse): boolean {
		console.log('error status',  error.status, error);
		return error.status === 401 || error.status === 403 /*|| error.status === 0*/;
	}

	public refreshToken(token?) {
		this.refreshSubject.subscribe({
			complete: ()=>{
				this.refreshSubject = new Subject<any>();
			}
		})

		if(this.refreshSubject.observers.length === 1){
			this.auth.validateToken(token).subscribe(this.refreshSubject);
		}
		return this.refreshSubject;
	}
	public updateHeader(req){
		const token = this.auth.cre.rsApiToken;
		req = req.clone({
			headers: req.headers.set('X-MCMAccessToken', token)
		});
		return req;
	}



}
