import { PageBase } from '@rsApp/core/page-base';
import _ from 'lodash';
import { CacheService } from 'ionic-cache';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, ViewEncapsulation } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { Utils } from '@rsApp/modules/utils/providers/utils';
import { AuthService } from '@rsApp/modules/auth/providers/auth.service';
import { Store } from '../../providers/store.service';
import { CurrentStore } from '../../providers/current-store.service';
import { Credential } from '@rsApp/modules/auth/providers/credential.service';
import { enumStoreId } from '@rsApp/core/enum';
import { BaseStore } from '../../base-store';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';
import { PermanentSettings } from '@rsApp/modules/utils/providers/permanent-settings';
import { ShoppingCartGatewayService } from '@rsApp/modules/shopping-cart/providers/shopping-cart-gateway.service';

@Component({
  selector: 'page-near-store',
  templateUrl: 'near-store.html',
  styleUrls: ['./near-store.scss', '../../styles/store-list.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NearStorePage extends BaseStore {
  searchbar: string;
  limitedQuantity: any;
  isPickupDelivery = false;
  storeListLength: any;

  returnRoute: string;
  defaultHref: string;
  btnFillInstore: string = "outline";
  btnFillOnline: string = "outline";
  listStore: any[] = [];
  keyword;

  serviceList: any;

  constructor(
    public utils: Utils,
    public auth: AuthService,
    public cStore: CurrentStore,
    public modalCtrl: ModalController,
    public router: Router,
    public store: Store,
    public route: ActivatedRoute,
    public cache: CacheService,
    public cri: Credential,
    public events: NgxPubSubService,
    public pSettings: PermanentSettings,
    public shoppingCartService: ShoppingCartGatewayService
  ) {
    super(utils, cStore, router, cache, store, cri, events, pSettings, shoppingCartService);
    const params = this.route.snapshot.paramMap;
    this.returnRoute = params.get('returnRoute');
    this.defaultHref = params.get('defaultHref');
  }

  async ionViewWillEnter() {
    await super.getCurrent();
    this.initSelectedStore();
    await this.loadData();
    /*
    if(!this.currentStore && (!this.listStore || this.listStore.length == 0) ){
      this.viewMoreStore();
      return false;
    }
    */
    return true;
  }

  async loadData() {
    this.keyword = (this.currentUser && this.currentUser.ZipCode) || (this.currentStore && this.currentStore.Zipcode) || '';
    // this.keyword = '01056';
    const stores = await this.searchStores();
    this.listStore = stores;
    
  }

  async searchStores() {
    try {
      await this.utils.showLoading();
      let stores: any = [];

      const position = await this.utils.getCurrentPosition();

      if (position && position.isLocationOn) {
        stores = await this.store.getStoreNearBy(position.lat + ',' + position.lng).toPromise();
      }

      if (this.keyword && stores.length == 0) {
        // this.keyword = "";
        stores = await this.store.searchStores(this.keyword, "l").toPromise();
      }

      this.utils.hideLoading();
      if (stores != null) {
        const listStore = [];
        stores.every(s => {
          if (s.CS_StoreID !== enumStoreId.BigYExpress && (!this.currentStore || (this.currentStore && this.currentStore.CS_StoreID != s.CS_StoreID))) {
            s.StoreType = this.cStore.displayStoreType(s);
            listStore.push(s);
          }
          if (listStore.length === 3) return false;
          return true;
        });
        return listStore;
      }
      return [];
    }
    catch (error) {
      console.log(error);
      this.utils.hideLoading();
      return [];
    }
  }

  closer() {
    this.router.navigate([this.returnRoute]);
  }

  async initSelectedStore() {
    let storePath = null;
    if (this.cStore.store) {
      storePath = this.cStore.store.ShopPath && this.cStore.store.ShopPath.toLowerCase();
      this.serviceList = this.cStore.store.SystemService;
    }
    // if (this.currentUser && this.currentUser.ShopPath) {
    //   storePath = this.currentUser.ShopPath.toLowerCase();
    // } else if (this.cStore.store) {

    //   storePath = this.cStore.store.ShopPath && this.cStore.store.ShopPath.toLowerCase();
    //   this.serviceList = this.cStore.store.SystemService;
    // }
    // this.selectStorePath(storePath);
  }

  viewMoreStore() {
    if (!!this.returnRoute) {
      this.router.navigate(['/store/find', { router: this.returnRoute }]);
    } else {
      this.router.navigate(['/store/find']);
    }
  }
  // not use any more
  /*
  selectStorePath(path) {
    if (path === "online") {
      this.btnFillOnline = "solid";
      this.btnFillInstore = "outline";
    }
    else if (path === "instore") {
      this.btnFillInstore = "solid";
      this.btnFillOnline = "outline";
    }
  }
  */
  public getPageName() {
		return "NearStorePage";
	}
}
