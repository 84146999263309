import { Injectable, NgModule } from '@angular/core';

import { RelationshopHttpClient } from '@rsApp/modules/gateway/rs-api.service';


import { CacheService } from 'ionic-cache';

import { ENV } from '@app/env';
import { RelationshopEComHttpClient } from '../../gateway/rs-ecom-api.service';
import { User } from '@rsApp/modules/account/provider/user.service';
import { tap, map, switchMap } from 'rxjs/operators';
import _ from 'lodash';
import { RelationshopEComCoreHttpClient } from '@rsApp/modules/gateway/rs-ecom-core-api.service';
import { of, Observable } from 'rxjs';
@NgModule({

})
export class StoreServiceModule {

}

@Injectable({
    providedIn: StoreServiceModule
})
export class Store {
    _store: any;
    storeDxpApiUrl: any;
    systemServiceIndex: any;

    constructor(
        private cache: CacheService,
        private api: RelationshopHttpClient,
        private ecomApi: RelationshopEComHttpClient,
        private ecomCoreApi: RelationshopEComCoreHttpClient,
        private user: User,
    ) {
        this.storeDxpApiUrl = `/store${ENV.VersionStoreAPI}/api`;
    }

    searchStores(keyword, sort) {
        
        let searchStoresO = this.ecomCoreApi.get(`${this.storeDxpApiUrl}/store/company/0/location/search`, { params: { keyword, sort, rCache: 'default'} });
        
        //make sure load system service
        // if(!this.systemServiceIndex){
        //     searchStoresO = searchStoresO.pipe(
        //         switchMap((dxpStores)=>{
        //             return this.getStoreSystemServices().pipe(
        //                 map(()=>{
        //                     return dxpStores;
        //                 })
        //             )
        //         }),
        //     )
        // }
        return searchStoresO.pipe( 
            switchMap((dxpStores) => {
                // const stores = [];
                // _.each(dxpStores, (dxpStore) => {
                //     const store = this.convertDXPStoreToStore(dxpStore);
                //     store.SystemService = _.orderBy(store.SystemService, ['Priority']);
                //     stores.push(store);

                // });
                return this.convertDXPStoresToStores(dxpStores);
            })
        );
    }


    getStoreById(id) {
        const seq = this.ecomCoreApi.get(`${this.storeDxpApiUrl}/Store/by-id/${id}`, {params:{rCache:'d'}}).pipe(
            map((dxpStore) => {
                // console.log('store', this.convertDXPStoreToStore(dxpStore));
                return this.convertDXPStoreToStore(dxpStore);
            })
        );
        return seq;
    }

    getStoreNearBy(latlng) {
        const params = { latlng };
        let searchStoresO  = this.ecomCoreApi.get(`${this.storeDxpApiUrl}/store/company/0/by-coordian`, { params });
        //make sure load system service
        // if(!this.systemServiceIndex){
        //     searchStoresO = searchStoresO.pipe(
        //         switchMap((dxpStores)=>{
        //             return this.getStoreSystemServices().pipe(
        //                 map(()=>{
        //                     return dxpStores;
        //                 })
        //             )
        //         }),
        //     )
        // }
        return searchStoresO.pipe(
            switchMap((dxpStores) => {
                // const stores = [];
                // _.each(dxpStores, (dxpStore) => {
                //     stores.push(this.convertDXPStoreToStore(dxpStore));
                // });
                return this.convertDXPStoresToStores(dxpStores);
            })
        );
    }

    getStoreSystemServices() {
        const seq = this.ecomCoreApi.get(`${this.storeDxpApiUrl}/service/all`,{params:{rCache:'d'}}).pipe(
            map((dxpServices) => {
                let services = this.convertDXPServicesToServices(dxpServices);
                services = _.orderBy(services, ['Priority']);
                // save store service Index
                this.systemServiceIndex = _.groupBy(dxpServices, 'ServiceId');
                return services;
            })
        );
        return seq;
    }

    getStoreInStoreervices() {
        const seq = this.ecomCoreApi.get(`${this.storeDxpApiUrl}/InstoreService`, {params:{rCache:'d'}}).pipe(
            map((dxpServices) => {
                return this.convertDXPInstoreServicesToServices(dxpServices);
            })
        );
        return seq;
    }

    setDontAllowLocation() {
        const key = 'dont-allow-location';
        return this.cache.saveItem(key, true)
            .catch(() => {
                console.log('setDontAllowLocation expired or doesn\'t exist!');
            });
    }

    getDontAllowLocation() {
        const key = 'dont-allow-location';
        return this.cache.getItem(key)
            .catch(() => {
                console.log('getDontAllowLocation expired or doesn\'t exist!');
            });
    }

    getStoreCities() {
        return this.api.get('/cities');
    }

    getStoreStates() {
        return this.api.get('/states');
    }
    getBanner() {
        return this.api.get('/banners');
    }
    /* not use any more */
    setStoretoShoppingV3(user, store) {
        const url = `/user/store/v3?userId=${user.ExternalCustomerCardID}&storeId=${store.ExternalID}`;
        return this.ecomApi.put(url, null);
    }

    getStoreProviver(store, shopPath) {
        try {
            const seq = this.ecomCoreApi.get(`${this.storeDxpApiUrl}/StoreEcomProvider/${store.CS_StoreID}/${shopPath}`);
            return seq;
        }
        catch {
            return of(null);
        }

    }

    getProviver(provider) {
        try {
            const seq = this.ecomCoreApi.get(`${this.storeDxpApiUrl}/Provider/${provider.ProviderId}`);
            return seq;
        }
        catch {
            return of(null);
        }
    }

    setStore(store, currentUser, service) {
        currentUser.StoreID = store.CS_StoreID;
        currentUser.StoreZipCode = store.Zipcode;
        currentUser.StoreName = store.StoreName;
        const shopPath = (typeof (service) === "string") && service || service.ServiceSettings.ShopPath;
        currentUser.ShopPath = shopPath;
        let o: Observable<any> = of(true);
        return o.pipe(
            switchMap(()=>{
                return this.user.setStore(currentUser, store, currentUser.ShopPath);
            })
         );
        
        // return this.user.updateUserById(currentUser);
    }

    displayStoreType(store) {
        if (!store || !store.ShopOnline || (!store.Pickup && !store.Delivery)) return '';
        const pickupText = store.Pickup ? 'Pickup ' : '';
        const deliveryText = store.Delivery ? 'Delivery ' : '';
        const andText = store.Pickup && store.Delivery ? '& ' : '';
        const type = `${deliveryText}${andText}${pickupText}Available`;
        return type;
    }


    convertDXPStoreToStore(dxpStore) {
        const store: any = {
            CS_StoreID: dxpStore.StoreId,
            // tslint:disable-next-line:radix
            StoreID: parseInt(dxpStore.StoreCode),
            StoreName: dxpStore.Name,
            Address1: dxpStore.Address,
            City: dxpStore.City,
            State: dxpStore.State,
            Zipcode: dxpStore.ZipCode,
            PhoneNumber: dxpStore.Phone,
            Logo: dxpStore.StoreLogo,
            CStoreURL: dxpStore.StoreLogo,
            StoreIconURL: dxpStore.MapPin,
            Latitude: parseFloat(dxpStore.Latitude),
            Longitude: parseFloat(dxpStore.Longitude),
            StoreHours: dxpStore.StoreHour,
            TaxRate: dxpStore.StoreTaxRate,
            Pharmacy: dxpStore.PharmacyFlag,
            PharmacyPhone: dxpStore.PharmacyPhone,
            PharmacyHours: dxpStore.PharmacyHours,
            SystemService: [],
            DepartmentStores: [],
            ServiceStores: [],
            StoreType: this.displayStoreType(dxpStore)
        };

        if (dxpStore.StoreServices != null && dxpStore.StoreServices.length > 0) {
            _.each(dxpStore.StoreServices, (storeService) => {
                if(!storeService.ServiceName) {
                    let s = this.systemServiceIndex[storeService.ServiceId];
                    if(s && s.length>0){
                        Object.assign(storeService, s[0]);
                    }
                }
                switch (storeService.ServiceName) {
                    case 'eCom':
                        store.ShopOnline = true;
                        store.Pickup = true;
                        break;
                    case 'Pickup':
                        store.ShopOnline = true;
                        store.Pickup = true;
                        break;
                    case 'Delivery':
                        store.ShopOnline = true;
                        store.Delivery = true;
                        break;
                    default:
                        break;
                }
                let serviceSettings = null;
                try {
                    serviceSettings = storeService.SettingJson ? JSON.parse(storeService.SettingJson) : null
                }
                catch (err) {
                    console.log(storeService.SettingJson);
                }
                const systemService = {
                    CS_ServicesID: storeService.ServiceId,
                    DisplayName: storeService.DisplayName,
                    ServiceName: storeService.ServiceName,
                    ServiceSettings: serviceSettings,
                    Priority: storeService.DisplayOrder,
                    IsActive: storeService.IsActive,
                };

                store.SystemService.push(systemService);
            });
        }


        // DepartmentStore
        if (dxpStore.DepartmentStores != null && dxpStore.DepartmentStores.length > 0) {
            _.each(dxpStore.DepartmentStores, (dxpDepartmentStore) => {
                const departmentStore = {
                    CS_StoreID: dxpDepartmentStore.StoreId,
                    CS_DepartmentID: dxpDepartmentStore.DepartmentId,
                    DepartmentSatHours: dxpDepartmentStore.DepartmentSatHours,
                    DepartmentSunHours: dxpDepartmentStore.DepartmentSunHours,
                    DepartmentMFHours: dxpDepartmentStore.DepartmentMFHours,
                    DepartmentPhone: dxpDepartmentStore.DepartmentPhone,
                    DepartmentNote: dxpDepartmentStore.DepartmentNote,
                    DepartmentName: dxpDepartmentStore.DepartmentName,
                    DepartmentIconURL: dxpDepartmentStore.DepartmentIconUrl,
                    DepartmentLinkURL: dxpDepartmentStore.DepartmentLinkUrl,
                    InStoreDepartment: dxpDepartmentStore.InStoreDepartment,
                    IsShowDepartmentIcon: dxpDepartmentStore.IsShowDepartmentIcon,
                    DepartmentHourses: dxpDepartmentStore.DepartmentHourses,
                    Priority: dxpDepartmentStore.DisplayOrder
                };
                if(!store.Pharmacy && dxpDepartmentStore.DepartmentName &&  dxpDepartmentStore.DepartmentName.toLowerCase() == 'pharmacy'){
                    store.Pharmacy = true;
                }

                store.DepartmentStores.push(departmentStore);
            });
        }

        // ServiceStores
        if (dxpStore.InstoreServices != null && dxpStore.InstoreServices.length > 0) {
            _.each(dxpStore.InstoreServices, (instoreService) => {
                const systemService = {
                    CS_ServicesID: instoreService.Id,
                    ServiceName: instoreService.ServiceName,
                    ServiceGroup: instoreService.ServiceGroup,
                    Priority: instoreService.Priority
                };
                store.ServiceStores.push(systemService);
            });
        }

        // BannerId, ExternalId, ExternalId2, ExternalId3
        if (dxpStore.Attributes != null && dxpStore.Attributes.length > 0) {
            let bannerId = 0, cityAreaId = 0, regionId = 0;
            let externalId = '', externalId2 = '', externalId3 = '', typeId = '';
            _.each(dxpStore.Attributes, (attribute) => {
                if (attribute.Values != null && attribute.Values.length > 0) {
                    switch (attribute.Name) {
                        case 'Banner':
                            // tslint:disable-next-line:radix
                            bannerId = isNaN(attribute.Values[0]) ? bannerId : parseInt(attribute.Values[0]);
                            break;
                        case 'City Area':
                            // tslint:disable-next-line:radix
                            cityAreaId = isNaN(attribute.Values[0]) ? cityAreaId : parseInt(attribute.Values[0]);
                            break;
                        case 'Region':
                            // tslint:disable-next-line:radix
                            regionId = isNaN(attribute.Values[0]) ? regionId : parseInt(attribute.Values[0]);
                            break;
                        case 'Reference Id':
                            externalId = attribute ? attribute.Values[0] : '';
                            break;
                        // case 'United Id':
                        //     externalId2 = attribute ? attribute.Values[0] : '';
                        //     break;
                        // case 'Mi9v8 Id':
                        //     externalId3 = attribute ? attribute.Values[0] : '';
                        //     break;
                        case 'Type':
                            typeId = attribute ? attribute.Values[0] : '';
                            break;
                        default:
                            break;
                    }
                }
            });

            store.CS_BannerID = bannerId;
            store.CS_CityAreaID = cityAreaId;
            store.CS_RegionID = regionId;
            store.ExternalID = externalId;
            store.ExternalID2 = externalId2;
            store.ExternalID3 = externalId3;
            store.TypeId = typeId;
            // cheat for Mi9v8 Store
            if (!store.ExternalId3) {
                store.ExternalID3 = store.StoreID + '';
            }
        }


        return store;
    }

    convertDXPStoresToStores(dxpStores){
        let o: Observable<any>;
        if (this.systemServiceIndex) {
            o = of(true);
        } else {
            o =  this.getStoreSystemServices();
        }
        return o.pipe(
            map((systemServices)=>{
                const stores = [];
                _.each(dxpStores, (dxpStore) => {
                    const store = this.convertDXPStoreToStore(dxpStore);
                    store.SystemService = _.orderBy(store.SystemService, ['Priority']);
                    stores.push(store);

                });
                return stores;
            })
        );
    }

    convertDXPServicesToServices(dxpServices) {
        if (dxpServices == null) {
            return null;
        }

        const services = [];
        _.each(dxpServices, (dxpService) => {
            const service = {
                CS_ServicesID: dxpService.ServiceId,
                ServiceName: dxpService.ServiceName,
                DisplayName: dxpService.DisplayName,
                Priority: dxpService.DisplayOrder,
                ServiceGroup: dxpService.Type,
                IsActive: dxpService.IsActive,
                SettingJson: dxpService.SettingJson
            };

            services.push(service);
        });

        return services;
    }

    convertDXPInstoreServicesToServices(dxpInstoreServices) {
        if (dxpInstoreServices == null) {
            return null;
        }

        const services = [];
        _.each(dxpInstoreServices, (dxpInstoreService) => {
            const service = {
                CS_ServicesID: dxpInstoreService.Id,
                ServiceName: dxpInstoreService.ServiceName,
                Priority: dxpInstoreService.Priority,
                ServiceGroup: dxpInstoreService.ServiceGroup
            };

            services.push(service);
        });

        return services;
    }

    convertDXPCityAreasToCityAreas(dxpCityAreas) {
        if (dxpCityAreas == null) {
            return null;
        }

        const cityAreas = [];
        _.each(dxpCityAreas, (dxpCityArea) => {
            const cityArea = {
                CS_CityAreaID: dxpCityArea.Id,
                CityAreaName: dxpCityArea.CityAreaName,
                Priority: dxpCityArea.Priority,
                IsDeleted: dxpCityArea.IsDeleted
            };

            cityAreas.push(cityArea);
        });

        return cityAreas;
    }

}
