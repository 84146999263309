import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { ENV } from '@app/env';
import { Storage } from '@ionic/storage';
@Injectable({
  providedIn: 'root'
})
export class CheckTutorial implements CanLoad {
  deviceMode = 'mobile';

  constructor(
    private storage: Storage,
    private router: Router) {
    this.deviceMode = ENV.DeviceMode;
  }
  canLoad() {
    this.deviceMode = ENV.DeviceMode;
    if (this.deviceMode === 'Web') {
      return true;
    }
    return this.storage.get('ion_did_tutorial9').then(res => {
      if (!res) {
        this.router.navigate(['/tutorial']);
        return false;
      } else {
        return true;
      }
    });
  }
}
