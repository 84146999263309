import {Injectable} from '@angular/core';
import {HttpClient, HttpRequest, HttpHeaders, HttpParams, HttpHandler } from '@angular/common/http';
import {Observable, of } from 'rxjs';

import { ENV } from '@app/env';
import { Credential } from '@rsApp/modules/auth/providers/credential.service';

export type HttpObserve = 'body' | 'events' | 'response';

@Injectable({
  providedIn: 'root'
})
export class RelationshopEComCoreHttpClient extends HttpClient {
	public api = ENV.EComCoreHost;
  constructor(handler: HttpHandler, private cre: Credential) {
       super(handler);
  }
	request(first: string|HttpRequest<any>, url?: string, options: {
    body?: any,
    headers?: HttpHeaders|{[header: string]: string | string[]},
    observe?: HttpObserve,
    params?: HttpParams|{[param: string]: string | string[]},
    reportProgress?: boolean,
    responseType?: 'arraybuffer'|'blob'|'json'|'text',
    withCredentials?: boolean,
  } = {}): Observable<any> {

	if (!options) {
		options = {};
	}
 url = this.api + url;

    // token
	let headers: HttpHeaders|undefined;
 if (options.headers instanceof HttpHeaders) {
        headers = options.headers;
    } else {
        headers = new HttpHeaders(options.headers);
    }
 headers = headers.set('Content-Type', 'application/json');
 headers = headers.set('Authorization', `Bearer ${this.cre.rsApiToken}` || '');
 headers = headers.set('X-MCMAccessToken', this.cre.rsApiToken || '');

 options.headers = headers;
 const req = super.request(first as string, url, options as any);
 return req;
  }
}
export const RelationshopEComCoreHttpClientFactory = function(handler: HttpHandler, cre: Credential, apiURL: string){
  const client = new RelationshopEComCoreHttpClient(handler, cre);
  client.api = apiURL;
  return client;
}