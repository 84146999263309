import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { StoreServiceModule, Store } from './providers/store.service';
import { UserServiceModule } from '@rsApp/modules/account/provider/user.service';
import { NearStorePage } from './pages/near-store/near-store';
import { StoreContextPage } from './pages/store-context/store-context';
import { FindStorePage } from './pages/find-store/find-store';
import { StoreInfoPage } from './pages/info/info';
import { DirectionsMapPage } from './pages/direction/direction';
export { Store } from './providers/store.service';
export { CurrentStore } from './providers/current-store.service';

import { UtilsModule } from '@rsApp/modules/utils/utils.module';
import { ResultFindStorePage } from './pages/result-find-store/result-find-store';
import { StoreList } from './components/store-list/store-list';
import { StoreMap } from './components/store-map/store-map';


const routes: Routes = [
	{
		path: 'find',
		component: FindStorePage
	},
	{
		path: 'near',
		component: NearStorePage
	},
	/*
	{
		path: 'near',
		component: StoreContextPage
	},
	*/
	{
		path: 'context',
		component: StoreContextPage
	},
	{
		path: 'info',
		component: StoreInfoPage
	},
	{
		path: 'direction',
		component: DirectionsMapPage
	},
	{
		path: 'result-find',
		component: ResultFindStorePage
	}
];

@NgModule({
	imports: [
		RouterModule.forChild(routes),
		CommonModule,
		IonicModule,
		FormsModule,
		UserServiceModule,
		StoreServiceModule,
		UtilsModule,
	],
	declarations: [
		FindStorePage,
		NearStorePage,
		StoreContextPage,
		StoreInfoPage,
		DirectionsMapPage,
		ResultFindStorePage,
		StoreList,
		StoreMap
	]
})
export class StoreModule {

}