import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ShoppingListIconModule} from '../shopping-list/shopping-list-icon.module';
import { ShoppingCartIconModule } from '../shopping-cart/shopping-cart-icon.module'
import { CartListIcon} from './components/cart-list-icon/cart-list-icon';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    IonicModule,
   	ShoppingListIconModule,
   	ShoppingCartIconModule
  ],
  entryComponents: [CartListIcon],
  declarations: [CartListIcon],
  exports: [CartListIcon]
})
export class CartListIconModule {}
