import { Component, Input, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GoogleMap, GoogleMapOptions, GoogleMaps, HtmlInfoWindow, Marker, GoogleMapsEvent } from '@ionic-native/google-maps';
import { CacheService } from 'ionic-cache';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';
import _ from 'lodash';

import { ENV } from '@app/env';
import { BaseStore } from '../../base-store';
import { Utils } from '@rsApp/modules/utils/providers/utils';
import { AuthService } from '@rsApp/modules/auth/providers/auth.service';
import { CurrentStore } from '@rsApp/modules/store/providers/current-store.service';
import { Store } from '@rsApp/modules/store/providers/store.service';
import { Credential } from '@rsApp/modules/auth/providers/credential.service';
import { enumStoreId } from '@rsApp/core/enum';
import { PermanentSettings } from '@rsApp/modules/utils/providers/permanent-settings';
import { ShoppingCartGatewayService } from '@rsApp/modules/shopping-cart/providers/shopping-cart-gateway.service';
@Component({
  selector: 'store-map',
  templateUrl: 'store-map.html',
  styleUrls: ['./store-map.scss']
})

export class StoreMap extends BaseStore {
  @Input() listStore: any;
  @Input() showCurrentStore: boolean;

  map: GoogleMap;
  subscribes = [];
  markers = [];
  constructor(
    public utils: Utils,
    public auth: AuthService,
    public cStore: CurrentStore,
    public router: Router,
    public route: ActivatedRoute,
    public cache: CacheService,
    public store: Store,
    public cri: Credential,
    public events: NgxPubSubService,
    public pSettings: PermanentSettings,
    public shoppingCartService: ShoppingCartGatewayService
  ) {
    super(utils, cStore, router, cache, store, cri, events, pSettings, shoppingCartService);
  }

  async ngOnChanges(changes: SimpleChanges) {
    await super.getCurrent();
    let stores = this.listStore;
    this.loadMap(stores);
  }

  async loadMap(stores) {
    // if (!stores) return;
    if (!stores) { stores = []; }
    // Create a map after the view is ready and the native platform is ready.
    if (this.showCurrentStore && this.currentStore) {
      //filter to remove current store in list
      stores = _.filter(stores, (store) => { return store.CS_StoreID != this.currentStore.CS_StoreID });
      // and unshift current store to list
      stores.unshift(Object.assign({}, this.currentStore));
    }
    let sumlat = 0, sumlng = 0, length = stores.length || 1; // for exclude case divide 0
    stores.forEach(store => {
      sumlat += store.Latitude;
      sumlng += store.Longitude;
    });

    let mapOptions: GoogleMapOptions = {
      camera: {
        target: {
          lat: sumlat / length,
          lng: sumlng / length
        },
        zoom: ENV.MapZoom,
        tilt: 30,
      },
      center: {
        lat: sumlat / length,
        lng: sumlng / length,
      },
    };
    if (!this.map) {
      this.map = GoogleMaps.create('map_canvas', mapOptions);
      this.map.one(GoogleMapsEvent.MAP_READY).then(async () => {
        await this.clearMarkers();
        this.loadStoreMarker(stores);
      })
    }
    else {
      await this.clearMarkers();
      this.loadStoreMarker(stores);
    }


  }

  loadStoreMarker(stores) {
    stores.forEach(store => {
      let htmlInfoWindow = new HtmlInfoWindow();
      let frame: HTMLElement = document.createElement('div');
      let cityStateZipcode = `${this.titleCase(store.City)} ${store.State} ${store.Zipcode}`;
      let storeHours = '';
      let arrStoreHours = store.StoreHours && store.StoreHours.split(';');
      let btnChooseStore = '';
      if (arrStoreHours) {
        arrStoreHours.forEach(hours => {
          storeHours += '<div>' + hours + '</div><br>';
        });
      }
      else {
        storeHours = '<div>' + store.StoreHours + '</div><br>';
      }

      if (store.CS_BannerID == enumStoreId.UnitedExpress || (this.currentStore && store.CS_StoreID == this.currentStore.CS_StoreID)) {
        btnChooseStore = '';
      }
      else {
        /*
        let btnChooseOnline = '';
        if (store.ShopOnline) {
          btnChooseOnline = '<span class="choose-online" style="color:#6ABF4B;"><a>Online</a></span> &nbsp;';
        }
        btnChooseStore = btnChooseOnline + '<span class="choose-instore" style="color:#FF671D;"><a>In-Store</a></span>';
        */
        btnChooseStore = '<span class="view-details" style="color:#6ABF4B;"><a>View Details</a></span> &nbsp;'
      }

      frame.innerHTML = [
        '<div style="width: 168px;">',
        '<span><img style="max-width: 80%;" src="' + store.Logo + '"></span>',
        '<span class="info-close" style="position: absolute;right: 5px;top: 5px;">x</span>',
        '<div><b>' + store.StoreName + '</b></div>',
        '<div>' + this.titleCase(store.Address1) + '</div>',
        '<div>' + cityStateZipcode + '</div>',
        '<div><b>Store Hours:</b></div>',
        storeHours,
        btnChooseStore,
        '</div>'
      ].join("");

      frame.getElementsByClassName("info-close")[0].addEventListener("click", () => {
        htmlInfoWindow.close();
      });
      frame.getElementsByClassName("choose-instore")[0] && frame.getElementsByClassName("choose-instore")[0].addEventListener("click", () => {
        super.selectStore(store, "instore");
      });
      frame.getElementsByClassName("choose-online")[0] && frame.getElementsByClassName("choose-online")[0].addEventListener("click", () => {
        super.selectStore(store, 'Online');
      });

      frame.getElementsByClassName("view-details")[0] && frame.getElementsByClassName("view-details")[0].addEventListener("click", () => {
        this.viewInfo(store.CS_StoreID);
      });

      htmlInfoWindow.setContent(frame);

      let marker: Marker = this.map.addMarkerSync({
        icon: {
          url: store.StoreIconURL || '',
          size: {
            width: 27,
            height: 40
          }
        },
        infoWindowAnchor: [13, 0],
        position: {
          lat: store.Latitude,
          lng: store.Longitude
        },
        zIndex: store.CS_BannerID == enumStoreId.UnitedExpress ? 1 : 2
      });
      marker.on(GoogleMapsEvent.MARKER_CLICK).subscribe(() => {
        htmlInfoWindow.open(marker);
      });

      this.markers.push(marker);
    });
  }

  titleCase(str) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
  }
  async clearMarkers() {
    _.forEach(this.markers, marker => {
      if (marker && marker.setMap) {
        marker.setMap(null);
      }
      marker.remove();
    })
    this.markers = [];
  }
  ngOnDestroy() {
    if (!this.subscribes) {
      return;
    }
    _.forEach(this.subscribes, (s) => {
      if (s && s.unsubscribe) {
        s.unsubscribe();
      }
    })
  }
}
