import {Injectable} from '@angular/core';
import {HttpClient, HttpRequest, HttpHeaders, HttpParams, HttpHandler } from '@angular/common/http';
import {Observable, of } from 'rxjs';

import { ENV } from '@app/env';
import { AuthService } from '@rsApp/modules/auth/providers/auth.service';
import { Credential } from '@rsApp/modules/auth/providers/credential.service';
import { RelationshopEComHttpClient} from './rs-ecom-api.service';


// import {RelationshopHttpClient} from './rs-api.service';
export type HttpObserve = 'body' | 'events' | 'response';
@Injectable({
  providedIn: 'root'
})
// export class RelationshopEComHttpClient extends RelationshopHttpClient{
// 	protected api = ENV.EComHost;
// }
export class RelationshopOnlineEComHttpClient extends RelationshopEComHttpClient{
	protected shopPath = 'Online';
}