import { Injectable } from "@angular/core";

import { AsyncSubject, BehaviorSubject } from "rxjs";
import { IStoreCustomService } from "@rsApp/modules/interfaces/store.interface";


export const LOCAL_STORE_DETAILS_KEY = "LocalStoreDetails";
export const VIEW_STORE_DETAILS_KEY = "ViewStoreDetails";

@Injectable({ providedIn: 'root' })
export class CurrentCustomService {
  private readonly _urlSlugSystemService = new BehaviorSubject<any>(-1);
  private readonly _systemServiceId = new BehaviorSubject<any>(-1);
  readonly _systemServiceId$ = this._systemServiceId.asObservable();
  private readonly _systemService = new BehaviorSubject<IStoreCustomService>(null);
  readonly _systemService$ = this._systemService.asObservable();
  public shoppingBagGateway$: AsyncSubject<any>;
  constructor() { 
  }
  get systemServiceId() {
    return this._systemServiceId.getValue();
  }
  set systemServiceId(value: number) {
    this._systemServiceId.next(value);
  }
  get systemService(): IStoreCustomService {
    return this._systemService.getValue();
  }
  set systemService(value: IStoreCustomService) {
    this._systemService.next(value);
  }
  get urlSlugSystemService() {
    return this._urlSlugSystemService.getValue();
  }
  set urlSlugSystemService(value: string) {
    this._urlSlugSystemService.next(value);
  }
  setSystemService(value: number) {
    this.systemServiceId = value;
  }
  getSystemService() {
    return this.systemServiceId;
  }
}

