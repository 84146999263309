import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { Observable, Subscription, interval } from 'rxjs';
import { Router } from '@angular/router';
import { CurrentStore } from '@rsApp/modules/store/store.module';
import { Credential } from '@rsApp/modules/auth/providers/credential.service';
import { ModalController } from '@ionic/angular';
import { Utils } from '@rsApp/modules/utils/providers/utils';
import { AppSettings } from '@rsApp/modules/utils/providers/app-setting';
import { enumCheckoutMsgSetting } from '@rsApp/core/enum';
// import { PurchasesService } from '@rsApp/modules/purchase/purchase-services.module';
import { PurchaseGatewayService as PurchasesService } from '@rsApp/modules/purchase/purchase-services.module';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';
import { ChangeOrderModalComponent } from '@rsApp/modules/purchase/components/change-order-modal/change-order-modal.component';

@Component({
	selector: 'count-down-change-order',
	templateUrl: 'count-down-change-order.html',
	styleUrls: ['./count-down-change-order.scss']
})
export class CountDownChangeOrder implements OnInit, OnDestroy {
	currentUser: any = {};
	currentStore: any;
	changeOrderId: any = 0;

	countDownClock: any = 0;
	hours: any = 0;
	minutes: any = 0;
	seconds: any = 0;

	subscriptions: Subscription[] = [];
	intervalCount: Subscription;
	constructor(
		private router: Router,
		public cre: Credential,
		public cStore: CurrentStore,
		public modalCtrl: ModalController,
		public utils: Utils,
		public appSettings: AppSettings,
		public purchase: PurchasesService,
		private events: NgxPubSubService,
		public zone: NgZone
	) {

	}
	public ngOnInit() {
		this.loadData();
	}
	public async cleanUpData() {
		// clearInterval(this.intervalCount);
		if (this.intervalCount) {
			this.intervalCount.unsubscribe();
		}
		this.intervalCount = null;
		this.changeOrderId = 0;
		this.countDownClock = 0;
	}
	async loadData() {
		this.currentStore = await this.cStore.getStore();
		this.currentUser = this.cre.currentUser;
		const obsEvent = this.events.subscribe('emmit-change-order', async (params) => {
			var { cleanUpData = false } = params || {};
			await this.cleanUpData();
			if (!cleanUpData) {
				setTimeout(() => {
					this.loadChangedOrder();
				}, 500)
			}
		});
		this.subscriptions.push(obsEvent);
		this.events.publishEvent('emmit-change-order');
	}

	async loadChangedOrder() {
		this.currentStore = await this.cStore.getStore();
		this.currentUser = this.cre.currentUser;
		if (this.currentUser && this.currentUser.ExternalCustomerCardID && this.currentStore && this.currentStore.ExternalID != null) {
			const self = this;
			// let obs = this.purchase.getChangedOrder(this.currentUser, this.currentStore).subscribe((result: any) => {
			// 	console.log(result);
			// 	if (result && result.ChangeOrderId !== 0) {
			// 		this.changeOrderId = result.ChangeOrderId;
			// 		this.countDownClock = result.RemainingTimeSeconds;

			// 		let countDownDate = result.RemainingTimeSeconds;
			// 		// use interval rxjs & ngZone for case launch fallback app from background
			// 		self.intervalCount = interval(1000).subscribe(val => {
			// 			this.zone.run(() => {
			// 				const timeRemaining = countDownDate--;
			// 				if (countDownDate === -1) {
			// 					self.cleanUpData();
			// 				}
			// 				const hours = Math.floor(timeRemaining / 3600);
			// 				const minutes = Math.floor(timeRemaining % 3600 / 60);
			// 				const seconds = Math.floor(timeRemaining % 60);

			// 				self.hours = hours;
			// 				self.minutes = minutes;
			// 				self.seconds = seconds;
			// 			});
			// 		});
			// 	} else {
			// 		this.changeOrderId = 0;
			// 		this.countDownClock = 0;
			// 	}
			// 	obs.unsubscribe();

			// });
		}
	}

	async questionMark() {
		await this.utils.showLoading();
		const msg = await this.appSettings.getEcomSettingValue(enumCheckoutMsgSetting.CheckoutChangeOrder, this.currentStore.ExternalID).toPromise();
		const m = await this.modalCtrl.create({
			component: ChangeOrderModalComponent,
			cssClass: 'app-change-order-modal',
			componentProps: {
				msg
			}
		});
		await m.present();
		await this.utils.hideLoading();
	}

	public ngOnDestroy() {
		this.cleanUpData();
		this.subscriptions.forEach((s) => {
			s.unsubscribe();
		});
	}
}
