import { Injectable } from '@angular/core';

import {AuthService} from '@rsApp/modules/auth/providers/auth.service';

@Injectable()
export class AppLoadService {
	constructor(public auth: AuthService){}
	getToken():Promise<any>{
		let r = new Promise((resolve, reject)=>{
			this.auth.authState().subscribe((res)=>{
				resolve();
			});
		});
		return r;
	}
}