export enum enumCouponsType{
    MFGCoupon = 1,
    MFGOffer = 2,
    MFGReward = 3,
    DigitalCoupon = "DigitalCoupon"
}

export enum enumBalanceType{
    Points = 5,
    PointsExpiring = 0,
    Rewards = 8,
    RewardsExpiring = 1008

}

export enum enumProductType{
    AdItems = "AdItems",
    EcomProduct = "EcomProduct"
}

export enum enumDisplayModeCustomService {
    Collections = 'Collections',
    Products = 'Products'
}
export enum enumStatusRespone {
    Success = 'Success',
    Ok = 'Ok',
    Error = 'Error',
}
export enum enumTrackAction {
    //Ecommerce
    SearchItem = 'tracking::SearchItem',
    PurchaseOrder = 'tracking::PurchaseOrder',
    CategoryView = 'tracking::CategoryView',
    ProductView = 'tracking::ProductView',
    AddEcommerceItem = 'tracking::AddEcommerceItem',
    TrackEcommerceShoppingCart = 'tracking::TrackEcommerceShoppingCart',
    RemoveEcommerceItem = 'tracking::RemoveEcommerceItem',
    ClearEcommerceCart = 'tracking::ClearEcommerceCart',
    TrackEcommerceCartUpdate = 'tracking::TrackEcommerceCartUpdate',
    //Online Order
    OnChangeSystemService = 'tracking::OnChangeSystemService',
    TrackOnlineOrderShoppingBag = 'tracking::TrackOnlineOrderShoppingBag',
    AddOnlineOrderItems = 'tracking::AddOnlineOrderItems',
    EventAddOnlineOrderItem = 'tracking::EventAddOnlineOrderItem',
    ClearOnlineOrderCart = 'tracking::ClearOnlineOrderCart',
    RemoveOnlineOrderItem = 'tracking::RemoveOnlineOrderItem',
    TrackOnlineOrderCartUpdate = 'tracking::TrackOnlineOrderCartUpdate',
    OnlineOrderProductView = 'tracking::OnlineOrderProductView',
    PurchaseOnlineOrder = 'tracking::PurchaseOnlineOrder',
    SetCustomDimentionServiceType = 'tracking::SetCustomDimentionServiceType',
    DeleteCustomDimension = 'tracking::DeleteCustomDimension',
    SaveToken = 'tracking::SaveToken',
    TrackUserProperty = 'tracking::TrackUserProperty',
    TrackGoal = 'tracking::TrackGoal',
    AddPaymentInfo = 'traccking::AddPaymentInfo',
    SearchProduct = 'tracking::ProductSearch',
    InitCheckOut = 'tracking::InitCheckout',

    //Shop List
    ViewShoppingList = 'tracking::ViewShoppingList',
    ShopListGrandTotal = 'tracking::ShopList',
    AddItemShoppingList = 'tracking::AddItemShoppingList',
    CheckOffItems = 'tracking::CheckOffItems',
    EmptyList = 'tracking::EmptyList',
    RemoveList = 'tracking::RemoveList',
    PrintList = 'tracking::PrintList',
    ShareList = 'tracking::ShareList',
    ListUpdate = 'tracking::ListUpdate',
}
export enum enumWidgetCode {
    homepagetop = "Mobile_HomePage_Top",
    homepagebottom = "Mobile_HomePage_Bottom",
}

export enum AccountType {
    Personal = "Personal",
    Business = "Business"
}

export enum Segment{
    NonProfit = 6,
    BusinessChecks = 7
}


export enum enumExpirationType {
    AbsoluteExpire = 1,
    AdaptiveExpire = 2,
    QuantityLimit
}

export enum ActionType {
    AddToCart = 'addToCart',
    AddToList = 'addToList',
    ViewDetail = 'viewDetail',
    ClipOffer = 'clipOffer',
    ChangeCart = 'ChangeCart',
    ChangeQuantity = 'ChangeQuantity',
    ReloadShoppingBag = 'ReloadShoppingBag',
    DoClipOffer = 'DoClipOffer',
    DoChangeQuantity = 'DoChangeQuantity',
    DoViewDetail = 'DoViewDetail',
    DoViewMore = 'DoViewMore',
    DoNavTab = 'DoNavTab',
    EmitList = 'EmitList',
    EmitCart = 'EmitCart',
    ReloadDataMyBigY = 'ReloadDataMyBigY',
    OnDxpComponentChangeShoppingList = 'OnDxpComponentChangeShoppingList',
    OnDxpComponentChangeShoppingCart = 'OnDxpComponentChangeShoppingCart',
    OnDxpComponentShowGuestMessage = 'OnDxpComponentShowGuestMessage',
    OnChangeShoppingList = 'OnChangeShoppingList',
    OnChangeShoppingCart = 'OnChangeShoppingCart',
    LoadShoppingCart = 'LoadShoppingCart',
    OnDxpComponentAddedCoupon = 'OnDxpComponentAddedCoupon',
    OnDxpComponentAddedOffer = 'OnDxpComponentAddedOffer',
    ChangeMenu = 'ChangeMenu',
    OpenHealthApp = 'HealthApp_Open',
    BackHealthApp = 'HealthApp_Back',
    OpenHealthWeb = 'HealthWeb_Open',
    BackHealthWeb = 'HealthWeb_Back',
    OpenPharmacyApp = 'PharmacyApp_Open',
    BackPharmacyApp = 'PharmacyApp_Back',
    OpenPharmacyWeb = 'PharmacyWeb_Open',
    BackPharmacyWeb = 'PharmacyWeb_Back',
    DoCloseModal = 'DoCloseModal',
    DoCloseAllModal = 'DoCloseAllModal',
    DisplayCart = 'ShowCartIcon',
    ShowFilterGS = "ShowFilterGS",
    ShowPopUp = "ShowPopUp",
    GoReward = "goReward"
}

export enum WeeklyAdStore {
    ACTIVE_PAGE_PRODUCT_VIEW = "ACTIVE_PAGE_PRODUCT_VIEW",
    ACTIVE_PAGE_OFFERCODE_PRODUCT_VIEW = "ACTIVE_PAGE_OFFERCODE_PRODUCT_VIEW",
    ACTIVE_PAGE_FLYER_VIEW = "ACTIVE_PAGE_FLYER_VIEW",
}

export enum enumTrackingProduct {
    ViewProductList = "ViewProductList",
    SelectProduct = "SelectProduct",
    ViewProduct = "ViewProduct",
    SearchItem = "SearchItem",
}

export enum enumTrackingEventChange {
    ChooseStore = "ChooseStore",
    ChooseContext = "ChooseContext",
    ChangeAddress = "ChangeAddress",
    ChangeContact = "ChangeContact",
    ChooseTimeslot = "ChooseTimeslot",
}

export enum enumTrackingShoppingCart {
    ViewCart = "ViewCart",
    AddToCart = "AddToCart",
    RemoveFromCart = "RemoveFromCart",
    CartUpdate = "CartUpdate",
    AllowsSubstitutions = "AllowsSubstitutions",
    EmptyCart = "EmptyCart",
}

export enum enumTrackingShoppingList {
    ViewList = "ViewList",
    AddItem = "AddItem",
    CheckOffItems = "CheckOffItems",
    AddToList = "AddToList",
    RemoveFromList = "RemoveFromList",
    ListUpdate = "ListUpdate",
    EmptyList = "EmptyList",
    RemoveList = "RemoveList",
    ShareList = "ShareList",
    PrintList = "PrintList",
    ShopList = "ShopList" // add item from list to cart
}

export enum enumTrackingCheckout {
    BeginCheckout = "BeginCheckout",
    CreateOrder = "CreateOrder",
    SelectBags = "SelectBags",
    RedeemItem = "RedeemItem",
    ApplyCode = "ApplyCode",
    AddNote = "AddNote",
    AddPaymentInfo = "AddPaymentInfo",
    SelectPromotion = "SelectPromotion",
    ViewPromotion = "ViewPromotion",
    AllowsSubstitutions = "AllowsSubstitutions"
}

export const TRACKING_ACTION_NAME: { [key: string]: string } = {
    // Action Name Track Product
    [enumTrackingProduct.ViewProductList]: 'ProductListID',
    [enumTrackingProduct.SelectProduct]: 'ProductUPC',  // Metadata: Product Name, Price, Itemsize, Brand, Category
    [enumTrackingProduct.ViewProduct]: 'ProductPrice',  // Metadata: Product Name, UPC, Itemsize, Brand, Category
    [enumTrackingProduct.SearchItem]: 'Keyword',

    // Action Name Track Change, Metadata : previous data
    [enumTrackingEventChange.ChooseStore]: 'StoreId',
    [enumTrackingEventChange.ChooseContext]: 'ShopPath',
    [enumTrackingEventChange.ChangeAddress]: 'Address',
    [enumTrackingEventChange.ChangeContact]: 'PhoneNumber',
    [enumTrackingEventChange.ChooseTimeslot]: 'Rangeslot',

    // Action Name Track Shopping List
    [enumTrackingShoppingList.ViewList]: 'ListID',
    [enumTrackingShoppingList.AddToList]: 'ProductUPC', // Metadata trackingProduct + ProductQuantity(ex: 1)
    [enumTrackingShoppingList.RemoveFromList]: 'ProductUPC', // Metadata trackingProduct + ProductQuantity(ex: 1)
    [enumTrackingShoppingList.ListUpdate]: 'ItemIDs', // string productIDs
    [enumTrackingShoppingList.EmptyList]: 'ItemIDs', // string productIDs
    [enumTrackingShoppingList.RemoveList]: 'ListID',
    [enumTrackingShoppingList.ShareList]: 'ListID',
    [enumTrackingShoppingList.PrintList]: 'ListID',
    [enumTrackingShoppingList.ShopList]: 'GrandTotal', // Metadata (JSON string): trackingShoppingCart
    [enumTrackingShoppingList.AddItem]: 'ItemID', // Metadata (JSON string): trackingShoppingCart
    [enumTrackingShoppingList.CheckOffItems]: 'ItemIDs', // Metadata (JSON string): trackingShoppingCart

    // Action Name Track Flow Checkout
    [enumTrackingCheckout.BeginCheckout]: 'GrandTotal', // Metadata (JSON string): trackingShoppingCart
    [enumTrackingCheckout.CreateOrder]: 'OrderId',
    // [enumTrackingCheckout.SelectBags]: 'UsableBags', // true | false // TRACKING IN ADD NOTE
    [enumTrackingCheckout.RedeemItem]: 'ProductUPC', // Metadata trackingProduct + ProductQuantity(ex: 1)
    [enumTrackingCheckout.ApplyCode]: 'PromoCode',
    [enumTrackingCheckout.AddNote]: 'OrderNote',
    [enumTrackingCheckout.AddPaymentInfo]: 'PaymentType', // ex: Credit Card (Metadata CardNumber)
    // [enumTrackingCheckout.SelectPromotion]: 'PromotionCode', // USE ViewPromotion
    [enumTrackingCheckout.ViewPromotion]: 'PromotionCode',
    [enumTrackingCheckout.AllowsSubstitutions]: 'IsAllowedSubstitutions', // true | false
}