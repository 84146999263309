import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TabsPage } from './tabs.page';
import { AuthGuardService as AuthGuard } from '@rsApp/modules/auth/providers/auth-guard.service';
import { GuestGuardService as GuestGuard } from '@rsApp/modules/auth/providers/guest-guard.service';
import { StoreGuardService as StoreGuard }from '@rsApp/modules/auth/providers/store-guard.service';
import { ShowLoginGuardService as ShowLoginGuard }from '@rsApp/modules/auth/providers/showlogin-guard.service';
import { FirstShowStoreGuardService } from '@rsApp/modules/auth/providers/first-show-store-guard.service';
import { ForceChangePasswordGuardService as ForceChangePasswordGuard } from '@rsApp/modules/auth/providers/force-change-password-guard.service';
import { CheckTutorial } from '../tutorial/providers/check-tutorial.service';
import { IsOutDateGuardService as IsOutDateGuard} from '@rsApp/modules/auth/providers/isOutDate-guard.service';


const routes: Routes = [
  {
    path: 'tabs',
    component: TabsPage,
    children: [
      {
        path: 'home',
        children: [
          {
            path: '',
            loadChildren: '../home/home.module#HomeModule',
            canActivate: [ShowLoginGuard/*, FirstShowStoreGuardService*/, StoreGuard],
            canLoad:[CheckTutorial]
          }
        ]
      },
      // { path: 'receipt', loadChildren: '../receipt/receipt.module#ReceiptModule', canActivate: [AuthGuard], data: { requiredLogin: true } },
      { path: 'shopping-lists', loadChildren: '../shopping-list/shopping-list.module#ShoppingListModule', canActivate: [AuthGuard], data: { requiredLogin: true } },
      { path: 'shopping-cart', loadChildren: '../shopping-cart/shopping-cart.module#ShoppingCartModule', canActivate: [AuthGuard], data: { requiredLogin: true } },
      { path: 'more', loadChildren: '../more/more.module#MoreModule', canActivate: [ForceChangePasswordGuard] },
      { path: 'purchase', loadChildren: '../purchase/purchase-routing.module#PurchaseRoutingModule', canActivate: [StoreGuard], data: { requiredLogin: true } },
      { path: 'recipe', loadChildren: '../recipe/recipe.module#RecipeModule', canActivate: [AuthGuard], data: { requiredLogin: true } },
      { path: 'punchcard', loadChildren: '../punchcard/punchcard.module#PunchcardModule', canActivate: [AuthGuard], data: { requiredLogin: true } },
      { path: 'products', loadChildren: '../product/product-routing.module#ProductRoutingModule', canActivate: [StoreGuard] },
      { path: 'coupons', loadChildren: '../reward/reward-routing.module#RewardRoutingModule', canActivate: [AuthGuard], data: { requiredLogin: true } },
      { path: 'weekly-ad', loadChildren: '../weekly-ad/weekly-ad-routing.module#WeeklyAdRoutingModule', canActivate: [StoreGuard] },
      // { path: 'welcome', loadChildren: '../welcome/welcome.module#WelcomeModule', canActivate: [GuestGuard] },
      // { path: 'dashboard', loadChildren: '../dashboard/dashboard-routing.module#DashboardRoutingModule', canActivate: [AuthGuard]},
      { path: 'deals', loadChildren: '../deals/deals.router.module#DealsPageRoutingModule', canActivate: [AuthGuard], data: { requiredLogin: true } },
      // { path: 'reward', loadChildren: '../reward/reward-routing.module#RewardRoutingModule', canActivate: [AuthGuard], data: { requiredLogin: true } },
      { path: 'rewards', loadChildren: '../dxp-rewards/dxp-rewards-routing.module#DXPRewardRoutingModule', canActivate: [AuthGuard], data: { requiredLogin: true } },
      { path: 'mybigy', loadChildren: '../myBigy/myBigy.module#MyBigyModule', canActivate: [AuthGuard], data: { requiredLogin: true } },
      { path: 'gas', loadChildren: '../gas/gas.module#GasModule', canActivate: [StoreGuard], data: { requiredLogin: true } },

    ],
    canActivate: [ForceChangePasswordGuard, IsOutDateGuard]
  },
  {
    path: '',
    redirectTo: '/tabs/home',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule],
  declarations: []
})
export class TabsPageRoutingModule { }
