import { Component, Input, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute, } from '@angular/router';
import { CacheService } from 'ionic-cache';

import { Utils } from '@rsApp/modules/utils/providers/utils';
import { AuthService } from '@rsApp/modules/auth/providers/auth.service';
import { CurrentStore } from '@rsApp/modules/store/providers/current-store.service';
import { Store } from '@rsApp/modules/store/providers/store.service';
import { Credential } from '@rsApp/modules/auth/providers/credential.service';
import { BaseStore } from '../../base-store';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';
import { PermanentSettings } from '@rsApp/modules/utils/providers/permanent-settings';
import _ from 'lodash';
import { ENV } from '@app/env';
import { ShoppingCartGatewayService } from '@rsApp/modules/shopping-cart/providers/shopping-cart-gateway.service';

@Component({
  selector: 'store-list',
  templateUrl: 'store-list.html',
  styleUrls: ['./store-list.scss']
})
export class StoreList extends BaseStore {
  @Input() listStore: any;
  @Input() showCurrentStore: boolean;
  @Input() noMsg = 'No results found for your search';

  btnFillInstore = 'outline';
  btnFillOnline = 'outline';
  btnColorInstore = 'gray';
  btnColorOnline = 'gray';
  textOnline = 'Shop Online';
  textInstore = 'Shop In Store';
  iconOnline = false;
  iconInstore = false;
  OnlineOrderSvcId: any;

  constructor(
    public utils: Utils,
    public auth: AuthService,
    public cStore: CurrentStore,
    public router: Router,
    public route: ActivatedRoute,
    public cache: CacheService,
    public store: Store,
    public cri: Credential,
    public events: NgxPubSubService,
    public pSettings: PermanentSettings,
    public shoppingCartService: ShoppingCartGatewayService,
  ) {
    super(utils, cStore, router, cache, store, cri, events, pSettings, shoppingCartService);
    this.OnlineOrderSvcId = ENV.OnlineOrderSvcId;
  }

  // tslint:disable-next-line:use-lifecycle-interface
  async ngOnChanges(changes: SimpleChanges) {
    await super.getCurrent();
    this.initCurrentStore();
  }

  async initCurrentStore() {
    /*
    let storePath = null;
    if (this.currentUser && this.currentUser.ShopPath) {
      storePath = this.currentUser.ShopPath.toLowerCase();
    }
    */
    let storePath = null;
    if (this.currentStore && this.currentStore.ShopPath) {
      storePath = this.currentStore.ShopPath.toLowerCase();
    }

    if (this.currentStore && this.currentStore.SystemService) {
      _.each(this.currentStore.SystemService, (systemService) => {
        if (systemService.ServiceSettings && this.currentStore.ShopPath === systemService.ServiceSettings.ShopPath) {
          this.currentStore.ShopPathIconUrl = systemService.ServiceSettings.IconUrl;
        }
      });
    }


    if (storePath === 'online') {
      this.btnFillOnline = 'solid';
      this.btnFillInstore = 'outline';
      this.btnColorInstore = 'gray';
      this.btnColorOnline = 'tertiary';
      this.textOnline = 'Online';
      this.textInstore = 'Shop In Store';
      this.iconOnline = true;
      this.iconInstore = false;
    } else if (storePath === 'instore') {
      this.btnFillInstore = 'solid';
      this.btnFillOnline = 'outline';
      this.btnColorInstore = 'secondary';
      this.btnColorOnline = 'gray';
      this.textOnline = 'Shop Online';
      this.textInstore = 'In Store';
      this.iconOnline = false;
      this.iconInstore = true;
    }
  }


  chooseStorePath() {

  }
}
