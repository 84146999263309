import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-change-order-modal',
  templateUrl: './change-order-modal.component.html',
  styleUrls: ['./change-order-modal.component.scss'],
})
export class ChangeOrderModalComponent implements OnInit {
  msg = '';
  constructor(public modalCtrl: ModalController) { }

  ngOnInit() {}
  submitChangeOrder(){
    this.modalCtrl.dismiss(true);
  }
  onDismiss(){
    this.modalCtrl.dismiss();
  }
}
