import { Injectable, OnInit } from '@angular/core';
import { Router, CanActivate, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take, filter, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root'
})
export class ForceChangePasswordGuardService implements CanActivate {
	constructor(public auth: AuthService, public router: Router) {

	}

	canActivate(): Observable<boolean> {
		return this.auth.getCurrentUser().pipe(
			take(1),
			map(user => {
				if (user && user.IsPwdTemporary) {
					this.router.navigate(['/account/forceChangePassword']);
				}
				return true;
			})
		)
	}
}