import { Component, HostListener, NgZone } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

import { Platform, AlertController, ModalController, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
// import { FirebaseMessaging } from '@ionic-native/firebase-messaging/ngx';
import { FirebaseX } from "@ionic-native/firebase-x/ngx";

import { CacheService } from "ionic-cache";

import { switchMap, tap, map, take, catchError } from 'rxjs/operators';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub'

import { AuthService } from '@rsApp/modules/auth/providers/auth.service';
import { RSTracker } from '@rsApp/modules/utils/providers/rs-tracker.service';
import { Utils } from '@rsApp/modules/utils/providers/utils';

import { filter } from 'rxjs/operators';
import { ENV } from '@app/env';
import { CurrentStore } from './modules/store/providers/current-store.service';
import { Credential } from '@rsApp/modules/auth/providers/credential.service';
import _ from 'lodash';
// import { FlyBuy } from '@ionic-native/fly-buy/ngx';
// import { FlyBuyService } from './modules/pickup/providers/flybuy.service';
import { FirebaseDynamicLinks } from '@ionic-native/firebase-dynamic-links/ngx';
import { of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DxpComponentService } from './modules/utils/providers/dxp.component.service';
import { enumTrackAction } from './modules/utils/enum/enum';
import { DXPTracker } from './modules/utils/providers/dxp-tracker.service';

// import { AppSettings } from './app.settings';

// As the handleOpenURL handler kicks in before the App is started, 
// declare the handler function at the top of app.component.ts (outside the class definition) 
// to track the passed Url
(window as any).handleOpenURL = (url: string) => {
  let w = window as any;
  console.log("🚀 ~ file: app.component.ts ~ line 35 ~ handleOpenURL", url);
  w.handleOpenURL_LastURL = url;
  if (w.OpenIdHandleOpenURL) {
    w.OpenIdHandleOpenURL(url);
  }
};
@Component({
  selector: 'app-root',
  styleUrls: ['./app.component.scss'],
  templateUrl: 'app.component.html'
})
export class AppComponent {
  runOnce = false;
  banner = 'united';
  deviceMode = 'mobile';
  deviceToken = '';

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private cache: CacheService,
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
    public tracker: RSTracker,
    // private firebaseMessaging: FirebaseMessaging,
    private firebaseX: FirebaseX,
    private alertCtrl: AlertController,
    public utils: Utils,
    public modalCtrl: ModalController,
    private events: NgxPubSubService,
    public cStore: CurrentStore,
    private ngZone: NgZone,
    public cre: Credential,
    // public appSettings: AppSettings
    private firebaseDynamicLinks: FirebaseDynamicLinks,
    // public flyBuy: FlyBuy,
    public navCtrl: NavController,
    public translate: TranslateService,
    // private flybuyService: FlyBuyService,
    public dxpComponentService: DxpComponentService,
    public dxpTracker: DXPTracker,
  ) {
    this.initializeApp();
    this.appThemeBanner();
  }


  appThemeBanner() {
    this.banner = ENV.DefaultBanerName;
    this.deviceMode = ENV.DeviceMode;
  }

  initializeApp() {
    // this.appSettings.fetchSettings().pipe(take(1)).subscribe();
    // this.flybuyService.initConfigure();
    //set defaule language
    this.dxpComponentService.loadScriptDxpComponent();
    this.translate.setDefaultLang('es');
    this.tracker.init();
    this.dxpTracker.init();

    this.platform.ready().then(() => {

      // this.statusBar.overlaysWebView(false);
      if (this.platform.is("android")) {
        this.statusBar.backgroundColorByHexString('#ffffff');
      }
      this.statusBar.styleDefault();
      // this.splashScreen.hide();

      // Set TTL to 10 mins
      this.cache.setDefaultTTL(10 * 60 * 1);

      // Keep our cached results when device is offline!
      this.cache.setOfflineInvalidate(false);
      this.onAuthChange();
      // on notification
      // this.handleNofitication();
      this.initPermission();
      this.handleOnDynamicLink();
      // end on notification
      this.onClickedSchemeUrl();

    });
    this.platform.resume.subscribe((res) => {
      // cheat for extend time cache token
      this.auth.cre.setRsApiToken(this.auth.cre.rsApiToken);
      this.events.publishEvent('emmit-change-order');
      this.events.publishEvent('reload-timeslot');

      this.resetBadgeiOS();
      // console.log('resumed');
    });

    //  analytic
    let navigationO = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      tap((event: any) => {
        try {
          if (!this.runOnce) {
            this.runOnce = true;
            this.splashScreen.hide();
          }
          // track page Event
          let state = this.route.root;
          //Get Page name
          while (state.firstChild) {
            state = state.firstChild;
          }
          const componentName = <any>state.component && (<any>state.component).prototype
            && (<any>state.component).prototype.getPageName && (<any>state.component).prototype.getPageName() || '';
          this.tracker.trackPage(componentName || event.url, event.url);
          this.dxpTracker.trackPage(componentName || event.url, event.url);
          this.events.publishEvent('emmit-change-url', { url: event.url }); // tracking url when change page
        } catch (error) {
          console.error('Tracking page', error); // Log to check when build prod
        }

      })
    );
    navigationO.subscribe();
    // end analytic
    this.events.subscribe('change-store', (store: any) => {
      if (!store) {
        return;
      }
      this.events.publishEvent(enumTrackAction.TrackUserProperty, { name: "HomeStore", value: store.StoreID });
      this.dxpComponentService.onChangeStore(store);
    });
  }

  async initPermission(){
    if (!this.platform.is('cordova')) {
      return;
    }

   let hasPermission = await this.firebaseX.hasPermission();
    if (!hasPermission) {
      hasPermission = await this.firebaseX.grantPermission();
    }
    this.utils.setPermission(hasPermission)

  }

  onAuthChange() {
    let o = this.auth.authState().pipe(
      switchMap((user: any) => {
        let lRoute = this.getLeaftActiveRoute(this.getLeaftActiveRoute(this.route))
        console.log("🚀 ~ file: app.component.ts ~ line 153 ~ AppComponent ~ switchMap ~ lRoute", lRoute)
        return lRoute.data.pipe(
          map((data) => { return { data, user }; })
        )
      }),
    );
    // anaylytics
    o = o.pipe(
      tap((res: any) => {
        this.events.publishEvent('emmit-change-order', { cleanUpData: !res.user }); // clean data if user logout
        this.events.publishEvent('emmit-list', { reload: true }); // must call befor return user null
        this.cStore.getStore().then(store => {
          this.events.publishEvent(enumTrackAction.TrackUserProperty, { name: "HomeStore", value: store && store.StoreID });
          this.initializeDxpComponent({ user: res.user, store, cre: this.auth.cre });
        });

        if (!res.user) {
          return
        }
        // add set user id and user property
        let user = res.user;
        this.tracker.trackUser(user.UserID);

        this.events.publishEvent(enumTrackAction.TrackUserProperty, { name: "MemberID", value: user && user.SRCardID });


        this.firebaseX.setCrashlyticsUserId(user.UserID);

      })
    );

    o.subscribe((res: any) => {

      this.handleNofitication(res.user);
      this.handleGetDynamicLink(res.user);
      if (res.user) {
        return;
      }
      // current component required login, redirect to login
      // console.log('dont have user', res.data);
      if (res.data.requiredLogin === true) {
        // console.log('navigate to login page');
        this.router.navigate(['/login']);
        return;
      }
      // fire event refresh if need reload url
    })
  }

  public handleNofitication(user?) {
    // alert(this.platform.is('cordova'));
    if (!this.platform.is('cordova')) {
      return;
    }
    // console.log('handleNofitication');
    this.firebaseX.grantPermission().then(async (hasPermission) => {
      console.log("Permission was " + (hasPermission ? "granted" : "denied"));
    });

    this.firebaseX.setBadgeNumber(0);
    this.firebaseX.getToken().then((token) => {
      console.log("Got device token: ");
      console.log(token);
      this.deviceToken = token;
      this.resetBadgeiOS(true);

      // console.log(token);
      // update token for FlyBuy
      // this.flyBuy.updatePushToken(token);
      this.events.publishEvent(enumTrackAction.SaveToken, {
        options: {
          DeviceToken: token,
          Notification: false,
          UserName: user && user.Email || 'guest-' + token
        }
      });
    });
    let handelURL = (url) => {
      // console.log('handle URL-----------------------------');
      // console.log(url);
      if (!url) {
        return;
      }
      if (url.indexOf('app://') < 0) {
        this.utils.openInSystem(url);
        return;
      }
      url = url.replace('app:\/\/', '\/');
      this.router.navigateByUrl(url);
    }
    this.firebaseX.onMessageReceived().pipe(
      tap(async (payload) => {
        console.log('onMessageReceived');
        console.log(JSON.stringify(payload));
        this.resetBadgeiOS();
        // this.flyBuy.handleRemoteNotification(payload);
        if (payload.tap == 'background') {
          let url = payload && payload.deeplink;
          if (url) {
            setTimeout(() => {
              handelURL(url);
            }, 500);
          }

          if (payload.message_source == 'flybuy' && payload.order_id && (payload.order_state === 'completed' || payload.customer_state === 'completed')) {
            this.navCtrl.navigateRoot(['/pickup/detail'], { queryParams: { id: payload.order_id, isBackToHome: true } });
          }
        }
        else {
          let url = payload && payload.deeplink,
            aps = payload && ((payload.aps && payload.aps.alert) || payload);

          if ((aps.title || aps.body) && aps.message_source !== 'flybuy') {
            let buttons: Array<any> = [{
              text: 'Close',
              role: 'cancel'
            }];
            if (url) {
              buttons.push({
                text: 'View',
                // role: 'cancel',
                handler: () => {
                  handelURL(url);
                }
              })
            }
            let alert = await this.alertCtrl.create({
              header: aps.title || "  ",
              message: aps.body || "  ",
              buttons: buttons,
              cssClass: buttons && buttons.length == 1 && 'cus-alert-message cus-btns' || ''
            });
            await alert.present();
          }

          //handle workflow Flybuy 
          if (aps.message_source == 'flybuy' && (aps.order_state === 'completed' || aps.customer_state === 'completed')) {
            this.events.publishEvent('reload-pickup-widget');
          }
        }
      })
    ).subscribe();

  }
  /**
    deprecate handle notification by firebasemessing
  **/
  /*
  public _handleNofitication(user?){
    if (!this.platform.is('cordova')) {
      return;
    }

    this.firebaseMessaging.requestPermission().then(()=>{
      console.log("Push messaging is allowed");
    });

    // this.firebaseMessaging.subscribe('dev');
    this.firebaseMessaging.setBadge(0);

    this.firebaseMessaging.getToken().then((token)=>{
      console.log("Got device token: ", token);
      // console.log(token);
      this.tracker.saveToken({
        DeviceToken: token,
        Notification: false,
        UserName: user && user.Email || 'guest'
      }).subscribe();
    });

    let handelURL = (url)=>{
      // console.log('handle URL-----------------------------');
      // console.log(url);
      if(!url){
        return;
      }
      if(url.indexOf('app://') < 0){
        this.utils.openInSystem(url);
        return;
      }
      url = url.replace('app:\/\/', '\/');
      this.router.navigate([url]);
    }
    this.firebaseMessaging.onMessage().pipe(
      tap(async (payload)=>{
        // console.log("New foreground FCM message: ");
        // console.log(JSON.stringify(payload));
        let url = payload && payload.deeplink,
          aps = payload && ( (payload.aps && payload.aps.alert) || payload.gcm);

        let buttons:Array<any> = [{
            text: 'Close',
            role:'cancel'
        }];
        if(url){
          buttons.push({
            text: 'View',
            // role: 'cancel',
            handler: ()=>{
               handelURL(url);
            }
          })
        }

        let alert = await this.alertCtrl.create({
           header: aps.title,
           message: aps.body,
           buttons: buttons
        });

        await alert.present();


      })
    ).subscribe();

    this.firebaseMessaging.onBackgroundMessage().pipe(
      tap((payload)=>{
        // console.log("New background FCM message: ");
        // console.log(JSON.stringify(payload));
        let url = payload && payload.deeplink;
        if(!url){
          return;
        }
        setTimeout(()=>{
          handelURL(url);
        }, 500);
        
      })
    ).subscribe();
  }
  */

  private getLeaftActiveRoute(route) {
    if (route.firstChild) {
      return this.getLeaftActiveRoute(route.firstChild);
    }
    return route;
  }
  async overrideFunctionHandleOpenURL() {
    // override open handler to navigate on further custom url scheme actions
    (window as any).handleOpenURL = (url: string) => {
      // this context is called outside of angular zone!
      setTimeout(() => {        // so we need to get back into the zone..
        this.ngZone.run(() => {
          // this.platform.ready().then(()=>{
          (window as any).cordova.exec(null, null, 'LaunchMyApp', 'clearIntent');
          console.log("🚀 ~ file: app.component.ts ~ line 371 ~ AppComponent ~ onClickedSchemeUrl ~ handleOpenURL", url);
          // this is in the zone again..
          this.handleOpenUrl(url);
          // })

        });
      }, 0);
    };
  }
  async onClickedSchemeUrl() {
    // recode HandleOpenURL function when launch opened app by custom url scheme
    this.overrideFunctionHandleOpenURL();
    // check if app was opened by custom url scheme when first launch app case
    let lastUrl: string = await this._getLastURL();
    // console.log('Mi9v8 ---- lastUrl ' + lastUrl);
    if (lastUrl) {
      this.handleOpenUrl(lastUrl);
    }
  }

  private handleOpenUrl(url: string) {
    if (!url || url == '' || _.isEmpty(url)) {
      return;
    }
    try {
      const hostUrl = new URL(url);
      if (url.toLowerCase().indexOf("pickup.") > -1) {

        this.closeModal();
        var _url = new URL(url);
        let redemptionCode = _url.searchParams.get("r");
        // let id = url.searchParams.get("id");
        this.navCtrl.navigateRoot(['/pickup/detail'], { queryParams: { redemptionCode: redemptionCode, isBackToHome: true } });

      } /*else if ( url.indexOf(ENV.auth_config.redirect_url) === 0 || url.indexOf(ENV.auth_config.end_session_redirect_url) == 0) {
        if ((window as any).OpenIdHandleOpenURL) {
          (window as any).OpenIdHandleOpenURL(url);
        }

      } */
      else if (hostUrl && hostUrl.host && hostUrl.host !== "google") {
        this.handleNavigateFromDynamicLink(hostUrl);
      } else {
        // custom url parsing, etc...
        // navigate to page with reactive forms
        const urlScheme = ENV.URLSCHEME + ':\/\/';
        url = url.replace(urlScheme, '\/');
        if (url && url.toLowerCase().indexOf('google/link') > -1) {
          // This case when clicked dynamic clink => navigate App Store -> Then clicked Open App
          // {apple_bunle_id}://google/link?deep_link_id={my_site}
          const parseUrl = new URL(url);
          const deepLinkId = parseUrl && parseUrl.searchParams && parseUrl.searchParams.get("deep_link_id");
          console.log('deepLinkId', deepLinkId)
          const my_site = new URL(deepLinkId);
          if (my_site) {
            this.handleNavigateFromDynamicLink(my_site);
            return;
          }
        }
        this.router.navigateByUrl(url);
      }

    } catch (error) {
      console.error('handleOpenUrl error:', error);
    }
  }

  async handleNavigateFromDynamicLink(hostUrl: URL) {
    var appUrl = await this.utils.mappingDynamicLink(hostUrl.pathname, hostUrl.search);
    if (!appUrl) {
      this.utils.openInaAppBrowser(hostUrl.href);
    } else {
      this.router.navigateByUrl(appUrl);
    }
  }

  private handleOnDynamicLink() {
    this.firebaseDynamicLinks.onDynamicLink()
      .subscribe((body: any) => {
        //link: https://pickup.marketstreetunited.com/m/o?r=F4HR9GET5Y
        // this context is called outside of angular zone!
        // so we need to get back into the zone..
        this.ngZone.run(async () => {
          // this is in the zone again..
          var strUrl: string = body && body.deepLink;
          this.handleOpenUrl(strUrl);
        });
      }, (error: any) => console.log('onDynamicLink error', error));
  }
  private handleGetDynamicLink(user) {
    if (!this.platform.is('cordova') || !user) {
      return;
    }
    this.firebaseDynamicLinks.getDynamicLink()
      .then((body: any) => {
        //link: https://pickup.marketstreetunited.com/m/o?r=F4HR9GET5Y
        // this context is called outside of angular zone!
        // so we need to get back into the zone..
        this.ngZone.run(async () => {
          // this is in the zone again..
          var strUrl: string = body && body.deepLink;
          this.handleOpenUrl(strUrl);
        });
      }, (error: any) => console.log('onDynamicLink error', error));
  }
  private async closeModal() {
    const modal = await this.modalCtrl.getTop();
    if (!modal) {
      return;
    }
    return this.modalCtrl.dismiss({ ignoreCallback: true });
  }
  resetBadgeiOS(isInit?) {
    if (this.platform.is("ios")) {
      !isInit && this.firebaseX.setBadgeNumber(0);
      // this.utils.clearBadge(this.deviceToken).subscribe();
    }
  }
  async _getLastURL() {
    let w = (window as any),
      lastUrl: string = w.handleOpenURL_LastURL;
    if (lastUrl) {
      delete w.handleOpenURL_LastURL;

      this.platform.is("android") && w.cordova.exec(null, null, 'LaunchMyApp', 'clearIntent');
      return lastUrl;
    }
    if (!this.platform.is("android") || !w.plugins || !w.plugins.launchmyapp) {
      return null;
    }
    const p = () => {
      return new Promise<string>((resolve, reject) => {
        w.plugins.launchmyapp.getLastIntent((url) => {
          w.cordova.exec(null, null, 'LaunchMyApp', 'clearIntent');
          resolve(url);
        }, () => { resolve(null) })
      })
    };
    return await p();

  }
  initializeDxpComponent({ user, store, cre }) {
    // NOTE: Not ready to release this feature yet
    this.dxpComponentService.initializeDxpComponent({ user, store, cre: this.auth.cre, currentShoppingList: null });
  }
  @HostListener('window:message', ['$event'])
  async listenDxpComponent(event) {
    try {
      this.dxpComponentService.listenDxpComponent(event);
    } catch (error) {
      console.error('error listenDxpComponent', error)
    }
  }
}
