import { Injectable } from '@angular/core';

import { CacheService } from 'ionic-cache';
import { Observable, Observer, of, combineLatest } from 'rxjs';
import { tap, catchError, switchMap, map } from 'rxjs/operators';

import _ from 'lodash';

import { ENV } from '@app/env';
import { enumProductType } from '@rsApp/modules/utils/enum/enum.ts';
import { RelationshopHttpClient} from '@rsApp/modules/gateway/rs-api.service';
// import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import {cacheErrorHandle} from '@rsApp/modules/utils/providers/utils';
@Injectable()
export class FavoriteList {

    constructor( 
    	private api: RelationshopHttpClient,
        private cache: CacheService) {
    }
    getList(user, refresher?){
    	return this.getUserFavorite(user, refresher).pipe(
    		switchMap((listArray)=>{
    			let list = listArray[0];
				return this.getItems(user, list.CS_UserShoppingListId).pipe(
					map((items)=>{
						list.Items = items;
						return list;
					})
				);
    		})
    	)
    }
    getUserFavorite(user, refresher?) {
        let url = '/favoritelists/all';
        let params = {
            username: user.Email,
            type: '1'
        };
        return this.api.get(url, {params:params});
    }
    getItems(user, listId, refresher?) {
        let url = '/favoritelists/item';
        let params = {
            username: user.Email,
            userShoppingListId: listId
        },
        cacheParams = {rCache: refresher? 'fresh': 'default', rCacheKey: '/favoritelists/item' };
        Object.assign(params, cacheParams);
        return this.api.get(url,{params: params});
    }

    addItem(product, listId, user) {
        // console.log("addItemToFavoriteList", product);
        let params = {
            ReferenceID: product.ReferenceID,
            ProductUPC: product.UPC || product.Sku,
            ProductName: product.FriendlyName || product.Name,
            UserName: user.Email,
            ItemType: enumProductType.EcomProduct,
            UserFavotireListId: listId,
            CategoryName: product.ProductCategories && product.ProductCategories[0].CategoryName  || product.RSCategoryName  || product.CategoryName || '',
            CategoryID: product.ProductCategories &&  product.ProductCategories[0].CategoryId  || product.RSCategory || product.Category || '',
            ExternalID: product.ExternalID ? product.ExternalID : product.Id,
            Description: JSON.stringify(product.FullDescription),
            ImageUrl: product.DefaultImage,
        };
        this.cache.removeItem('/favoritelists/item');
        let req = this.api.post('/favoritelists/add-item?listId=' + listId, params);
        
        return req;
    }

    removeItem(product, listId, user) {
        let params = {
            
        };
        this.cache.removeItem('/favoritelists/item');
        let url ='/favoritelists/' + user.Email + "/Item?itemIdToRemove="+(product.favItemId || product.ShoppingListId);
        return this.api.request('DELETE', url, {body: params}).pipe(
            tap(() => {
                this.cache.removeItem('favoritelists/item');
            })
        );
    }
    removeItems(products, listId, user){
        let os = _.map(products, (p)=>{
            return this.removeItem(p, listId, user);
        })
        return combineLatest(os);
    }

}

