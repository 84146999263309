import { Component } from '@angular/core';
import { ModalController, Platform, NavController, AlertController } from '@ionic/angular';
import { Location } from '@angular/common';
import { CacheService } from "ionic-cache";
import { take } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';
import { PermanentSettings } from '@rsApp/modules/utils/providers/permanent-settings';

import { AuthService } from '@rsApp/modules/auth/providers/auth.service';
import { Utils, noop, cacheErrorHandle } from '@rsApp/modules/utils/providers/utils';
import { Store } from '../../providers/store.service';
import { User } from '@rsApp/modules/account/provider/user.service';
import { CurrentStore } from '../../providers/current-store.service';
import { Credential } from '@rsApp/modules/auth/providers/credential.service';
import { BaseStore } from './../../base-store';
import _ from 'lodash';
import { ENV } from '@app/env';
import { ShoppingCartGatewayService } from '@rsApp/modules/shopping-cart/providers/shopping-cart-gateway.service';

@Component({
  selector: 'page-store-info',
  templateUrl: 'info.html',
  styleUrls: ['./info.scss']
})
export class StoreInfoPage extends BaseStore {

  showPharmacy: boolean = false;
  showAdditionalServices: boolean = false;
  storeId: string;
  storeInfo: any;
  currentStore: any;
  currentUser: any;
  type: string;

  isSignup: boolean;
  views: any;

  starbuck: any = {};

  storeService = [];
  orderedService = [];

  public backInfo: any; // {route: ['/singup/account'], key: 'selected-store'};

  deptShow: any = {};
  serviceShow: any = {};
  isSeletedStore: any;
  OnlineOrderSvcId: any;
  deptIcons:Array<any> = [];
  isBigYExpress = false;
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public user: User,
    public store: Store,
    public utils: Utils,
    public modalCtrl: ModalController,
    public cStore: CurrentStore,
    public navCtrl: NavController,
    public auth: AuthService,
    public cre: Credential,
    public location: Location,
    public cache: CacheService,
    public alertCtrl: AlertController,
    public platform: Platform,
    public pSettings: PermanentSettings,
    public events: NgxPubSubService,
    public shoppingCartService: ShoppingCartGatewayService
  ) {
    super(utils, cStore, router, cache, store, cre, events, pSettings, shoppingCartService);
    let params = this.route.snapshot.paramMap;
    this.storeId = params.get('storeId');
    this.type = params.get('type');
    this.isSignup = !!params.get('isSignup');
    this.OnlineOrderSvcId = ENV.OnlineOrderSvcId;

  }


  async ionViewWillEnter() {
    super.getCurrent();
  }

  async ionViewDidEnter() {
    let back = await this.cache.getItem('store-back').catch(cacheErrorHandle);
    if (back) {
      this.backInfo = back;
    }
    await this.utils.showLoading();
    this.store.getStoreById(this.storeId).subscribe((resp: any) => {
      if (!resp.DepartmentStores)
        resp.DepartmentStores = [];
      else {
        //find starbuck department
        resp.DepartmentStores.forEach(item => {
          if (item.CS_DepartmentID === 9) {
            this.starbuck = item;
            return false;
          }
        });
      }
      if (!resp.ServiceStores)
        resp.ServiceStores = [];
      this.storeInfo = resp;
      this.isBigYExpress = this.storeInfo.CS_BannerID == ENV.DefaultBanerExpressId;
      let weekdays = ['Monday', 'Tuesday', 'Wednesday','Thursday','Friday', 'Saturday', 'Sunday'];
      // console.log(this.storeInfo);
      Object.assign(this.storeInfo, {
        StoreType: this.cStore.displayStoreType(this.storeInfo),
        SystemService: _.orderBy(this.storeInfo.SystemService, ['Priority']),
        PhoneNumber: this.storeInfo.PhoneNumber && this.storeInfo.PhoneNumber.replace('(', '').replace(')', '').replace(' ', '-'),
        PharmacyPhone: this.storeInfo.PharmacyPhone && this.storeInfo.PharmacyPhone.replace('(', '').replace(')', '').replace(' ', '-') 
      });
      _.each(this.storeInfo.DepartmentStores, (dept)=>{
        let departmentHours = dept.DepartmentMFHours && dept.DepartmentMFHours.split(';');
        departmentHours =_.map(departmentHours, (itemHour, index)=>{
          return {weekday: weekdays[index], val: itemHour};
        });
        if(dept.DepartmentSatHours){
          departmentHours.push({weekday: weekdays[5], val: dept.DepartmentSatHours})
        }
        if(dept.DepartmentSunHours){
          departmentHours.push({weekday: weekdays[6], val: dept.DepartmentSunHours})
        }
        dept.DepartmentHours = departmentHours;
        if(dept.IsShowDepartmentIcon && dept.DepartmentIconURL){
          this.deptIcons.push(dept);
        }
      })
      this.isSeletedStore = (this.currentStore && this.currentStore.CS_StoreID == this.storeInfo.CS_StoreID) ? this.currentStore : null;
      this.groupStoreService(resp.ServiceStores);

      this.utils.hideLoading();
    }, (err) => {
      this.utils.hideLoading();
      console.log(this.storeInfo);

    });
  }

  toggleShow(id) {
    this.serviceShow[id] = !this.serviceShow[id];
  }

  groupStoreService(services) {
    this.storeService = _.groupBy(_.orderBy(services, ['Priority', 'desc']), function (item) {
      // tslint:disable-next-line:max-line-length
      return item.ServiceGroup || 'Additional Store Services';
    });
    this.orderedService = Object.keys(this.storeService);
  }

  toggleDept(id) {
    this.deptShow[id] = !this.deptShow[id];
  }

  goWeeklyAd() {
    if (!this.currentStore || this.currentStore.CS_StoreID != this.storeInfo.CS_StoreID) {
      this.cache.saveItem('selected-store', this.storeInfo);
    }
    this.router.navigate(['/tabs/weekly-ad']);
  }

  async goDirections() {
    /*
    await this.cache.saveItem('store_storeInfo', this.storeInfo);
    this.router.navigate(['../direction'], {relativeTo: this.route});
    */
    const alert = await this.alertCtrl.create({
      message: 'Open in Maps?',
      cssClass: 'cus-alert-modal only-message-aler-modal',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.utils.openInSystem(this.mapLink(this.storeInfo.Latitude, this.storeInfo.Longitude));
          },
          cssClass: 'btn-ok'
        }
      ]
    });
    await alert.present();
    // let m = await this.utils.alert('', 'Open in Maps?');
    // let rs = await alert.onDidDismiss();
    // if (rs.data.yes) {
    //   this.utils.openInSystem(this.mapLink(this.storeInfo.Latitude, this.storeInfo.Longitude));
    // }

  }
  mapLink(lat, long) {
    if (this.platform.is('android')) {
      return 'geo:' + lat + ',' + long + '?z=8&q=' + lat + ',' + long;
    }
    else {
      return 'http://maps.apple.com/?q=' + lat + ',' + long + '&ll=' + lat + ',' + long;
    }

  }

  popToPage(page, viewList) {
    let destinationPage = viewList.find(viewCtrl => viewCtrl.id == page);
    let index = destinationPage.index + 1;
    // this.navCtrl.remove(index, viewList.length - index - 1).then(() => this.navCtrl.pop());
  }

  openLink(url) {
    if (!url)
      return;
    this.utils.openInaAppBrowser(url);
  }
  findStore() {
    this.router.navigate(['../find'], { relativeTo: this.route });
  }
  public getPageName() {
		return "StoreInfoPage";
	}
}
