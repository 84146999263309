import { Injectable } from '@angular/core';
import { CacheService } from "ionic-cache";
import { of } from 'rxjs';
import { MatomoTracker } from 'ngx-matomo';

@Injectable({
	providedIn: 'root'
})
export class Credential {
	// public rsApiToken:string = 'd23cc01090054f13bd56aa18a7b61cc1'; 
	public rsApiToken: string = null;
	public rsApiSesstionId: string = null;
	public currentUser: any = null;
	public cacheTime = 60 * 60 * 24 * 7 * 4; // TTL in seconds for 4 weeks
	public extras: any = {};
	public constructor(
		public cache: CacheService,
		public matomoTracker: MatomoTracker,
	) {
	}
	public async getCurrentUser() {
		if (this.currentUser) {
			return this.currentUser;
		}

		this.currentUser = await this.cache.getItem('cache-user')
			.catch(() => {
				return null;
			});


		return this.currentUser;
	}

	public async setCurrentUser(user) {
		this.currentUser = user;
		if (!user) {
			await this.cache.removeItem('cache-user');
		}
		else {
			await this.cache.saveItem('cache-user', user, null, this.cacheTime);
		}
		return null;
	}

	public async getRsApiToken() {
		if (this.rsApiToken) {
			return this.rsApiToken;
		}
		this.rsApiToken = await this.cache.getItem('cache-rs-token')
			.catch(() => {
				return null;
			})
		return this.rsApiToken;
	}

	public async getRsSessionId() {
		this.rsApiSesstionId = await this.matomoTracker.getVisitorId()
		return this.rsApiSesstionId;
	}

	public async setRsApiToken(token) {
		// Nhat: Cheat code sandbox bigY
		this.rsApiToken = token;
		if (token) {
			await this.cache.saveItem('cache-rs-token', token, null, this.cacheTime);
		}
		else {
			await this.cache.removeItem('cache-rs-token');
		}
		return null;
	}


}