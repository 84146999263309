import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
// import { MomentModule } from 'ngx-moment';

import { UtilsModule } from '@rsApp/modules/utils/utils.module';
import { PurchasesService } from './providers/purchase.service';
import { PurchaseGatewayService } from './providers/purchase-gateway.service';

export {
	PurchasesService,
	PurchaseGatewayService
};

@NgModule({
	imports: [
		CommonModule,
		IonicModule,
		// MomentModule,
		UtilsModule,
	],
	entryComponents: [],
	exports: [],
	declarations: [],
	providers: [PurchasesService, PurchaseGatewayService]
})
export class PurchaseServiceModule {

}